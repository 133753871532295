<section
  class="w-100 sec-top-header breadcrumb-section"
  style="background-color: #dcdcdc"
>
  <div class="wrapper4 d-flex tyre-ryder-header">
    <div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb p-0 m-0 breadcrumb-bg-color">
          <li class="breadcrumb-item breadcrumb-headsize">
            <a class="primary-color" href="/disposable/products">Disposable</a>
          </li>
          <li class="breadcrumb-item breadcrumb-headsize">
            <a class="primary-color" href="/disposable/orders">Cart</a>
          </li>
          <li
            class="breadcrumb-item breadcrumb-headsize active"
            aria-current="page"
          >
            Thank You
          </li>
        </ol>
      </nav>
    </div>
  </div>
</section>
<!-- Skeleton Loader  -->
<section class="w-100 padding-top float-left" *ngIf="isLoading == true">
  <div class="wrapper4">
    <div class="cart-card thanku-section">
      <div class="row">
        <div class="col-lg-7">
          <div class="" style="margin: 0 0px">
            <div class="card main-section thankyou-confirmed mb-3">
              <div class="card-header">
                <div class="confirm-message mb-3">
                  <div class="">
                    <p
                      class="skeleton-loader tr-loader-text"
                      style="font-weight: bold"
                    >
                      Order# {{ orderSummary?.orderNumber }}
                    </p>
                    <div class="skeleton-loader tr-loader-text">
                      {{ orderSummary?.customer?.firstName }}
                      {{ orderSummary?.customer?.lastName }}
                    </div>
                  </div>
                </div>
                <div>
                  <div class="skeleton-loader tr-loader-text mb-2">
                    Your order has been received.
                  </div>
                  <p class="m-0 skeleton-loader tr-loader-text">
                    We received your order, and we are getting it ready. A
                    confirmation email has been sent to
                    {{ orderSummary?.customer?.email }}. We will keep you
                    updated on your order status from time to time.
                  </p>
                </div>
              </div>
            </div>
            <div class="card main-section shipping-address-card mb-3">
              <div class="card-header">
                <div class="skeleton-loader mb-2 loader-height"></div>
                <div class="row mb-4">
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="skeleton-loader mb-2">
                      <!-- Customer: -->

                      <span class="tr-loader-text"
                        >{{ orderSummary?.customer?.firstName }}
                        {{ orderSummary?.customer?.lastName }}</span
                      >
                    </div>

                    <div class="skeleton-loader mb-2">
                      <span class="tr-loader-text">{{
                        orderSummary?.customer?.mobile
                      }}</span>
                    </div>

                    <div class="skeleton-loader">
                      <span class="tr-loader-text">{{
                        orderSummary?.customer?.email
                      }}</span>
                    </div>
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="skeleton-loader mb-2 loader-height"></div>

                    <div class="skeleton-loader mb-2 loader-height"></div>
                    <div class="skeleton-loader mb-2 loader-height"></div>

                    <div class="skeleton-loader mb-2 loader-height"></div>

                    <div class="skeleton-loader mb-2 loader-height"></div>
                  </div>

                  <div
                    class="col-lg-6 col-md-6 col-sm-12"
                    *ngIf="orderSummary?.billingAddress"
                  >
                    <div class="skeleton-loader mb-2 loader-height"></div>

                    <strong class="text-standard">
                      <div class="skeleton-loader mb-2 loader-height"></div>
                      <div class="skeleton-loader mb-2 loader-height"></div>
                      <div class="skeleton-loader mb-2 loader-height"></div>
                    </strong>
                  </div>
                </div>
                <div>
                  <div class="skeleton-loader mb-2 loader-height"></div>
                  <div class="skeleton-loader mb-2 loader-height"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-5 col-md-12 mb-3 order-summary-thankyou">
          <div class="">
            <div class="d-flex justify-content-between mx-2">
              <h2 class="summary-heading base-color">Order Summary</h2>
            </div>
            <div
              class=""
              *ngFor="let item of orderSummary?.orderLines; let i = index"
            >
              <div class="card shadow order-card">
                <div class="card-body border-bottom order-card-padding">
                  <div class="row" style="margin-bottom: 10px">
                    <div class="col-3">
                      <div class="">
                        <div
                          class="cart-img-box skeleton-loader"
                          style="height: 100px"
                        ></div>
                      </div>
                    </div>
                    <div class="col-9">
                      <div class="tire-spec mb-2">
                        <div class="skeleton-loader">
                          <b class="tr-loader-text">
                            {{ item?.catalogueMPN }}
                          </b>
                        </div>
                      </div>
                      <div class="mb-2">
                        <div class="skeleton-loader">
                          <h5
                            class="tr-loader-text"
                            data-qa-id="tire-product-name"
                            style="font-size: 1rem; line-height: 1.2rem"
                          >
                            {{ item?.catalogueAddtionalDescription }}
                          </h5>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-lg-7 col-md-7 col-8">
                          <div class="d-flex skeleton-loader">
                            <del
                              class="item-price grand-total mr-1 tr-loader-text"
                              *ngIf="item?.discountAmount != 0"
                            >
                              ${{ item?.unitPrice | number : "1.2-2" }}</del
                            >
                            <div class="mr-1">
                              <h3
                                class="item-price grand-total tr-loader-text"
                                *ngIf="item?.discountAmount != 0"
                              >
                                ${{
                                  item?.unitDiscountAmount | number : "1.2-2"
                                }}
                              </h3>
                              <h3
                                class="item-price grand-total tr-loader-text"
                                *ngIf="item?.discountAmount == 0"
                              >
                                ${{ item?.unitPrice | number : "1.2-2" }}
                              </h3>
                            </div>
                            <div>
                              <h3 class="item-price grand-total tr-loader-text">
                                X {{ item?.quantity }}
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-5 col-md-5 col-4">
                          <div
                            class="d-flex justify-content-end skeleton-loader"
                          >
                            <h3 class="tr-loader-text">
                              ${{ item?.netAmount | number : "1.2-2" }}
                            </h3>
                            <!-- <span class="text-small base-color">ea</span> -->
                          </div>
                        </div>

                        <div class="divider"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card" style="border-bottom: none; background: none">
            <div class="card-body">
              <div class="row" style="margin-bottom: 20px">
                <div class="col">
                  <div
                    class="d-flex justify-content-between align-items-center skeleton-loader"
                  >
                    <p class="text-standard mb-0 tr-loader-text">Subtotal</p>
                    <strong class="subtitle-small tr-loader-text"
                      >${{ orderSummary?.netAmount | number : "1.2-2" }}
                    </strong>
                  </div>
                </div>
              </div>
              <div
                class="row"
                style="margin-bottom: 20px"
                *ngIf="orderSummary?.discountAmount > 0"
              >
                <div class="col">
                  <div
                    class="d-flex justify-content-between align-items-center skeleton-loader"
                  >
                    <p class="text-standard mb-0 tr-loader-text">Discount</p>
                    <strong class="subtitle-small tr-loader-text"
                      >- ${{ orderSummary?.discountAmount | number : "1.2-2" }}
                    </strong>
                  </div>
                </div>
              </div>
              <div
                class="row"
                style="margin-bottom: 20px"
                *ngIf="orderSummary?.taxAmount > 0"
              >
                <div class="col">
                  <div
                    class="d-flex justify-content-between align-items-center skeleton-loader"
                  >
                    <p class="text-standard mb-0 tr-loader-text">FET</p>
                    <strong class="subtitle-small tr-loader-text">
                      ${{ orderSummary?.taxAmount | number : "1.2-2" }}
                    </strong>
                  </div>
                </div>
              </div>

              <div class="row" style="margin-bottom: 20px">
                <div class="col">
                  <div
                    class="d-flex justify-content-between align-items-center skeleton-loader"
                  >
                    <p class="text-standard mb-0 tr-loader-text">
                      Shipping (Free)
                    </p>
                    <strong class="subtitle-small tr-loader-text">$0.00</strong>
                  </div>
                </div>
              </div>

              <div class="row mx-0 no-gutters d-lg-flex">
                <div class="col-6">
                  <h3 class="skeleton-loader tr-loader-text">Total</h3>
                </div>

                <div class="col-6">
                  <div class="text-right">
                    <h3 class="skeleton-loader tr-loader-text">
                      ${{ orderSummary?.grossAmount | number : "1.2-2" }}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-center">
            <div class="mt-1 skeleton-loader" style="height: 40px">
              <a class="text-center tr-loader-text" href="/products "
                >Continue Shopping</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End Skeleton loader -->
<!-- Cart SECTION -->
<section class="w-100 padding-top float-left" *ngIf="isLoading == false">
  <div class="wrapper4">
    <div class="cart-card thanku-section">
      <div class="row">
        <div class="col-lg-7">
          <div class="" style="margin: 0 0px">
            <div class="card main-section thankyou-confirmed mb-3">
              <div class="card-header">
                <div class="confirm-message mb-3">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="36"
                      height="36"
                      fill="currentColor"
                      class="bi bi-check-circle-fill bg-F5F6F9 drop-icon-blue"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                      />
                    </svg>
                  </div>
                  <div>
                    <p class="mb-0 base-color" style="font-weight: bold">
                      Order# {{ orderSummary?.orderNumber }}
                    </p>
                    <div class="thankyou-heading text-capitalize">
                      {{ orderSummary?.customer?.firstName }}
                      {{ orderSummary?.customer?.lastName }}
                    </div>
                  </div>
                </div>
                <div>
                  <div class="order-confirm">Your order has been received.</div>
                  <p class="m-0">
                    We received your order, and we are getting it ready. A
                    confirmation email has been sent to
                    {{ orderSummary?.customer?.email }}. We will keep you
                    updated on your order status from time to time.
                  </p>
                </div>
              </div>
            </div>
            <div class="card main-section shipping-address-card mb-3">
              <div class="card-header">
                <div class="customer-info-heading base-color mb-3">
                  Customer Information
                </div>
                <div class="row mb-4">
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <div style="font-weight: bold">
                      <!-- Customer: -->
                      <i class="fas fa-regular fa-user mr-2"></i>
                      <span style="font-weight: 500"
                        >{{ orderSummary?.customer?.firstName }}
                        {{ orderSummary?.customer?.lastName }}</span
                      >
                    </div>

                    <div style="font-weight: bold">
                      <!-- <strong>Contact#:</strong> -->
                      <i class="fas fa-solid fa-phone mr-2"></i>
                      <span style="font-weight: 500">{{
                        orderSummary?.customer?.mobile
                      }}</span>
                    </div>

                    <div style="font-weight: bold">
                      <i class="fas fa-solid fa-envelope mr-2"></i>
                      <span style="font-weight: 500">{{
                        orderSummary?.customer?.email
                      }}</span>
                    </div>
                  </div>

                  <!-- <div class="col-lg-6 col-md-6 col-sm-12">
                          <div class="subtitle-small">Payment method</div>
                          <strong class="text-standard">ending with 1234</strong>
                        </div> -->
                </div>

                <div class="row mb-2">
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="customer-info-heading base-color mb-1">
                      Pickup Address
                    </div>
                    <strong class="text-standard"
                      >{{ orderSummary?.shippingAddress?.addressLine1 }},
                      {{ orderSummary?.shippingAddress?.addressLine2
                      }}<span
                        *ngIf="orderSummary?.shippingAddress?.addressLine2"
                        >,</span
                      ><br />
                      {{ orderSummary?.shippingAddress?.city }},
                      {{ orderSummary?.shippingAddress?.state }}
                      {{ orderSummary?.shippingAddress?.zipCode }}<br />
                      {{ orderSummary?.shippingAddress?.country }}
                    </strong>
                  </div>
                  <div
                    class="col-lg-6 col-md-6 col-sm-12"
                    *ngIf="orderSummary?.billingAddress"
                  >
                    <div class="customer-info-heading base-color mb-1">
                      Billing Address
                    </div>
                    <strong class="text-standard"
                      >{{ orderSummary?.billingAddress?.addressLine1 }},
                      {{ orderSummary?.billingAddress?.addressLine2
                      }}<span *ngIf="orderSummary?.billingAddress?.addressLine2"
                        >,</span
                      ><br />
                      {{ orderSummary?.billingAddress?.city }},
                      {{ orderSummary?.billingAddress?.state }}
                      {{ orderSummary?.billingAddress?.zipCode }}<br />
                      {{ orderSummary?.billingAddress?.country }}
                    </strong>
                  </div>
                </div>
                <div>
                  <div class="customer-info-heading base-color mb-1">
                    Shipping Information
                  </div>
                  <strong class="text-standard">Local Delivery</strong>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-5 col-md-12 mb-3 order-summary-thankyou">
          <div class="">
            <div class="d-flex justify-content-between mx-2">
              <h2 class="summary-heading base-color">Order Summary</h2>
            </div>
            <div
              class=""
              *ngFor="let item of orderSummary?.orderLines; let i = index"
            >
              <div class="card shadow order-card">
                <div class="card-body border-bottom order-card-padding">
                  <div class="row" style="margin-bottom: 10px">
                    <div class="col-3">
                      <div class="cart-img-box">
                        <!-- <img src="assets/images/tire.png" class="img-fluid" alt="image not shown" /> -->
                        <img
                          *ngIf="item?.images == null || item?.images == ''"
                          src="assets/images/tyreryder_images/img_not_available.jpg"
                          class="img-fluid"
                          alt="{{ item?.catalogueName }}"
                        />
                        <img
                          *ngIf="item?.images != null && item?.images != ''"
                          src="{{ item?.images[0].replace('lg-', 'sm-') }}"
                          class="img-fluid"
                          alt="{{ item?.catalogueName }}"
                        />
                      </div>
                    </div>
                    <div class="col-9">
                      <div class="tire-spec mb-2">
                        <div class="tire-spec-wrapper">
                          <b class="">
                            {{ item?.catalogueMPN }}
                          </b>
                        </div>
                      </div>
                      <div class="mb-2">
                        <div class="tyre-name mb-2">
                          <h5
                            data-qa-id="tire-product-name"
                            style="font-size: 1rem; line-height: 1.2rem"
                          >
                            {{ item?.catalogueName }}
                          </h5>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-lg-7 col-md-7 col-8">
                          <div class="d-flex">
                            <del
                              class="item-price grand-total mr-1"
                              *ngIf="item?.discountAmount != 0"
                            >
                              ${{ item?.unitPrice | number : "1.2-2" }}</del
                            >
                            <div class="mr-1">
                              <h3
                                class="item-price grand-total"
                                *ngIf="item?.discountAmount != 0"
                              >
                                ${{
                                  item?.unitDiscountAmount | number : "1.2-2"
                                }}
                              </h3>
                              <h3
                                class="item-price grand-total"
                                *ngIf="item?.discountAmount == 0"
                              >
                                ${{ item?.unitPrice | number : "1.2-2" }}
                              </h3>
                            </div>
                            <div class="d-flex">
                              <h3 class="item-price grand-total mr-1">
                                x {{ item?.quantity }}
                              </h3>
                              <h3
                                class="item-price grand-total"
                                *ngIf="item?.taxAmount > 0"
                              >
                                + {{ item?.taxAmount | number : "1.2-2"
                                }}<span class="h6 base-color">(FET)</span>
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-5 col-md-5 col-4">
                          <div class="d-flex justify-content-end">
                            <h3 class="total-price grand-total base-color">
                              ${{ item?.grossAmount | number : "1.2-2" }}
                            </h3>
                            <!-- <span class="text-small base-color">ea</span> -->
                          </div>
                        </div>

                        <div class="divider"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card" style="border-bottom: none; background: none">
            <div class="card-body">
              <div class="row" style="margin-bottom: 20px">
                <div class="col">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <p class="text-standard mb-0">Subtotal</p>
                    <strong class="subtitle-small"
                      >${{ orderSummary?.netAmount | number : "1.2-2" }}
                    </strong>
                  </div>
                </div>
              </div>
              <div
                class="row"
                style="margin-bottom: 20px"
                *ngIf="orderSummary?.discountAmount > 0"
              >
                <div class="col">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <p class="text-standard mb-0">Discount</p>
                    <strong class="subtitle-small"
                      >- ${{ orderSummary?.discountAmount | number : "1.2-2" }}
                    </strong>
                  </div>
                </div>
              </div>
              <div
                class="row"
                style="margin-bottom: 20px"
                *ngIf="orderSummary?.taxAmount > 0"
              >
                <div class="col">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <p class="text-standard mb-0">FET</p>
                    <strong class="subtitle-small">
                      ${{ orderSummary?.taxAmount | number : "1.2-2" }}
                    </strong>
                  </div>
                </div>
              </div>

              <div class="row" style="margin-bottom: 20px">
                <div class="col">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <p class="text-standard mb-0">Shipping (Free)</p>
                    <strong class="subtitle-small">$0.00</strong>
                  </div>
                </div>
              </div>

              <div class="row mx-0 no-gutters d-lg-flex">
                <div class="col-6">
                  <h3 class="total-price-heading base-color">Total</h3>
                </div>

                <div class="col-6">
                  <div class="text-right">
                    <h3 class="total-price-heading grand-total base-color">
                      ${{ orderSummary?.grossAmount | number : "1.2-2" }}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-center">
            <div class="t-generic-btn mt-1 aos-init">
              <a class="text-center" href="/disposable/products"
                >Continue Shopping</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Cart SECTION -->
