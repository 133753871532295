<section
  class="w-100 sec-wholesale-top-header"
  style="background-color: #b31b1b"
>
  <div class="wrapper3">
    <div class="sub-banner-txt text-center">
      <h3
        class="text-white text-uppercase font-weight700 mb-0"
        data-aos="fade-up"
        data-aos-duration="600"
      >
        Login
      </h3>
    </div>
  </div>
</section>
<div
  class="signing-page d-flex align-items-center justify-content-center flex-column padding-bottom mb-4" style="margin-top: 140px;">
  <div class="wrapper wholesale-login">
    <div class="text-center signing-title">
      <h1 class="color-000" style="margin-top: 30px">Wholesale</h1>
    </div>

    <form class="col-lg-6 mx-auto" [formGroup]="userLoginForm">
      <div
        class="modal modal-tr"
        *ngIf="loader == true"
        id="modal_Loading"
        role="dialog"
        [ngStyle]="{ display: 'block' }"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-md">
          <div class="modal-content modal-content1">
            <div class="modal-body modal-body1">
              <div style="text-align: center">
                <span
                  class="spinner-border allmargin-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12">
          <mat-form-field class="example-full-width" style="display: block">
            <mat-label class="color-000">Email</mat-label>
            <input
              type="text"
              placeholder="sample@gmail.com"
              matInput
              formControlName="userName"
              name="userName"
              [(ngModel)]="useremail"
              required
              [disabled]="signbtn"
            />
            <div
              *ngIf="
                getStatus(userLoginForm?.get('userName'), 'required') ||
                getStatus(userLoginForm?.get('userName'), 'pattern')
              "
              class="textMessage error-message"
            >
              Enter Valid Email
            </div>
            <!-- <div *ngIf="getStatus(userLoginForm?.get('userName'), 'pattern')" class="textMessage error-message">
                          Enter valid Email/Phone Number
                      </div> -->
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mat-form-field class="example-full-width" style="display: block">
            <mat-label class="color-000d-block">Password</mat-label>
            <input
              type="password"
              placeholder="Password"
              matInput
              formControlName="password"
              name="password"
              [(ngModel)]="userpassword"
              required
              [disabled]="signbtn"
            />
            <div
              *ngIf="getStatus(userLoginForm?.get('password'), 'required')"
              class="textMessage error-message"
            >
              Enter Password
            </div>
          </mat-form-field>
          <div class="form-group">
            <input type="checkbox" (click)="remember($event)"> Remember me?
          </div>
        </div>
      </div>

      <!-- <div class="forget-con text-center color-000">
        <a
          href="/forgotpassword/customer"
          class="base-color text-decor"
          style="cursor: pointer"
        >
          Forget Password?
        </a>
      </div> -->

      <div class="singbtn text-center t-generic-btn1 mt-3">
        <!-- <button type="button" [disabled]="loginForm.invalid" (click)="validateLoginForm()">Sing In <img src="assets/images/long-white-arrow.png" alt="long-white-arrow"></button> -->
        <button
          class="singin-btn"
          type="button"
          (click)="userValidateLoginForm()"
        >
          <span class="mr-2">Login</span>
          <span *ngIf="signbtn == true"
            ><mat-spinner class="spinner"></mat-spinner
          ></span>
        </button>
      </div>

      <div class="sign-con text-center color-000">
        Don't have an account?
        <a
          class="base-color fw-500 cursor-affect"
          style="text-decoration: underline"
          >Contact Us
        </a>
      </div>

      <div class="sign-con text-center color-000">
        <!-- <a href="#" routerLink="/login"><img src="assets/images/left-arrow-long.png" alt="left-arrow" >
                  Go Back </a> -->
      </div>
    </form>
  </div>
</div>
