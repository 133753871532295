import { DecimalPipe, NgIf, NgFor, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { B2bService } from '../../../services/b2b/b2b.service';
import { SharedService } from '../../../services/shared/shared.service';
import { ShopService } from '../../../services/shop/shop.service';

@Component({
  selector: 'app-disposable-cart',
  standalone: true,
  imports: [DecimalPipe, NgIf, NgFor],
  templateUrl: './disposable-cart.component.html',
  styleUrl: './disposable-cart.component.css'
})
export class DisposableCartComponent {
  constructor(private router: Router,
    private shopService:ShopService,
    private b2bService: B2bService,
    public sharedService: SharedService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.isServer = isPlatformServer(platformId);
  }

  // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  cartItems:any
  isBrowser: any;
  isServer: any;
  netAmount: number = 0
  discountAmount: number = 0
  cartList: any[] = []
  totalAmount = 0
  totalTax = 0
  user: any
  guestId: any;
  isLoading: boolean = false;

  ngOnInit(): void {
    if (this.isBrowser) {
      this.user = localStorage.getItem('user');
      this.user = JSON.parse(this.user);
      this.getCart();
    }
  }

  AddQuantity(index: any) {
    if (this.isBrowser) {
      this.cartList[index].quantity = this.cartList[index].quantity + 1
      this.cartList[index].catalogue.totalAmount = this.cartList[index].quantity * (this.cartList[index].catalogue.pricing.discountPrice > 0
        ? this.cartList[index].catalogue.pricing.discountPrice
        : this.cartList[index].catalogue.pricing.salePrice);
      this.updateCart(index);
    }
  }

  SubtractQuantity(index: any) {
    if (this.isBrowser) {
      if (this.cartList[index].quantity > 1) {
        this.cartList[index].quantity = this.cartList[index].quantity - 1
        this.cartList[index].catalogue.totalAmount = this.cartList[index].quantity * (
          this.cartList[index].catalogue.pricing.discountPrice > 0
            ? this.cartList[index].catalogue.pricing.discountPrice
            : this.cartList[index].catalogue.pricing.salePrice
        )
        this.updateCart(index);
      }
      else if (this.cartList[index].quantity <= 1) {
        this.cartList[index].quantity = 0
        this.updateCart(index)
      }
    }
  }

  getCart() {
    this.totalAmount = 0
    this.totalTax = 0
    this.netAmount = 0
    this.discountAmount = 0
    
    if(this.isBrowser)
      {
        this.cartItems=localStorage.getItem('cart');
        if(this.cartItems)
          {
            this.cartList=JSON.parse(this.cartItems);
            this.sharedService.setb2bCartProductsList(this.cartList);
          }
      }
      if(this.cartList)
        {
          this.cartList.forEach(element => {
            element.variant.taxAmount=0;
            element.variant.totalAmount = element.quantity * (element.variant.discountPrice > 0
              ? element.variant.discountPrice
              : element.variant.salePrice);
            if (element.variant.tax) {
              element.variant.taxAmount = element.quantity * element.variant.tax;
              element.variant.totalAmount=element.variant.totalAmount+element.variant.taxAmount;
              this.totalTax = (this.totalTax || 0) + element.variant.taxAmount;

            }
            this.netAmount = this.netAmount + (element.variant.salePrice * element.quantity);
            if (element.variant.discountPrice > 0) {
              this.discountAmount = this.discountAmount + (element.variant.salePrice - element.variant.discountPrice) * element.quantity
            }
            
          });
          this.totalAmount = this.netAmount - this.discountAmount + this.totalTax
          this.sharedService.setb2bCartProductsList(this.cartList)
        }

  }

  updateCart(index: any) {
    if (this.isBrowser) {
      this.sharedService.setShowLoading(true);
      this.b2bService.UpdateShoppingCart(this.user.userId, this.mapCatalogueData(this.cartList[index].quantity, this.cartList[index].catalogue.variantId, this.cartList[index].catalogue.productId)).subscribe(
        {
          next: (res: any) => {
            if (res.status == 200) {
              this.sharedService.setShowLoading(false);
              this.cartList = res.body.response
              this.getCart();
            }
          },
          error: (error: any) => {
            this.sharedService.setShowLoading(false);
          }
        })
    }
  }

  deleteItem(index: any) {
    this.cartList[index].quantity = 0
    this.updateCart(index)
    this.getCart();
  }

  checkout() {
    this.router.navigate(['/disposable/checkout'])
  }

  mapCatalogueData(quantity: any, variantId: any, productId: any) {

    let user: any = localStorage.getItem('user');
    user = JSON.parse(user);

    let productDetail = {
      'CustomerId': user ? user.userId : null,
      'Catalogues': [
        {
          'catalogueId': productId,
          'Quantity': quantity,
          'variantId': variantId
        }
      ],
      'guestId': localStorage.getItem('guestId') ? localStorage.getItem('guestId') : null,
    }
    return productDetail;
  }

}
