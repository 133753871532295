<section>
  <div class="d-flex align-items-stretch">
    <nav id="sidebar"  style="top: 0; bottom: 0; left: 0; z-index: 101">
      <div class="custom-menu">
        <button type="button" id="sidebarCollapse" class="btn btn-primary">
          <i class="fa fa-bars"></i>
          <span class="sr-only">Toggle Menu</span>
        </button>
      </div>
      <div class="p-4 pt-5">
        <h1>
          <a href="/wholeSale/products" class="logo">
            <img
              class="main-logo"
              src="assets/images/tyreryder_images/logo128x128.png"
              alt="image not shown"
          /></a>
        </h1>
        <ul class="list-unstyled components mb-5">
          <li class="active">
            <a
              href="#homeSubmenu"
              data-toggle="collapse"
              aria-expanded="false"
              class="dropdown-toggle"
              >WholeSale</a
            >

            <ul class="collapse list-unstyled" id="homeSubmenu">
              <li>
                <a href="/wholeSale/products">Tires</a>
              </li>
              <li>
                <a href="/wholeSale/orders">Order History</a>
              </li>
              <li>
                <a href="/invoice">Statements</a>
              </li>
              <li>
                <a href="/cart">Cart</a>
              </li>
            </ul>
          </li>
          <!-- <li>
            <a href="#">Disposal</a>
          </li> -->
          <li>
            <a
              href="#pageSubmenu"
              data-toggle="collapse"
              aria-expanded="false"
              class="dropdown-toggle"
              >Disposable</a
            >
            <ul class="collapse list-unstyled" id="pageSubmenu">
              <li>
                <a href="/disposable/products">Tires</a>
              </li>
              <li>
                <a href="/disposable/orders">Order History</a>
              </li>
              <li>
                <a href="/disposable/cart">Cart</a>
              </li>
            </ul>
          </li>
         
        </ul>
      </div>
    </nav>

    <!-- Page Content  -->
    <div id="content" class="">
      <router-outlet></router-outlet>
    </div>
  </div>
</section>
