export const environment = {
  production: false,
  pageSize: 20,
  bankofAmerica: '',

  apiUrl: 'https://dev-api.tireryder.com',

  baseUrl: 'http://localhost:4200',
  shopUrl: 'https://localhost:7002',
  shopBaseUrl: 'http://localhost:4200/shop',

  nonKeyType1: 'max-load-lbs',
  nonKeyType2: 'mileage-warranty',
  nonKeyType3: 'max-speed-m-h',
  stripeKey:
    'pk_test_51Nb72GC56ELTN1PoEz1UpmjV9cCpC87x1aF26kcujPJr5e9IUBMkfMzPv2nrgWDkanTxy3RhJAfsnithy4SX8uLF008xfphgAW',
  showb2bCardInfo: true,
};
