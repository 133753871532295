import { NgClass, NgFor, NgIf, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AuthService } from '../../../services/auth.service';
import { ProfileService } from '../../../services/profile/profile.service';
import { SharedService } from '../../../services/shared/shared.service';
import { ShopService } from '../../../services/shop/shop.service';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    NgFor,
    FormsModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, MatProgressSpinnerModule,
  ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.css'
})
export class ProfileComponent {
  userDetail: any;
  addressList: any;
  paymentMethodList: any;
  customerProfileUpdateForm: FormGroup | undefined;
  newAddressInfoForm!: FormGroup;
  addressInfoForm: FormGroup | any;
  profileDetail: any;
  selectedAddress: any;
  showAddressForm: boolean = false;
  isBrowser: any;
  isServer: any;

  constructor(private authService: AuthService, private shopService: ShopService, public sharedService: SharedService,
    private profileService: ProfileService, private formBuilder: FormBuilder, private titleService: Title,
    private forms: FormBuilder, @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.isServer = isPlatformServer(platformId);

  }
  ngOnInit(): void {
    if (this.isBrowser) {
      this.userDetail = localStorage.getItem('user');
      this.userDetail = JSON.parse(this.userDetail);
    }
    this.initForm();
    this.fillUserProfileData();
    this.getAllAddress();
    this.avaliablePaymentMethod();
  }
  initForm() {
    this.addressInfoForm = this.formBuilder.group({

      addressLine1: ['', [Validators.required]],
      addressLine2: [''],
      landMark: [''],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
      country: ['', [Validators.required]],
      zipCode: ['', [Validators.required]],

    });

    this.newAddressInfoForm = this.forms.group({
      customerId: this.userDetail?.userId,
      addressLine1: ['', Validators.required],
      addressLine2: [''],
      landMark: [''],
      state: ['CA', Validators.required],
      city: ['', Validators.required],
      country: ['US', Validators.required],
      zipCode: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      addressType: ['3', Validators.required],
    })

    this.customerProfileUpdateForm = this.formBuilder.group({

      firstName: ['', [Validators.required, Validators.minLength(2), Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9]+$'),]],
      lastName: ['', [Validators.required, Validators.minLength(2), Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9]+$'),]],
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.([a-zA-Z0-9]+){2})$')]],
      mobileNo: ['', [Validators.required, Validators.maxLength(10), Validators.pattern('^[0-9]*$')]],
      previouspassword: ['', [ Validators.minLength(8)]],
      Newpassword: ['', [ Validators.minLength(8)]],
      Confirmpassword: ['', [ Validators.minLength(8)]],
    });

  }
  fillUserProfileData() {

    this.profileService.getCustomerProfileDetail(this.userDetail?.userId).subscribe(
      {
        next: (res: any) => {

          if (res.status === 200) {
            this.profileDetail = res.body.response;

            if (this.profileDetail != undefined) {
              this.profileDetail.dateOfBirth = (this.profileDetail.dateOfBirth, 'yyyy-MM-dd');
              this.customerProfileUpdateForm?.patchValue(this.profileDetail);
            }

            // this.sharedService.setShowLoading(false);
          }
        },
        error: (error: any) => {
          // this.sharedService.setShowLoading(false);

        }
      });

  }
  getAllAddress() {

    if (this.userDetail) {
      // this.sharedService.setShowLoading(true);
      this.shopService.getAllAddress(this.userDetail?.userId).subscribe(
        {
          next: (res: any) => {


            if (res.status === 200) {
              this.addressList = res.body.response;
              //  this.sharedService.setShowLoading(false);
            } else {
              // this.sharedService.setShowLoading(false); 

            }
          },
          error: (error: any) => {
            // this.sharedService.setShowLoading(false); 

          }
        });
    }
  }
  avaliablePaymentMethod() {
    if (this.userDetail?.userId) {
      // this.sharedService.setShowLoading(true); 

      this.shopService.getAvaliablePaymentMethod(this.userDetail?.userId).subscribe(
        {
          next: (res: any) => {


            if (res.status === 200) {
              this.paymentMethodList = res.body.response;
              this.paymentMethodList.forEach((element: any, index: number) => {
                if (element.name.toLowerCase() == 'Cash'.toLowerCase())
                  this.paymentMethodList.splice(index, 1);
              });
              // this.sharedService.setShowLoading(false); 


            } else {
              // this.sharedService.setShowLoading(false); 

              // this.toastr.error(res.body.message, 'error');
            }
          },
          error: (error: any) => {
            // this.sharedService.setShowLoading(false); 
          }
        });
    }
  }
  updateSelectedAddress() {
    // this.sharedService.setShowLoading(true);
    let selectedAddressObject = this.addressInfoForm?.value;
    selectedAddressObject.id = this.selectedAddress.id;

    this.profileService.updateAddress(selectedAddressObject).subscribe(
      {
        next: (res: any) => {

          // this.sharedService.setShowLoading(false);

          if (res.status === 200) {

            this.addressList[this.selectedAddress.index] = res.body.response;

            // this.sharedService.setShowLoading(false);

          } else {
            // this.toastr.error(res.body.message, 'error');
          }
        },
        error: (error: any) => {
          // if (error.status !== 500) {
          //   this.toastr.error(error, "error",);
          // } else {s
          //   this.toastr.error("try again", "error",);
          // }
        }
      });
  }
  getStatus(control: AbstractControl | null | undefined, errorName: string): boolean {
    return (control?.hasError(errorName) && (control.touched || control.dirty)) || false;
  }
  allFormValidation(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control: any) => {
      control.markAsTouched();
    });
  }
  submitProfileDetail() {
    console.log('hy')

    if (this.customerProfileUpdateForm?.invalid) {
      this.allFormValidation(this.customerProfileUpdateForm)
      return
    }

    // this.sharedService.setShowLoading(true);


    this.profileService.B2BProfile(this.mapData()).subscribe(
      {
        next: (res: any) => {
          if (res.status == 200) {
            this.sharedService.showGeneralMsgModal('Profile Updated Successfully', 'Profile Updated')
            // this.sharedService.setShowLoading(false);
          }
        },
        error: (error: any) => {
          // this.sharedService.setShowLoading(false);

          // this.sharedService.setShowLoading(false);

        }
      })
  }
  mapData() {
    let profileDetail = {
      'Id': this.userDetail?.userId,
      'firstName': this.customerProfileUpdateForm?.controls['firstName'].value,
      'lastName': this.customerProfileUpdateForm?.controls['lastName'].value,
      'mobileNo': this.customerProfileUpdateForm?.controls['mobileNo'].value,
      'previouspassword': this.customerProfileUpdateForm?.controls['previouspassword'].value,
      'newpassword': this.customerProfileUpdateForm?.controls['Newpassword'].value,
      'confirmpassword': this.customerProfileUpdateForm?.controls['Confirmpassword'].value,
      "primaryLoginType": 1,
      'email': this.customerProfileUpdateForm?.controls['email'].value,
      // 'DateOfBirth': this.customerProfileUpdateForm?.controls['dateOfBirth'].value,
      // 'middleName': this.customerProfileUpdateForm?.controls['middleName'].value,
      // "Gender": this.customerProfileUpdateForm?.controls['gender'].value,

    }


    return profileDetail;
  }
  onAddressChange(address: any, index: any) {

    if (this.selectedAddress)
      this.addressList[this.selectedAddress.index].checked = false;

    this.addressInfoForm?.reset();
    this.addressInfoForm?.patchValue(address);

    this.addressList[index].checked = true;
    this.addressList[index].index = index;
    this.selectedAddress = this.addressList[index];

    // this.showAddressInfoForm = true;
  }
  showAddressform() {
    this.showAddressForm = true;
  }
  submitForm() {
    if (this.newAddressInfoForm.valid) {
      this.authService.newAddress(this.newAddressInfoForm.value).subscribe({
        next: (data: any) => {
          if (data.body.status.statusCode != 'ERROR') {
            this.sharedService.showGeneralMsgModal('Address Added Successfully');
            this.newAddressInfoForm.reset();
            this.showAddressForm = false;
          }
          else {
            this.sharedService.showGeneralMsgModal('ERROR! Adding Address');

          }
        }
      })
    }
    else {
      this.allFormValidation(this.newAddressInfoForm)
    }
  }
  onUploadProfileImage(event: any) {

    let selectedFile: any = <File>event.target.files[0];

    // this.sharedService.setShowLoading(true);
    let fd = new FormData();
    fd.append('file', selectedFile, selectedFile.name);

    this.profileService.uploadProfilePicture(this.userDetail?.userId, fd).subscribe(
      {
        next: (res: any) => {

          // this.sharedService.setShowLoading(false);

          if (res.status == 200) {
          }

        },
        error: (error: any) => {
          // this.sharedService.setShowLoading(false);

        }
      })
  }
}
