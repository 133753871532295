import { DecimalPipe, NgIf, NgFor, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { B2bService } from '../../../services/b2b/b2b.service';
import { SharedService } from '../../../services/shared/shared.service';

@Component({
  selector: 'app-wholesale-thankyou',
  standalone: true,
  imports: [DecimalPipe,
    NgIf,
    NgFor,],
  templateUrl: './wholesale-thankyou.component.html',
  styleUrl: './wholesale-thankyou.component.css'
})
export class WholesaleThankyouComponent {
  orderId: any
  orderSummary: any
  isBrowser: any;
  isServer: any;
  isLoading:boolean=true;
  constructor(private activatedRoute: ActivatedRoute, public sharedService: SharedService, private b2bService: B2bService, @Inject(PLATFORM_ID) platformId: Object
  ) {

    this.isBrowser = isPlatformBrowser(platformId);
    this.isServer = isPlatformServer(platformId);
  }
  ngOnInit(): void {
    if(this.isBrowser)
    {
      this.activatedRoute.params.subscribe((res: any) => {
        this.orderId = res['orderId'];
      })
      this.getOrderDetails();
    }
   
  }
  getOrderDetails() {
    if(this.isBrowser){
      this.isLoading=true;
      this.b2bService.getb2bOrderDetail(this.orderId).subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
            this.isLoading=false;
            this.orderSummary = res.body.response;
          }
        },
        error: (error: any) => {
        }
      });
      }
  }


}

