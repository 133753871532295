import { isPlatformBrowser, isPlatformServer } from "@angular/common";
import { Inject, PLATFORM_ID } from "@angular/core";

export class StaticService {

    static AUTH_NEWSLETTER = '/api/common/newsletter/subscriptions/new';
    static AUTH_CONTACTUS = '/api/ContactUs/addcontact';
    static AUTH_SIGNUPCUSTOMER = '/customer/api/signup';
    static AUTH_NEWADDRESS = '/customer/api/Address/addAddress';
    // signup and login for Customer apis
    static AUTH_VERIFYSIGNUPCUSTOMERPINURL = '/customer/api/signup/verifypin';
    static AUTH_GETREGENERATESIGNUPCUSTOMERPINURL = '/customer/api/signup/regeneratepin';
    static AUTH_GENERATELOGINCUSTOMERPINURL = '/Auth/generateloginpin';
    static AUTH_LOGINCUSTOMERWITHPASSWORDURL = '/customer/api/auth/loginwithpassword';
    static AUTH_LOGINB2BCUSTOMERWITHPASSWORDURL = '/customer/api/B2bauth/loginwithpassword';
    static AUTH_VERIFYLOGINCUSTOMERPINURL = '/Auth/verifyloginpin';
    static AUTH_GENERATEFORGETCUSTOMERPASSPINURL = '/Auth/generateforgetpasswordpin';
    static AUTH_UPDATECUSTOMERPASSBYPINURL = '/customer/api/Auth/generatepasswordpin';
    static AUTH_REGENERATECUSTOMERSIGNUPPIN = '/customer/api/signup/regeneratepin';

    static B2B_GETVARAINTLISTING = '/{1}/b2bcatalogue/variant';
    static B2B_GETVARAINTLISTINGBYCATEGORY = '/{1}/b2bcataloguebycategory/variant'
    static B2B_GETCREATEORDER = '/customer/api/B2bOrder/checkout'
    static B2B_CREATEDISPOSABLEORDER = '/customer/api/B2bOrder/disposablecheckout'
    static B2B_GETSHOPPINGCART = '/{1}/b2bshoppingcart/get/v1'
    static B2B_UPDATESHOPPINGCART = '/{1}/b2bshoppingcart/update'
    static B2B_EMPTYSHOPPINGCART = '/{1}/b2bshoppingcart/emptycart'
    static B2BAUTH_REFRESHAUTHTOKEN = '/customer/api/B2BAuth/refreshtoken'


    // signup and login for Beautician apis
    static AUTH_UPDATEBEAUTICIANPASSBYPINURL = '/vendor/api/Auth/generatepasswordpin';
    static AUTH_REGENERATEBEAUTICIANSIGNUPPIN = '/vendor/api/signup/regeneratepin';
    static AUTH_REFRESHAUTHTOKEN = '/customer/api/Auth/refreshtoken'

    static AUTH_SIGNUPBEAUTICIANURL = '/vendor/api/signup';
    static AUTH_LOGINBEAUTICIANWITHPASSWORDURL = '/vendor/api/auth/loginwithpassword';
    static AUTH_SIGNUPPROFILEUPDATEURL = '/vendor/api/profile/signupprofileupdate';
    static AUTH_VERIFYSIGNUPBEAUTICIANPINURL = '/vendor/api/signup/verifypin';

    //Auth Ended


    //Bank Started
    static AUTH_GETACHBANKSBYVENDORIDURL = '/vendor/api/ACHBanking/getachbanksbyvendorid/';
    static AUTH_GETACHBANKBYIDURL = '/vendor/api/ACHBanking/getachbankbyid/';
    static AUTH_ADDACHBANKINFOURL = '/vendor/api/ACHBanking/addachbankinfo';
    static AUTH_UPDATEBANKACCOUNTURL = '/vendor/api/ACHBanking/updateachbankinfo';
    //Bank Ended


    // Document Started
    static AUTH_UPLOADDOCUMENTURL = '/vendor/api/Document/Upload';
    // Document Ended


    // Interceptor Started
    // static AUTH_REFRESHAUTHTOKENS='/customer/api/Auth/refreshtoken';
    // Interceptor Ended


    // Profile Started
    static AUTH_GETCUSTOMERORDERSLISTURL = '/customer/api/order/';
    static AUTH_GETCUSTOMERPROFILEDETAILURL = '/customer/api/profile/detail/';
    static AUTH_SUBMITPROFILEDETAILURL = '/customer/api/profile/SignupProfileUpdate';
    static AUTH_UPLOADPROFILEPICTUREURL = '/customer/api/profile/uploadprofilepicture';
    static AUTH_UPDATEADDRESSURL = '/customer/api/Address/updateaddress';
    static AUTH_ORDERDETAILURL = '/customer/api/order/';
    static AUTH_ORDERVENDORDETAILURL = '/vendor/api/order/';
    static AUTH_VENDORORDERLIST = '/vendor/api/order/';
    // static AUTH_REFRESHAUTHTOKEN='/customer/api/Auth/refreshtoken'
    // Profile Ended

    // Shared Service Started
    static AUTH_GETALLSERVICESURL = '/vendor/api/Common/get/all';
    static AUTH_GETSERVICEDETAILBYIDURL = '/vendor/api/Common/get/cataloguebyid/';
    static AUTH_GETSERVICEDETAILBYPERMAURL = '/vendor/api/Common/get/cataloguebypermalink/';
    static AUTH_GETALLBEAUTICIANSERVICELISTURL = '/vendor/api/catalogue/all';
    static AUTH_SIGNUPPROFILEDETAILURL = '/vendor/api/profile/signupprofileupdate';
    static AUTH_SUBMITTEDPROFILEDETAILURL = '/vendor/api/profile/detail/';
    static AUTH_VENDORSERVICESOFFEREDURL = '/vendor/api/catalogue/save/allowedservices';
    static AUTH_SUBMITTEDALLOWEDSERVICESURL = '/vendor/api/catalogue/get/allowedservices/';
    static AUTH_GETREQUIREDDOCUMENTLISTURL = '/vendor/api/Document/getrequireddocs';
    // static AUTH_UPLOADDOCUMENTURL='/vendor/api/Document/Upload';
    static AUTH_GETSUBMITTEDDOCUMENTLISTURL = '/vendor/api/Document/getsubmitteddocs';
    static AUTH_GETVENDORDETAILBYIDURL = '/vendor/api/profile/detail/';
    // Shared Service Ended


    // Shop Service Started

    static AUTH_GETALLPRODUCTURL = '/vendor/api/Common/get/all';
    static AUTH_GETPRODUCTDETAILBYIDURL = '/vendor/api/Common/get/cataloguebyid/';
    static AUTH_GETPRODUCTDETAILBYPERMALINK = '/vendor/api/Common/get/cataloguebypermalink/';
    static AUTH_UPDATECARTURL = '/customer/api/ShoppingCart/update';
    static AUTH_TYREUPDATECARTURL = '/api/ShoppingCart/update';
    static AUTH_GETCARTBYCUSTOMERIDORGUESTIDURL = '/customer/api/shoppingcart/';
    static AUTH_TYREGETCARTBYCUSTOMERIDORGUESTIDURL = '/api/shoppingcart/';
    static AUTH_CLEARCARTITEM = '/api/shoppingcart/emptyCart';
    static AUTH_GETCARTBYCUSTOMERIDURL = '/customer/api/ShoppingCart/getcustomercartitems';
    static AUTH_GETALLADDRESSURL = '/customer/api/Address/getalladdresses?Customerid=';
    static AUTH_ORDERCHECKOUTURL = '/customer/api/order/checkout';
    static AUTH_GUESTORDERCHECKOUTURL = '/customer/api/order/guestCheckout';
    static AUTH_AVALIABLEPAYMENTMETHODURL = '/customer/api/payment/customerpaymentmethods';
    static AUTH_AVALIABLESITEPAYMENTMETHODURL = '/customer/api/payment/methods';
    static AUTH_ORDERSUMMARY = '/customer/api/order';
    // Shop Service Ended


    // Order Services Started
    static ORDER_LISTING = '/customer/api/order/:custId/all';
    // Order Services Ended   
    // Order Services Started
    static B2B_ORDER_SUMMARY = '/customer/api/b2border';
    static ORDER_SaveOrderStatus = '/customer/api/b2border/{0}/saveOrderStatus'

    static B2B_ORDER_LISTING = '/customer/api/b2border/:custId/all';
    // Order Services Ended

    // TIRERYDER STARTED

    // Attribute Service Started
    static AUTH_GETLOOKUP = '/api/lookups/get';

    static ATTRIBUTE_SIZE_WIDTH = '/api/lookups/attribute/key1';
    static ATTRIBUTE_SIZE_RATIO = '/api/lookups/attribute/:key1/key2';
    static ATTRIBUTE_SIZE_DIAMETER = '/api/lookups/attribute/Key1/:key1/key2/:key2/key3';

    static ATTRIBUTE_VEHICAL_YEAR = '/api/lookups/vehicle/year';
    static ATTRIBUTE_VEHICAL_MAKE = '/api/lookups/vehicle/year/:year/make';
    static ATTRIBUTE_VEHICAL_MODEL = '/api/lookups/vehicle/year/:year/make/:make/model';
    static ATTRIBUTE_VEHICAL_TRIM = '/api/lookups/vehicle/year/:year/make/:make/model/:model/trim';

    static ATTRIBUTE_BRAND = '/api/lookups/brands';

    static ATTRIBUTE_CATELOUGE = '/api/lookups/:parentUrl/child/select-data';

    static CATALOGUE_VARIANT_LISTING_KEYATTRIBUTES = "/api/catalogue/variant/:keyattribute"
    static CATALOGUE_DETAIL_BRANDPRODUCTURL = "/api/catalogue/detail/:brand/:producturl"
    static CATALOGUE_DETAIL_BYVEHICLE = "/api/vehicle/:year/:make/:model/:trim"
    static CATEGORIES_URL = '/api/catalogue/listing/bycategory/';
    // Attribute Service Ended

    // Blog Started

    static BLOG_RECENT = '/api/Blog/recentblog';
    static BLOG_PAGINATED = '/api/Blog/all';
    static BLOG_PERMALINK = '/api/Blog/';

    // Blog Ended


    // Brand Started

    static TOP_BRANDS = '/api/Brand/recent';
    static ALL_BRANDS = '/api/Brand/all';
    static BRANDS_BY_PERMALINK = '/api/Brand/';
    static BRANDS_URL = '/api/catalogue/listing/bybrand/';

    // Brand Ended

    // stripe
    static GETNEW_SESSIONSTRIPE = '/api/ShoppingCart/new/session';
    // end 

    //  Brand Listing Started
    static BRAND_FEATURE = '/api/brand/:permaLink/catalogue/feature';
    static BRAND_SPEC = '/api/brand/:permaLink/specs';
    static BRAND_REVIEW = '/api/brand/:brandPermaLink/reviewsandrating';
    static CATALOGUE_REVIEW = '/api/catalogue/rating/';
    //  Brand Listing Ended

    //  Invoice Service Started 
    static INVOICE_LISTING = '/customer/api/invoice/:customerid/listing';
    static INVOICE_DETAIL = '/customer/api/invoice/:customerid/detail/:invoiceId';
    static GET_CREDIT = '/customer/api/invoice/:customerId/CreditInfo';
    //  Invoice Service Ended

    //  B2B Profile Started
    static WHOLESALE_PROFILE = '/customer/api/B2BProfile/updateprofile';
    //  B2B Profile Ended

    //  B2B Profile Started
    static ACH_B2B = '/customer/api/B2BProfile/savebanking';
    //  B2B Profile Ended

    //  B2B ACH Started
    static B2B_GETBANKING = '/customer/api/B2BProfile/:customerId/getbanking';
    //  B2B ACH Ended

    //  ALL SIZES Started
    static ALL_SIZES = '/api/catalogue/allSizes';
    //  ALL SIZESEnded


    //  ALL SIZES Started
    static ALL_TYRES = '/api/catalogue/variant/';
    //  ALL SIZESEnded


    //  ALL SIGNUP B2C Started
    static AUTH_SIGNUPTYRECUSTOMER = '/customer/api/signup/request';
    static AUTH_SIGNUPTYRECUSTOMERVERIFYTOKEN = '/customer/api/signup/resendtoken/:token';
    static AUTH_SIGNUPVERIFYTYRECUSTOMER = '/customer/api/signup/verfication';
    //  ALL SIGNUP B2C Ended

    //  ALL FORGOTPASSWORD B2C Started
    static AUTH_FORGOTPASSWORD = '/customer/api/signup/resetpassword/request';
    static AUTH_RESERPASSWORD_VERIFY = '/customer/api/signup/resetpassword/resendtoken/:token';
    static AUTH_FORGOTPASSWORDVERIFICATION = '/customer/api/signup/resetpassword/verfication';
    //  ALL FORGOTPASSWORD B2C Ended
    //Support tickets start
    static SUPPORT_TICKETLISTING = '/customer/api/SupportTicket/getticketbycustomerid/{id}';
    static SUPPORT_TICKETNEW = '/customer/api/SupportTicket/addticket'
    static SUPPORT_TICKETBYID = '/customer/api/SupportTicket/getticketbyid/{id}'
    static SUPPORT_TICKETREPLY = '/customer/api/SupportTicket/replyticket'
    //Support tickets end        
    //  VEHICLE Started
    static VEHICLE_MAKE = '/api/vehicle/getmake';
    static VEHICLE_YEAR = '/api/vehicle/:make/getyear';
    static VEHICLE_MODEL = '/api/vehicle/:make/:year/getmodel';
    static VEHICLE_TRIM = '/api/vehicle/:make/:year/:model/gettrim';
    //  VEHICLE Ended


    //  Rating Started
    static CUSTOMER_RATING = '/customer/api/Rating/AddRating/Catalogue';
    static GUEST_RATING = '/customer/api/Rating/AddRating/guest/catalogue';
    //  Rating Ended

    // TIRERYDER ENDED
}
