import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { DatePipe, DecimalPipe, NgFor, NgIf, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { UtcToLocalPipe } from '../../core/pipes/utctolocalPipe';
import { environment } from '../../core/enviroment/environment';
import { InvoiceService } from '../../../services/invoice/invoice.service';
import { SharedService } from '../../../services/shared/shared.service';
@Component({
  selector: 'app-invoice-detail',
  standalone: true,
  providers:
  [
    DatePipe
  ],
  imports: 
  [
    NgFor,
    UtcToLocalPipe,
    DecimalPipe,
    NgIf
  ],
  templateUrl: './invoice-detail.component.html',
  styleUrl: './invoice-detail.component.css'
})
export class InvoiceDetailComponent implements OnInit {
  user:any;
  isBrowser: any;
  isServer: any;
  invoiceDetail:any;
  perma:any[]=[];
  constructor(private invoiceService:InvoiceService, private sharedService:SharedService ,private route:ActivatedRoute,@Inject(PLATFORM_ID) platformId: Object){
    this.isBrowser = isPlatformBrowser(platformId);
    this.isServer = isPlatformServer(platformId);
  }
  baseUrl = '';
  ngOnInit(): void {
    this.baseUrl = environment.baseUrl;
    this.sharedService.setShowLoading(true);
    if (this.isBrowser) {
      this.route.paramMap.subscribe((res: any) => {
        this.perma = res.params['permaLink'];
        console.log(this.perma);
      });
      this.user = localStorage.getItem('user');
      this.user = JSON.parse(this.user);
      console.log(this.user);
      this.getInvoiceDetail();
    }
  }
  getInvoiceDetail() {
    this.sharedService.setShowLoading(true);
    this.invoiceService.getInvoiceDetail(this.user.userId, this.perma).subscribe({
      next: (res: any) => {
        console.log(res);
        this.invoiceDetail=res.body.response;
        this.sharedService.setShowLoading(false);
        console.log(this.invoiceDetail);
      }
    })
  }
}