import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { SharedService } from '../../../services/shared/shared.service';
import { Title } from '@angular/platform-browser';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { NgIf, NgStyle, isPlatformBrowser, isPlatformServer } from '@angular/common';

@Component({
  selector: 'app-wholesale-login',
  standalone: true,
  imports: [
    FormsModule, NgStyle, MatFormFieldModule, MatInputModule, ReactiveFormsModule, NgIf, MatProgressSpinnerModule,
  ],
  templateUrl: './wholesale-login.component.html',
  styleUrl: './wholesale-login.component.css'
})
export class WholesaleLoginComponent {
  isBeautician: boolean = false;
  userLoginForm!: FormGroup;
  email: any;
  verifyAccountPending: boolean = false;
  signbtn: boolean = false;
  rememberData: boolean = false;
  isBrowser: any;
  isServer: any;
  loader: boolean = false;
  user:any;
  useremail:any;
  userpassword:any;

  constructor(private authService: AuthService, private activatedRoute: ActivatedRoute, private titleService: Title, private formBuilder: FormBuilder, private sharedservice: SharedService, private router: Router,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.isServer = isPlatformServer(platformId);
  }

  ngOnInit(): void {
    if (this.isBrowser) {
      if(sessionStorage.getItem('data')!=null){
        this.user = sessionStorage.getItem('data');
        this.user = JSON.parse(this.user);
        this.useremail= this.user.username;
        this.userpassword= this.user.password;
      }
    }
    this.activatedRoute.params.subscribe((res: any) => {
      var type = res['type'];
      if (type == 'seller') {
        this.isBeautician = true
        this.titleService.setTitle('Login | TireRyder')
      }
      else {
        this.isBeautician = false
        this.titleService.setTitle('wholesale Customer Login | Tire Ryder')
      }
    })

    if (this.isBrowser) {
      this.email = localStorage.getItem('tempusername');
    }

    this.userLoginForm = this.formBuilder.group({
      // userName: [this.email? this.email:'', [Validators.required, Validators.pattern('^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.([a-zA-Z0-9]+){2})|(^[0-9]{11,}$)$')]],
      userName: [this.email ? this.email : '', [Validators.required, Validators.pattern('^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.([a-zA-Z0-9]+){2})$')]],
      password: ['', [Validators.required]],
      type: [1]
    });

  }

  signup() {
    this.router.navigate(['/signup']);
  }

  customer() {
    this.router.navigate(['/forgotpassword/customer']);
  }

  getStatus(control: AbstractControl | null | undefined, errorName: string): boolean {
    return (control?.hasError(errorName) && (control.touched || control.dirty)) || false;
  }

  userValidateLoginForm() {
    if (this.userLoginForm?.invalid) {
      this.userLoginForm.markAllAsTouched();
      return
    }

    let emailReg = new RegExp('^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.([a-zA-Z0-9]+){2})$');
    if (emailReg.test(this.userLoginForm?.controls['userName'].value)) {
      this.userLoginForm?.controls['type'].setValue(1);
      this.userSubmitLoginForm();
      // $emailModel = input;
    }
    // else {
    //   let mobileRegex = new RegExp('^[0-9]{11,}$');
    //   if (mobileRegex.test(this.loginForm?.controls['userName'].value)) {
    //     // if ((input.length > 6) && (intRegex.test(input))) {
    //     this.loginForm?.controls['type'].setValue(2);
    //     this.submitLoginForm();
    //     // $mobileModel = input;
    //   }
    // }
  }

  remember(event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      this.rememberData=true
      console.log(this.rememberData);
    } else {
      this.rememberData=false
      console.log(this.rememberData);
    }
  }
  userSubmitLoginForm() {
    // this.signbtn=true;
    this.loader = true;
    // this.sharedservice.setShowLoading(true);
    this.authService.loginB2bCustomerwithPassword(this.userLoginForm?.value).subscribe({
      next: (res: any) => {
        if (res.body.status.statusCode != 'ERROR') {
          this.signbtn = false;
          this.loader = false;
          console.log(res)
          // this.sharedservice.setShowLoading(false);
          let user = {
            userId: res.body.response.customerId,
            username: res.body.response.username,
            statusId: res.body.response.statusId,
            status: res.body.response.status,
            isBeautician: this.isBeautician,
            firstname: res.body.response.firstName,
            Lastname: res.body.response.lastName,
            customerType: res.body.response.customerType,
            phone: res.body.response.mobileNo,
            isCreditAccount:res.body.response.isCreditAccount
          }
          let userdata = {
            userId: res.body.response.customerId,
            username: res.body.response.username,
            password:this.userLoginForm?.controls['password'].value,
          }
          if (this.isBrowser) {
            localStorage.setItem('user', JSON.stringify(user));
            if(this.rememberData == true){
              sessionStorage.setItem('data', JSON.stringify(userdata));
            }
            localStorage.setItem('token', JSON.stringify(res.body.response.authToken));
            localStorage.removeItem('tempusername');
          }
          const anchor = document.createElement('a');
          anchor.href = 'wholeSale/products';
          anchor.click();
          // this.router.navigate(['/listing']);
        }
        else {
          this.signbtn = false;
          this.loader = false;
          // this.sharedservice.setShowLoading(false);
          this.sharedservice.showGeneralMsgModal(res.body.status.statusDescription, 'ERROR');
          if (res.body.status.statusDescription == 'Account verification pending. Please verify you account.') {
            this.verifyAccountPending = true;
          }
        }

      },
      error: (error: any) => {
        this.signbtn = false;
        this.loader = false;
      }
    })
  }
}
