import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'utcToLocal',
    standalone: true
})
export class UtcToLocalPipe implements PipeTransform {
    constructor(private datePipe: DatePipe) { }

    transform(value: string, format: string): string | null {
        const offset = this.getTimezoneOffsetInMinutes();
        const localTime = new Date(value);
        localTime.setMinutes(localTime.getMinutes() - offset);
        return this.datePipe.transform(localTime, format);
    }
    getTimezoneOffsetInMinutes(): number {
        return new Date().getTimezoneOffset();
    }
}