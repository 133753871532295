<section class="w-100 header-con pb-0 tyre-ryder-header print-on-media">
  <div
    class="wrapper2 tyre-ryder-header d-flex justify-content-end header-overall-padding"
    id="top-bar"
    style="gap: 10px"
  >
    <div style="margin-right: 18px">
      <div class="d-flex align-content-center" *ngIf="loggedIn">
        <div class="flex-grow-1"></div>

        <div class="top-links">
          <ul class="clearfix nopadding pl-0" style="touch-action: pan-y">
            <li class="margin dropdown">
              <a
                class="nav-link dropdown-toggle"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style="
                  font-size: 14px;
                  color: #333;
                  cursor: pointer;
                  display: ruby;
                "
              >
                Hi! {{ user.firstname }}
              </a>
              <div
                class="dropdown-menu p-0"
                aria-labelledby="navbarDropdown"
                style="
                  min-width: 100%;
                  top: 24px !important;
                  left: 5px !important;
                "
              >
                <a
                  class="dropdown-item cursor-affect"
                  style="font-size: 12px"
                  href="/payments"
                  >My Account</a
                >
                <a
                  class="dropdown-item cursor-affect"
                  style="font-size: 12px"
                  href="/profile"
                  >My Profile</a
                >
                <a
                  class="dropdown-item cursor-affect"
                  style="font-size: 12px"
                  (click)="onLogout()"
                  >Log Out</a
                >
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- <div style="margin-right: 3%">
      <nav class="navbar navbar-expand-lg navbar-light p-0">
        <div
        class="collapse navbar-collapse"
        id="navbarSupportedContent"
        *ngIf="loggedIn"
      >
        <ul class="navbar-nav ml-auto" style="margin-right: 6px">
          <li class="pl-lg-0">
            <a class="nav-link" href="/products">Tires</a>
          </li>
          <li class="pl-lg-0">
            <a class="nav-link" href="/orders">Order History</a>
          </li>

          <li class="pl-lg-0">
            <a class="nav-link" href="/invoice">Statements</a>
          </li>
          <li class="pl-lg-0">
            <a class="nav-link" href="/cart">Cart</a>
          </li>
        </ul>
      </div>

        <div class="" *ngIf="loggedIn">
          <div class="d-inline-block">
            <a
              id="cartDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              aria-disabled="cartList?.quantity === 0"
            >
              <img
                src="assets/images/tyreryder_images/shopping-cart-icon.png"
                class="img-fluid"
                alt="Your Image"
                style="width: 25px; height: 25px"
              />
              <span class="badge badge-primary" style="vertical-align: top">{{
                totalQuantity
              }}</span>
            </a>
            <div
              class="dropdown-menu dropdown-cart p-0"
              aria-labelledby="cartDropdown"
            >
              <div class="title">
                <h5>Shopping Cart</h5>
              </div>

              <div *ngFor="let item of cartList">
                <div class="dropdown-cart-item" style="line-height: 20px">
                  <div class="cart-items clearfix">
                    <div class="cart-item-image">
                      <figure class="mb-0">
                        <img
                          *ngIf="
                            item?.catalogue?.image == null ||
                            item?.catalogue?.image == ''
                          "
                          src="/assets/images/tyreryder_images/img_not_available.jpg"
                          class="img-fluid"
                          alt="{{ item?.catalogue?.description }}"
                        />

                        <img
                          *ngIf="
                            item?.catalogue?.image != null &&
                            item?.catalogue?.image != ''
                          "
                          src="{{
                            item?.catalogue?.image.replace('lg-', 'sm-')
                          }}"
                          class="img-fluid"
                          alt="{{ item?.catalogue?.description }}"
                        />
                      </figure>
                    </div>
                    <div>
                      <div class="cart-item-des">
                        <span
                          class="cart-item-price dropdown-price-value"
                          style="color: #212529"
                          >{{ item?.catalogue?.description }}
                        </span>

                        <div class="item-price">
                          <div class="flex-grow-1">
                            <span
                              class="cart-item-price dropdown-price-value"
                              style="font-weight: 700"
                            >
                              {{
                                item?.catalogue?.pricing?.salePrice +
                                  item?.catalogue?.pricing?.tax
                                  | number : "1.2-2"
                              }}
                            </span>
                            <span
                              class="cart-item-price dropdown-price-value"
                              style="font-weight: 700"
                              >X
                              {{ item.quantity }}
                            </span>
                          </div>
                          <div class="">
                            <span class="cart-item-quantity color-333">
                              ${{
                                item?.quantity *
                                  (item?.catalogue?.pricing?.salePrice +
                                    (item?.catalogue?.pricing?.tax > 0
                                      ? item?.catalogue?.pricing?.tax
                                      : 0)) | number : "1.2-2"
                              }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cart-action clearfix t-generic-btn">
                <div class="">
                  <button
                    class="button_border_none"
                    [disabled]="cartList.length == 0"
                  >
                    <a href="/cart">View Cart</a>
                  </button>
                  <span class="fright checkout-price"
                    >${{ totalAmount | number : "1.2-2" }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div> -->
  </div>
</section>
