import { Component, Inject, OnInit, PLATFORM_ID, ViewChild, ElementRef, Renderer2  } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatePipe, DecimalPipe, NgClass, NgFor, NgIf, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { json } from 'stream/consumers';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators,} from '@angular/forms';
import { ProfileService } from '../../../services/profile/profile.service';
import { UtcToLocalPipe } from '../../core/pipes/utctolocalPipe';
import { SaveOrderStatusModel, SharedService, orderLineReturn, returnOrderLineModel } from '../../../services/shared/shared.service';
import { absPipe } from '../../core/pipes/absPipe';


@Component({
  selector: 'app-whole-sale-order-detail',
  standalone: true,
  providers:[DatePipe],
  imports: [
    NgFor,
    NgIf,
    NgClass,
    DecimalPipe,
    UtcToLocalPipe,
    absPipe,
    FormsModule,
    ReactiveFormsModule,
    DecimalPipe
  ],
  templateUrl: './whole-sale-order-detail.component.html',
  styleUrl: './whole-sale-order-detail.component.css'
})
export class WholeSaleOrderDetailComponent implements OnInit{
  perma:any[]=[];
  shippableOrdersDetail: any;
  shippableOrdersubtotal: any = 0;
  userId:any;
  user:any;
  isBrowser: any;
  isServer: any;
  selectedReason: any;
  SaveOrderModel: SaveOrderStatusModel = new SaveOrderStatusModel();
  returnOrderLine:orderLineReturn[]=  []
  CancelForm: FormGroup | any;
  returnForm: FormGroup | any;
  orderStatusData: any;
  orderLineStatusData: any;
  orderLineStatuses: any[]=[];
  shipableOrderTypeData: any;
  orderReturn:any[]=[];
  idData: any[]=[];
  itemsReturnCount:number=0;
  fetCount:number=0;
  itemsReturnAmount:number=0;
  returnCharges:number=0;
  subTotal:number=0;
  returnSubtotal:number=0;
  returnfet:number=0;
  returntotal:number=0;
  constructor(private route:ActivatedRoute, private renderer: Renderer2 ,private sharedservice:SharedService,private formBuilder: FormBuilder ,private profileService:ProfileService,@Inject(PLATFORM_ID) platformId: Object){
    this.isBrowser = isPlatformBrowser(platformId);
    this.isServer = isPlatformServer(platformId);
  }
  @ViewChild('cancelorder') public cancelorder!: ElementRef;
  @ViewChild('returnorder') public returnorder!: ElementRef;

  ngOnInit(): void {
    this.sharedservice.setShowLoading(true);
    if(this.isBrowser){
      this.route.paramMap.subscribe((res:any) => {
        this.perma = res.params['permaLink'];
        console.log(this.perma);
      });
      this.user=localStorage.getItem('user');
      if(this.user)
      {
        this.user = JSON.parse(this.user);
        this.userId=this.user.userId
      }
      this.getShippableOrdersDetail();
    }
    this.initForm();
  }
  initForm(){
    if(this.isBrowser){
      this.CancelForm = this.formBuilder.group({
        reason: ['', Validators.required],
        cancelText: ['', [Validators.required, Validators.pattern(/cancel/i)]],
      });
      this.returnForm = this.formBuilder.group({
        description: [''],
        orderLineId: [''],
        reason: ['no used'],
        condition: ['good'],
        returnedQuantity: [,this.formBuilder.array([]) ],
      });
    }
  }
  getShippableOrdersDetail() {
    if (this.isBrowser) {
      this.sharedservice.setShowLoading(true);
      this.profileService.getOrderDetail(this.perma).subscribe(
        {
          next: (res: any) => {
            if (res.status === 200) {
              this.sharedservice.setShowLoading(false);
              this.shippableOrdersDetail = res.body.response;
              this.SaveOrderModel.orderId=this.shippableOrdersDetail.id;
              this.SaveOrderModel.AdminUser=this.user.firstname +' '+this.user.Lastname;
              this.shippableOrdersDetail?.orderLines.forEach((element: any) => {
                var orderLine= new orderLineReturn();
                orderLine.orderId= this.shippableOrdersDetail.id;   
                orderLine.variantId= element.variantId;   
                orderLine.orderLineId= element.id;
                orderLine.discountAmount= element.discountAmount;
                orderLine.unitDiscountAmount= element.unitDiscountAmount;
                orderLine.unitPrice= element.unitPrice;
                orderLine.taxAmount= element.taxAmount;
                orderLine.unitTaxAmount= element.unitTaxAmount;
                orderLine.actualQuantity=element.quantity;
                orderLine.grossAmount=element.grossAmount;
                orderLine.returnQuantity=element.returnedQuantity;
                if(element.returnedQuantity<0){
                  orderLine.returnedQuantity.setValue(element.returnedQuantity)
                }
                else{
                  orderLine.returnedQuantity.setValue(0);
                }
                orderLine.orderLineStatus=element.statusId;
                orderLine.status=element.status;
                orderLine.MfA=element.catalogueMPN;
                orderLine.AlternateMFA=element.catalogueAddtionalDescription;
                this.returnOrderLine.push(orderLine)
              });
              if(this.shippableOrdersDetail?.returnOrderLines!=null){
                this.returnSubtotal=0;
                this.returnfet=0;
                this.returntotal=0;
                this.shippableOrdersDetail?.returnOrderLines.forEach((element: any) => {
                  this.returnOrderLine.filter(x=>x.variantId==element.variantId)[0].grossAmount=this.returnOrderLine.filter(x=>x.variantId==element.variantId)[0].grossAmount+element.grossAmount;
                  this.returnOrderLine.filter(x=>x.variantId==element.variantId)[0].orderLineStatus=element.statusId;
                  this.returnSubtotal=this.returnSubtotal+element.netAmount;
                  this.returnfet=this.returnfet+element.taxAmount;
                  this.returntotal=this.returntotal+element.grossAmount;
                });
              }

              this.orderStatusData=this.shippableOrdersDetail?.statusId;
              this.orderLineStatusData=this.shippableOrdersDetail?.orderLines.statusId;
              this.shipableOrderTypeData=this.shippableOrdersDetail?.statusId;
              this.sharedservice.setShowLoading(false);
            } else {
              this.sharedservice.setShowLoading(false);
            }
          },
          error: (error: any) => {
            this.sharedservice.setShowLoading(false);
          }
        });
    }
  }
  closeModel() {
    this.cancelorder.nativeElement.click();
  }
  closeModelBox() {
    this.returnorder.nativeElement.click();
  }
  CancelOrder() {
    if (this.isBrowser) {
      if (this.CancelForm.valid) {
        this.selectedReason = this.CancelForm.get('reason').value;
        this.closeModel();
        this.sharedservice.setShowLoading(true);
        this.SaveOrderModel.orderLineStatus = this.shippableOrdersDetail?.orderLines[0].statusId;
        this.SaveOrderModel.orderStatus = 9
        this.SaveOrderModel.description = this.selectedReason;
        this.profileService.saveOrder(this.userId, this.SaveOrderModel).subscribe({
          next: (res: any) => {
            if (res.body.status.statusCode == 'OK') {
              this.getShippableOrdersDetail();
              this.sharedservice.setShowLoading(false);
            }
            else {
              this.sharedservice.setShowLoading(false);
            }
          },
          error: (error: any) => {
            this.sharedservice.setShowLoading(false);
          }
        });
        this.CancelForm.reset();
      }
      else {
        this.CancelForm.markAllAsTouched();
        return;
      }
    }
  }
  valuechange(product: any) {
    const quantity=product.actualQuantity+product.returnQuantity;
    if (product.returnedQuantity.value > quantity) {
      this.sharedservice.showGeneralMsgModal(`Maximum quantity exceed of ${quantity}`);
      product.returnedQuantity.setValue(quantity);
    }
    if (product.returnedQuantity.value<0) {
      this.sharedservice.showGeneralMsgModal('Minimum quantity is 1');
      product.returnedQuantity.setValue(1);
    }
    else {
      this.itemsReturnCount = 0;
      this.itemsReturnAmount = 0;
      this.fetCount = 0;
      this.returnOrderLine.forEach((element: any) => {
        this.itemsReturnCount = this.itemsReturnCount + element.returnedQuantity.value;
        this.fetCount=this.fetCount+(element.returnedQuantity.value * element.unitTaxAmount);
        this.itemsReturnAmount = this.itemsReturnAmount + (element.returnedQuantity.value * element.unitPrice) ;
      })
    }
  }
  returnOrder() {
    if (this.itemsReturnCount==0) {
      this.sharedservice.showGeneralMsgModal('Minimum quantity should be 1');
    }
    else {
      this.profileService.saveOrder(this.userId, this.mapData()).subscribe(
        {
          next: (res: any) => {
            if (res.status == 200) {
              this.closeModelBox();
              this.sharedservice.showGeneralMsgModal('Return Request created successfully')
              this.getShippableOrdersDetail();
              this.returnForm.reset();
            }
          },
        })
    }
  }
  mapData(){
    var request=new SaveOrderStatusModel();
    request.orderId=this.shippableOrdersDetail?.id;
    // request.orderStatus = this.shippableOrdersDetail?.statusId;
    request.orderLineStatus = 20;
    request.shipableorderType = 2;
    request.AdminUser=this.user.firstname+" "+this.user.Lastname;
    request.description="reason: "+this.returnForm?.controls['reason'].value + ", description: " + this.returnForm?.controls['description'].value;
    this.returnOrderLine.forEach((element:any) => {
      var lines=new returnOrderLineModel();
      if(element.returnedQuantity.value > 0){
        lines.orderId=this.shippableOrdersDetail.id;
        lines.orderLineId=element.orderLineId;
        lines.returnedQuantity=element.returnedQuantity.value;
        // lines.orderLineStatus=20;
        request.returnOrderLines.push(lines);
      }
    });
    return request;
  }
}
