<section
  class="w-100 sec-top-header breadcrumb-section"
  style="background-color: #dcdcdc"
>
  <div class="wrapper4 d-flex tyre-ryder-header">
    <div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb p-0 m-0 breadcrumb-bg-color">
          <li class="breadcrumb-item breadcrumb-headsize">
            <a class="primary-color" href="#">WholeSale</a>
          </li>

          <li
            class="breadcrumb-item breadcrumb-headsize active"
            aria-current="page"
          >
            Statements
          </li>
        </ol>
      </nav>
    </div>
  </div>
</section>
<section class="w-100" style="overflow: hidden">
  <div class="wrapper4 my-4">
    <div class="generic-heading-box text-center w-100 my-4">
      <h2 class="font-weight600 color-333 text-capitalize">Statements</h2>
    </div>

    <div class="w-100 d-md-block d-none">
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
            <tr class="align-middle">
              <th class="align-middle" scope="col">Invoice</th>
              <th class="align-middle" scope="col">Total</th>
              <th class="align-middle" scope="col">Paid</th>
              <th class="align-middle" scope="col">Status</th>
              <th class="align-middle w-25" scope="col">
                Date <br />
                Duration
              </th>
              <th class="align-middle" scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let invoice of invoiceData">
              <td>
                <a
                  [href]="['/invoice-detail/' + invoice.id]"
                  class="color-B31B1B font-weight500"
                >
                  {{ invoice.invoiceNumber }}
                </a>
              </td>
              <td>
                <span class="color-B31B1B font-weight500">
                  ${{ invoice.totalAmount | number : "1.2-2" }}
                </span>
              </td>
              <td>
                <span class="color-B31B1B font-weight500">
                  ${{ invoice.paidAmount | number : "1.2-2" }}
                </span>
              </td>
              <td class="align-middle">
                <a
                  class="badge badge-warning bg-light-warning font-weight500 complete-btn text-white"
                  >{{ invoice.status }}</a
                >
              </td>
              <td class="align-middle">
                <span class="base-color">{{
                  invoice.invoiceDate | utcToLocal : "dd-MMM-yyyy"
                }}</span>
                <br />
                <span *ngIf="invoice.invoiceFrom && invoice.invoiceTo != null">
                  {{ invoice.invoiceFrom | utcToLocal : "dd-MMM-yyyy" }}
                  to
                  {{ invoice.invoiceTo | utcToLocal : "dd-MMM-yyyy" }}
                </span>
              </td>
              <td class="align-middle">
                <a
                  style="cursor: pointer"
                  [href]="['/invoice-detail/' + invoice.id]"
                >
                  <i class="fas fa-regular fa-eye primary-color"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div
      class="card card-flush d-block d-md-none card-shadow mb-3"
      data-aos="fade-up"
      data-aos-duration="600"
      *ngFor="let invoice of invoiceData"
    >
      <!--begin::Card body-->
      <div class="card-body pt-3">
        <!-- <div> <span>Status</span></div> -->

        <div class="status-mb w-100 d-flex justify-content-between">
          <span class="status-heading">
            <a
              class="badge badge-success bg-light-success mr-2 order-card-sm px-2 py-1"
              >{{ invoice.invoiceStatus }}</a
            >
            <a
              class="badge badge-success bg-light-success order-card-sm px-2 py-1"
              >{{ invoice.status }}</a
            >
          </span>

          <div class="">
            <div>
              <div class="dropdown show">
                <a
                  class="info"
                  href="#"
                  role="button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="fas fa-ellipsis-v"></i>
                </a>

                <div
                  class="dropdown-menu dropdown-menu-right"
                  aria-labelledby="dropdownMenuLink"
                >
                  <a
                    class="dropdown-item"
                    style="cursor: pointer"
                    [href]="['/invoice-detail/' + invoice.id]"
                    >View</a
                  >
                  <!-- <a class="dropdown-item" href="#">Download</a> -->
                  <!-- <a class="dropdown-item" href="#">Print</a> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <i class="fas fa-solid fa-lock fa-2x"></i>
        </div>
        <div class="order-position">
          <div>
            <span class="font-weight-bold color-B31B1B">Invoice Number</span>
          </div>
          <div>
            <a
              [href]="['/invoice-detail/' + invoice.id]"
              class="color-B31B1B"
              >{{ invoice.invoiceNumber }}</a
            ><br />
            <span class="card-data"
              >{{ invoice.invoiceDate | utcToLocal : "dd-MMM-yyyy" }} at
              {{ invoice.invoiceDate | utcToLocal : "h:mm a" }}</span
            >
          </div>
        </div>

        <!-- <div>
                    <i class="fas fa-regular fa-calendar fa-2x"></i>
                  </div>
                  <div class="order-position">
                    <div><span class="font-weight-bold color-B31B1B">Date</span></div>
                    <div>
                      <span class="card-data">{{ invoice.invoiceDate }} {{ invoice.invoiceDate }}</span>
                    </div>
                  </div> -->

        <div>
          <i class="fas fa-regular fa-tag fa-2x"></i>
        </div>
        <div class="order-position">
          <div><span class="font-weight-bold color-B31B1B">Items</span></div>
          <div>
            <span class="card-data"
              >${{ invoice.totalAmount | number : "1.2-2" }}</span
            >
          </div>
        </div>

        <!-- <div>
                    <i class="fas fa-solid fa-book fa-2x"></i>
                  </div>
                  <div class="order-position">
                    <div><span class="font-weight-bold color-B31B1B">Total</span></div>
                    <div>
                      <span class="card-data"
                        >${{ order.totalPrice | number : "1.2-2" }}</span
                      >
                    </div>
                  </div> -->
      </div>
      <!--end::Customer address-->
    </div>
  </div>
</section>
<section class="w-100 bg-F5F6F9 float-left t-padding-bottom">
  <div class="wrapper4">
    <div
      class="d-flex align-items-baseline pagination-block pb-3 justify-content-between flex-wrap"
    >
      <div>
        Showing Page <span class="base-color h6">{{ page }}</span> of
        <span class="base-color h6">{{ totalPages }}</span>
      </div>
      <div class="pagination p1">
        <ul>
          <button
            class="button_border_none cursors"
            (click)="onPreviousPageSet()"
            [disabled]="currentPageIndex === 0"
          >
            <li class="arrows-color">Previous</li>
          </button>

          <ng-container *ngFor="let pageNumber of pagesToShow()">
            <a
              [class.is-active]="page === pageNumber"
              (click)="onPageChange(pageNumber)"
              class="cursors"
            >
              <li>{{ pageNumber }}</li>
            </a>
          </ng-container>

          <button
            class="button_border_none cursors"
            (click)="onNextPageSet()"
            [disabled]="currentPageIndex === totalPages"
          >
            <li class="arrows-color">Next</li>
          </button>
        </ul>
      </div>
      <div>
        Total Data<span class="base-color h6"> ({{ total }})</span>
      </div>
    </div>
  </div>
</section>
