<footer
  class="w-100 float-left footer-con text-white footer-section"
  style="z-index: 0"
>
  <div class="wrapper3 pb-20px">
    <div class="generic-heading text-center">
      <h5 class="font-weight500 text-uppercase footer-subtext">Live Support</h5>
      <h3 class="font-weight600 mb-0 footer-title footer-heading">
        (Mon-Fri 8:00 AM TO 5:00 PM EST)
      </h3>
    </div>

    <!--  -->
    <div class="d-flex justify-content-center">
      <div
        class="d-flex justify-content-center text-white align-items-center mr-4"
      >
        <i class="fas fa-solid fa-phone mr-1 fa-2x"></i>
        <a class="w-support-des text-white" href="tel:(916) 832-1265"
          >(916) 832-1265</a
        >
      </div>

      <div class="d-flex justify-content-center text-white align-items-center">
        <i class="fas fa-solid fa-envelope mr-1 fa-2x"></i>
        <a class="w-support-des text-white" href="mailto:info@tireryder.com"
          >info&#64;tireryder.com</a
        >
      </div>
    </div>

    <!-- <div class="footer-flex">
        <div class="">
          <div class="card box-shadow" style="background-color: #e9ecf4">
            <div class="card-body">
              <div
                class="d-flex justify-content-center align-items-center flex-column"
              >
                <div class="deal-img-box-call mb-2">
                  <img
                    src="assets/images/tyreryder_images/Call.svg"
                    alt="image not shown"
                    class="deal-img img-fluid"
                  />
                </div>
  
                <h4 class="support-text mb-2">Call</h4>
                <a class="support-des" href="tel:(916) 832-1265"
                  >(916) 832-1265</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="">
          <div class="card box-shadow" style="background-color: #e9ecf4">
            <div class="card-body">
              <div
                class="d-flex justify-content-center align-items-center flex-column"
              >
                <div class="deal-img-box mb-2">
                  <img
                    src="assets/images/tyreryder_images/Email-01.svg"
                    alt="image not shown"
                    class="deal-img img-fluid"
                  />
                </div>
  
                <h4 class="support-text mb-2">Email</h4>
                <a class="support-des" href="mailto:info@tireryder.com"
                  >info&#64;tireryder.com</a
                >
              </div>
            </div>
          </div>
        </div>
      </div> -->
  </div>

  <div class="wrapper">
    <div
      class="copyright-con w-100 float-left d-md-flex align-items-center justify-content-between text-md-left text-center"
    >
      <div class="copyright-txt mx-auto">
        <span class="d-inline-block">
          ©2024 Ryder Group LLC all rights reserved.</span
        >
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</footer>
