<section
  class="w-100 sec-top-header breadcrumb-section"
  style="background-color: #dcdcdc"
>
  <div class="wrapper4 d-flex tyre-ryder-header">
    <div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb p-0 m-0 breadcrumb-bg-color">
          <li class="breadcrumb-item breadcrumb-headsize">
            <a class="primary-color" href="/disposable/products">Disposal</a>
          </li>

          <li
            class="breadcrumb-item breadcrumb-headsize active"
            aria-current="page"
          >
            checkout
          </li>
        </ol>
      </nav>
    </div>
  </div>
</section>

<section class="w-100 float-left shipping-con padding-top padding-bottom">
  <div class="wrapper4">
    <div class="checkout-section">
      <div class="w-100 float-left">
        <div class="row">
          <div class="col-lg-8 col-md-12 order-lg-0 order-md-2 order-1">
            <div
              class="modal modal-tr"
              *ngIf="showLoading == true"
              id="modal_Loading"
              role="dialog"
              [ngStyle]="{ display: 'block' }"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-md">
                <div class="modal-content modal-content1">
                  <div class="modal-body modal-body1">
                    <div style="text-align: center">
                      <span
                        class="spinner-border allmargin-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <section class="skele" *ngIf="isLoading == true">
              <div class="ccontainer order-summary-mobile mt-20px mb-2 px-0">
                <div class="card">
                  <div class="card-body box-shadow">
                    <div class="row mb-1">
                      <div class="col-lg-12 col-md-12 col-sm-12 my-3">
                        <div class="skeleton-loader mb-2">
                          <span class="tr-loader-text">
                            Contact Information</span
                          >
                          <a
                            class="order-edit mb-3"
                            style="
                              text-decoration: underline;
                              font-weight: bold;
                            "
                            *ngIf="!edit"
                            (click)="editAddress(true)"
                            >Edit</a
                          >
                        </div>
                        <div class="pl-20px skeleton-loader mb-2">
                          <!-- <i class="fas fa-regular fa-user mr-2"></i> -->
                          <span class="tr-loader-text">
                            {{ userDetail?.firstname }}
                            {{ userDetail?.Lastname }}
                          </span>
                        </div>
                        <div class="pl-20px skeleton-loader mb-2">
                          <!-- <i class="fas fa-solid fa-envelope mr-2"></i> -->
                          <span class="primary-color tr-loader-text"
                            >{{ userDetail?.username }}
                          </span>
                        </div>
                        <div class="pl-20px skeleton-loader">
                          <!-- <strong>Contact#:</strong> -->
                          <!-- <i class="fas fa-solid fa-phone mr-2"></i> -->
                          <span class="tr-loader-text">
                            {{ userDetail?.phone }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="t-payment-con w-100 float-left">
                      <div
                        class="skeleton-loader mb-2 loader-height"
                        style="width: 50%"
                      ></div>
                      <div
                        class="pay-box w-100 float-left"
                        *ngFor="let address of addressList; let i = index"
                      >
                        <div class="payment-title skeleton-loader">
                          <!-- <input 
                                        class="skeleton-loader"
                                          type="radio"
                                          checked
                                          [id]="address.id"
                                          name="address.id"
                                          value="{{ address.id }}"
                                          (change)="onAddressChange($event, i)"
                                          [checked]="
                                            address.checked && showAddressForm == false
                                          "
                                        /> -->
                          <label
                            [for]="address.id"
                            class="mb-0 font-weight500 tr-loader-text"
                          >
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="" style="text-align: right" *ngIf="userDetail">
                      <div class="skeleton-loaderb loader-height"></div>
                    </div>

                    <form
                      [formGroup]="NewShippingAddressForm"
                      class="shipping-form"
                      *ngIf="showAddressForm"
                    >
                      <h5
                        class="font-weight500 color-333 mb-sm base-color shipping-heading mb-0"
                      >
                        Shipping Address
                      </h5>

                      <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 mb-2">
                          <mat-form-field class="d-block">
                            <mat-label class="">Address Line1 </mat-label>
                            <input
                              autocomplete="on"
                              matInput
                              formControlName="addressLine1"
                              name="addressLine1"
                              required
                              maxlength="50"
                            />
                            <div
                              class=""
                              *ngIf="
                                NewShippingAddressForm.get('addressLine1')
                                  ?.invalid &&
                                (NewShippingAddressForm.get('addressLine1')
                                  ?.dirty ||
                                  NewShippingAddressForm.get('addressLine1')
                                    ?.touched)
                              "
                              class="textMessage error-message"
                            >
                              <div
                                class="textMessage error-message"
                                *ngIf="NewShippingAddressForm.get('addressLine1')?.errors?.['required'] || NewShippingAddressForm.get('addressLine1')?.errors?.['minlength'] ||NewShippingAddressForm.get('addressLine1')?.errors?.['maxlength'] || NewShippingAddressForm.get('addressLine1')?.errors?.['pattern']"
                              >
                                Enter valid address
                              </div>
                            </div>
                          </mat-form-field>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 mb-2">
                          <mat-form-field class="d-block">
                            <mat-label class="">Address Line2 </mat-label>
                            <input
                              autocomplete="on"
                              matInput
                              formControlName="addressLine2"
                              name="addressLine2"
                              maxlength="50"
                            />
                            <div
                              class=""
                              *ngIf="
                                NewShippingAddressForm.get('addressLine2')
                                  ?.invalid
                              "
                              class="textMessage error-message"
                            >
                              <div
                                class="textMessage error-message"
                                *ngIf="NewShippingAddressForm.get('addressLine2')?.errors?.['minlength'] ||NewShippingAddressForm.get('addressLine2')?.errors?.['maxlength'] "
                              >
                                Enter valid address
                              </div>
                            </div>
                          </mat-form-field>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6 mb-2">
                          <mat-form-field class="d-block">
                            <mat-label class="">City </mat-label>
                            <input
                              autocomplete="on"
                              matInput
                              formControlName="city"
                              name="city"
                              required
                              maxlength="50"
                            />
                            <div
                              class=""
                              *ngIf="
                                NewShippingAddressForm.get('city')?.invalid &&
                                (NewShippingAddressForm.get('city')?.dirty ||
                                  NewShippingAddressForm.get('city')?.touched)
                              "
                              class="textMessage error-message"
                            >
                              <div
                                class="textMessage error-message"
                                *ngIf="NewShippingAddressForm.get('city')?.errors?.['required'] || NewShippingAddressForm.get('city')?.errors?.['minlength'] || NewShippingAddressForm.get('city')?.errors?.['pattern']"
                              >
                                Enter valid city
                              </div>
                            </div>
                          </mat-form-field>
                        </div>
                        <div
                          class="col-lg-6 col-md-6 col-sm-6 mb-2 col-6 pr-0px"
                        >
                          <mat-form-field class="d-block">
                            <mat-label
                              >State
                              <!-- <span
                                              class="none"
                                              [ngClass]="{
                                                'd-inline-block':
                                                NewShippingAddressForm?.state?.invalid && NewShippingAddressForm?.state?.touched
                                              }"
                                              >is required</span >-->
                            </mat-label>
                            <mat-select
                              [formControl]="
                                NewShippingAddressForm.get('state')
                              "
                              name="state"
                            >
                              <mat-option
                                *ngFor="let width of sharedService.states"
                                [value]="width.value"
                              >
                                {{ width.text }}({{ width.value }})
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>

                        <div
                          class="col-lg-6 col-md-6 col-sm-6 mb-2 col-6 pl-0px"
                        >
                          <mat-form-field class="d-block">
                            <mat-label class="">ZipCode </mat-label>
                            <input
                              autocomplete="on"
                              matInput
                              formControlName="zipCode"
                              name="zipCode"
                              required
                              maxlength="5"
                            />
                            <div
                              class=""
                              *ngIf="
                                NewShippingAddressForm.get('zipCode')
                                  ?.invalid &&
                                (NewShippingAddressForm.get('zipCode')?.dirty ||
                                  NewShippingAddressForm.get('zipCode')
                                    ?.touched)
                              "
                              class="textMessage error-message"
                            >
                              <div
                                class="textMessage error-message"
                                *ngIf="NewShippingAddressForm.get('zipCode')?.errors?.['required'] || NewShippingAddressForm.get('zipCode')?.errors?.['minlength'] || NewShippingAddressForm.get('zipCode')?.errors?.['pattern']"
                              >
                                Enter valid Code
                              </div>
                            </div>
                          </mat-form-field>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 mb-2">
                          <mat-form-field class="d-block">
                            <mat-label>Country</mat-label>
                            <mat-select
                              [formControl]="
                                NewShippingAddressForm.get('country')
                              "
                              name="country"
                            >
                              <mat-option
                                *ngFor="let width of lookup?.countries"
                                [value]="width.value"
                              >
                                {{ width.name }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                    </form>

                    <div class="row mb-1" *ngIf="!edit">
                      <div class="col-lg-6 col-md-12 col-sm-12 mb-0">
                        <div class="d-flex justify-content-between">
                          <span class="spec-text base-color"
                            >Shipping Information</span
                          >
                        </div>

                        <p class="text-eyebrow-small mb-0 pl-20px payment-des">
                          Address line 1, Address Line 2<br />
                          Sacramanto, NewYork(NY) 10012<br />
                          United States Of America
                        </p>
                      </div>

                      <div class="col-lg-6 col-md-12 col-sm-12 mb-0">
                        <div class="d-flex justify-content-between">
                          <span class="spec-text base-color"
                            >Billing Information</span
                          >
                        </div>
                        <p class="text-eyebrow-small mb-0 pl-20px payment-des">
                          Address line 1, Address Line 2<br />
                          Sacramanto, NewYork(NY) 10012<br />
                          United States Of America
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card mt-3">
                  <div class="card-body box-shadow">
                    <div class="row mb-3">
                      <div class="col-lg-12 col-md-12 col-sm-12 mb-0">
                        <div class="d-flex justify-content-between">
                          <div
                            class="skeleton-loader mb-2 loader-height"
                            style="width: 50%"
                          ></div>
                        </div>
                      </div>
                    </div>

                    <div class="skeleton-loader w-100 float-left mb-2">
                      <div class="">
                        <label class="tr-loader-text">
                          >ACH OR Credit Account</label
                        >
                      </div>
                    </div>

                    <!-- <div class="skeleton-loader w-100 float-left">
                        <div class="">
                          <label class="tr-loader-text">
                            >Card Payment</label>
                        </div>
                      </div> -->
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div
                class="ccontainer order-summary-mobile mt-20px mb-2 px-0"
                *ngIf="isLoading == false"
              >
                <div class="card">
                  <div class="card-body box-shadow">
                    <div class="row mb-1">
                      <div class="col-lg-12 col-md-12 col-sm-12 my-3">
                        <div class="d-flex justify-content-between">
                          <span class="spec-text base-color"
                            >Contact Information</span
                          >
                          <a
                            class="order-edit mb-3"
                            style="
                              text-decoration: underline;
                              font-weight: bold;
                            "
                            *ngIf="!edit"
                            (click)="editAddress(true)"
                            >Edit</a
                          >
                        </div>
                        <div class="pl-20px">
                          <i class="fas fa-regular fa-user mr-2"></i>
                          <span class="text-eyebrow-small mb-0 payment-des">
                            {{ userDetail?.firstname }}
                            {{ userDetail?.Lastname }}
                          </span>
                        </div>
                        <div class="pl-20px">
                          <i class="fas fa-solid fa-envelope mr-2"></i>
                          <span class="primary-color"
                            >{{ userDetail?.username }}
                          </span>
                        </div>
                        <div class="pl-20px">
                          <!-- <strong>Contact#:</strong> -->
                          <i class="fas fa-solid fa-phone mr-2"></i>
                          <span class="text-eyebrow-small mb-0 payment-des">
                            {{ userDetail?.phone }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      class="t-payment-con w-100 float-left"
                      *ngIf="addressList?.length > 0"
                    >
                      <h5 class="color-333 font-weight500 border-bottom">
                        Shipping Address
                      </h5>
                      <div
                        class="pay-box w-100 float-left"
                        *ngFor="let address of addressList; let i = index"
                      >
                        <div class="payment-title">
                          <input
                            type="radio"
                            checked
                            [id]="address.id"
                            name="address.id"
                            value="{{ address.id }}"
                            (change)="onAddressChange($event, i)"
                            [checked]="i === 0 && showAddressForm == false"
                          />
                          <label [for]="address.id" class="mb-0 font-weight500">
                            {{ address.addressLine1 }} ,
                            {{ address.addressLine2 }}
                            <span *ngIf="address.addressLine2">,</span>
                            {{ address.city }} {{ address.state }}
                            {{ address.zipCode }} ,
                            {{ address.country }}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div
                      class="t-generic-btn1-wholesale"
                      style="text-align: right"
                      *ngIf="userDetail"
                    >
                      <button mat-button (click)="showNewShippingAddressForm()">
                        <a class="mb-0 add_new_address"
                          ><i class="fas fa-plus mr-2 shipping-address-icon"></i
                          >New Shipping Address</a
                        >
                      </button>
                    </div>

                    <form
                      [formGroup]="NewShippingAddressForm"
                      class="shipping-form"
                      *ngIf="showAddressForm"
                    >
                      <h5
                        class="font-weight500 color-333 mb-sm base-color shipping-heading mb-0"
                      >
                        Shipping Address
                      </h5>

                      <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 mb-2">
                          <mat-form-field class="d-block">
                            <mat-label class="">Address Line1 </mat-label>
                            <input
                              autocomplete="on"
                              matInput
                              formControlName="addressLine1"
                              name="addressLine1"
                              required
                              maxlength="50"
                            />
                            <div
                              class=""
                              *ngIf="
                                NewShippingAddressForm.get('addressLine1')
                                  ?.invalid &&
                                (NewShippingAddressForm.get('addressLine1')
                                  ?.dirty ||
                                  NewShippingAddressForm.get('addressLine1')
                                    ?.touched)
                              "
                              class="textMessage error-message"
                            >
                              <div
                                class="textMessage error-message"
                                *ngIf="NewShippingAddressForm.get('addressLine1')?.errors?.['required'] || NewShippingAddressForm.get('addressLine1')?.errors?.['minlength'] ||NewShippingAddressForm.get('addressLine1')?.errors?.['maxlength'] || NewShippingAddressForm.get('addressLine1')?.errors?.['pattern']"
                              >
                                Enter valid address
                              </div>
                            </div>
                          </mat-form-field>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 mb-2">
                          <mat-form-field class="d-block">
                            <mat-label class="">Address Line2 </mat-label>
                            <input
                              autocomplete="on"
                              matInput
                              formControlName="addressLine2"
                              name="addressLine2"
                              maxlength="50"
                            />
                            <div
                              class=""
                              *ngIf="
                                NewShippingAddressForm.get('addressLine2')
                                  ?.invalid
                              "
                              class="textMessage error-message"
                            >
                              <div
                                class="textMessage error-message"
                                *ngIf="NewShippingAddressForm.get('addressLine2')?.errors?.['minlength'] ||NewShippingAddressForm.get('addressLine2')?.errors?.['maxlength'] "
                              >
                                Enter valid address
                              </div>
                            </div>
                          </mat-form-field>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6 mb-2">
                          <mat-form-field class="d-block">
                            <mat-label class="">City </mat-label>
                            <input
                              autocomplete="on"
                              matInput
                              formControlName="city"
                              name="city"
                              required
                              maxlength="50"
                            />
                            <div
                              class=""
                              *ngIf="
                                NewShippingAddressForm.get('city')?.invalid &&
                                (NewShippingAddressForm.get('city')?.dirty ||
                                  NewShippingAddressForm.get('city')?.touched)
                              "
                              class="textMessage error-message"
                            >
                              <div
                                class="textMessage error-message"
                                *ngIf="NewShippingAddressForm.get('city')?.errors?.['required'] || NewShippingAddressForm.get('city')?.errors?.['minlength'] || NewShippingAddressForm.get('city')?.errors?.['pattern']"
                              >
                                Enter valid city
                              </div>
                            </div>
                          </mat-form-field>
                        </div>
                        <div
                          class="col-lg-6 col-md-6 col-sm-6 mb-2 col-6 pr-0px"
                        >
                          <mat-form-field class="d-block">
                            <mat-label
                              >State
                              <!-- <span
                                            class="none"
                                            [ngClass]="{
                                              'd-inline-block':
                                              NewShippingAddressForm?.state?.invalid && NewShippingAddressForm?.state?.touched
                                            }"
                                            >is required</span >-->
                            </mat-label>
                            <mat-select
                              [formControl]="
                                NewShippingAddressForm.get('state')
                              "
                              name="state"
                            >
                              <mat-option
                                *ngFor="let width of sharedService.states"
                                [value]="width.value"
                              >
                                {{ width.text }}({{ width.value }})
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>

                        <div
                          class="col-lg-6 col-md-6 col-sm-6 mb-2 col-6 pl-0px"
                        >
                          <mat-form-field class="d-block">
                            <mat-label class="">ZipCode </mat-label>
                            <input
                              autocomplete="on"
                              matInput
                              formControlName="zipCode"
                              name="zipCode"
                              required
                              maxlength="5"
                            />
                            <div
                              class=""
                              *ngIf="
                                NewShippingAddressForm.get('zipCode')
                                  ?.invalid &&
                                (NewShippingAddressForm.get('zipCode')?.dirty ||
                                  NewShippingAddressForm.get('zipCode')
                                    ?.touched)
                              "
                              class="textMessage error-message"
                            >
                              <div
                                class="textMessage error-message"
                                *ngIf="NewShippingAddressForm.get('zipCode')?.errors?.['required'] || NewShippingAddressForm.get('zipCode')?.errors?.['minlength'] || NewShippingAddressForm.get('zipCode')?.errors?.['pattern']"
                              >
                                Enter valid Code
                              </div>
                            </div>
                          </mat-form-field>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 mb-2">
                          <mat-form-field class="d-block">
                            <mat-label>Country</mat-label>
                            <mat-select
                              [formControl]="
                                NewShippingAddressForm.get('country')
                              "
                              name="country"
                            >
                              <mat-option
                                *ngFor="let width of lookup?.countries"
                                [value]="width.value"
                              >
                                {{ width.name }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                    </form>

                    <!-- <form [formGroup]="addBillingAddressInfoForm" class="shipping-form" *ngIf="showAddressForm">
                                  <div class="w-100  my-3">
                                    <h5 class="font-weight500 color-333 base-color address-info-heading mb-3">
                                      Billing Address
                                    </h5>
                                    <div class="pay-box pay-box2 w-100 ">
                                      <div class="payment-title">
                                        <input type="radio" name="radio" (click)="showbilling(false)" checked="showbilling(false)"
                                          id="billing" />
                                        <label for="billing" class="mb-0 font-weight500">Same as shipping address</label>
                                      </div>
                                    </div>
                                    <div class="pay-box w-100">
                                      <div class="payment-title">
                                        <input type="radio" name="radio" (click)="showbilling(true)" id="shipping" />
                                        <label for="shipping" class="mb-0 font-weight500">Use a different billing address</label>
                                      </div>
                                      <div class="payment-body" *ngIf="showBillingForm">
                                        <form class="shipping-form m-3" [formGroup]="addBillingAddressInfoForm">
                                          <div class="row">
                                            <div class="col-lg-6 col-md-6 col-sm-6 mb-2">
                                              <mat-form-field class="d-block">
                                                <mat-label class="">Address Line1 </mat-label>
                                                <input autocomplete="on" matInput formControlName="addressLine1" name="addressLine1"
                                                  required maxlength="50" />
                                                <div class="" *ngIf="
                                                      addBillingAddressInfoForm.get(
                                                        'addressLine1'
                                                      )?.invalid &&
                                                      (addBillingAddressInfoForm.get(
                                                        'addressLine1'
                                                      )?.dirty ||
                                                        addBillingAddressInfoForm.get(
                                                          'addressLine1'
                                                        )?.touched)
                                                    " class="textMessage error-message">
                                                  <div class="textMessage error-message"
                                                    *ngIf="addBillingAddressInfoForm.get('addressLine1')?.errors?.['required'] || addBillingAddressInfoForm.get('addressLine1')?.errors?.['minlength'] || addBillingAddressInfoForm.get('addressLine1')?.errors?.['pattern']">
                                                    Enter valid address
                                                  </div>
                                                </div>
                                              </mat-form-field>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6 mb-2">
                                              <mat-form-field class="d-block">
                                                <mat-label class="">Address Line2 </mat-label>
                                                <input autocomplete="on" matInput formControlName="addressLine2" name="addressLine2"
                                                  maxlength="50" />
                                                <div class="" *ngIf="
                                                      addBillingAddressInfoForm.get(
                                                        'addressLine2'
                                                      )?.invalid
                                                    " class="textMessage error-message">
                                                  <div class="textMessage error-message"
                                                    *ngIf=" addBillingAddressInfoForm.get('addressLine2')?.errors?.['minlength']|| addBillingAddressInfoForm.get('addressLine2')?.errors?.['minlength'] ">
                                                    Enter valid address
                                                  </div>
                                                </div>
                                              </mat-form-field>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6 mb-2">
                                              <mat-form-field class="d-block">
                                                <mat-label class="">City </mat-label>
                                                <input autocomplete="on" matInput formControlName="city" name="city" required
                                                  maxlength="50" />
                                                <div class="" *ngIf="
                                                      addBillingAddressInfoForm.get('city')
                                                        ?.invalid &&
                                                      (addBillingAddressInfoForm.get('city')
                                                        ?.dirty ||
                                                        addBillingAddressInfoForm.get('city')
                                                          ?.touched)
                                                    " class="textMessage error-message">
                                                  <div class="textMessage error-message"
                                                    *ngIf="addBillingAddressInfoForm.get('city')?.errors?.['required'] || addBillingAddressInfoForm.get('city')?.errors?.['minlength'] || addBillingAddressInfoForm.get('city')?.errors?.['pattern']">
                                                    Enter valid city
                                                  </div>
                                                </div>
                                              </mat-form-field>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6 mb-2">
                                              <mat-form-field class="d-block">
                                                <mat-label>State</mat-label>
                                               
                                                <mat-select [formControl]="addBillingAddressInfoForm.get('state')" name="state">
                                                  <mat-option *ngFor="let width of sharedService.states" [value]="width.value">
                                                    {{ width.text }}({{ width.value }})
                                                  </mat-option>
                                                  <mat-error class="textMessage error-message"
                                                    *ngIf="addBillingAddressInfoForm.get('state')?.errors?.['required'] ">
                                                    Please select state
                                                  </mat-error>
                                                </mat-select>
                                              </mat-form-field>
                                            </div>
              
                                            <div class="col-lg-6 col-md-6 col-sm-6 mb-2">
                                              <mat-form-field class="d-block">
                                                <mat-label class="">zipCode </mat-label>
                                                <input autocomplete="on" matInput formControlName="zipCode" name="zipCode" required
                                                  maxlength="5" />
                                                <div class="" *ngIf="
                                                      addBillingAddressInfoForm.get('zipCode')
                                                        ?.invalid &&
                                                      (addBillingAddressInfoForm.get('zipCode')
                                                        ?.dirty ||
                                                        addBillingAddressInfoForm.get('zipCode')
                                                          ?.touched)
                                                    " class="textMessage error-message">
                                                  <div class="textMessage error-message"
                                                    *ngIf="addBillingAddressInfoForm.get('zipCode')?.errors?.['required'] || addBillingAddressInfoForm.get('zipCode')?.errors?.['minlength'] || addBillingAddressInfoForm.get('zipCode')?.errors?.['pattern']">
                                                    Enter valid Code
                                                  </div>
                                                </div>
                                              </mat-form-field>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6 mb-2">
                                              <mat-form-field class="d" style="display: block;">
                                                <mat-label>Country</mat-label>
                                                <mat-select [formControl]="
                                                      addBillingAddressInfoForm.get('country')
                                                    " name="country">
                                                  <mat-option *ngFor="let width of lookup.countries" [value]="width.value">
                                                    {{ width.name }}
                                                  </mat-option>
                                                </mat-select>
                                              </mat-form-field>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </form> -->

                    <div class="row mb-1" *ngIf="!edit">
                      <div class="col-lg-6 col-md-12 col-sm-12 mb-0">
                        <div class="d-flex justify-content-between">
                          <span class="spec-text base-color"
                            >Shipping Information</span
                          >
                        </div>

                        <p class="text-eyebrow-small mb-0 pl-20px payment-des">
                          Address line 1, Address Line 2<br />
                          Sacramanto, NewYork(NY) 10012<br />
                          United States Of America
                        </p>
                      </div>

                      <div class="col-lg-6 col-md-12 col-sm-12 mb-0">
                        <div class="d-flex justify-content-between">
                          <span class="spec-text base-color"
                            >Billing Information</span
                          >
                        </div>
                        <p class="text-eyebrow-small mb-0 pl-20px payment-des">
                          Address line 1, Address Line 2<br />
                          Sacramanto, NewYork(NY) 10012<br />
                          United States Of America
                        </p>
                      </div>
                    </div>

                    <!-- <div class="row">
                                  <div class="col-lg-6 col-md-12 col-sm-12 mb-0">
                                    <div class="d-flex justify-content-between">
                                      <span class="spec-text base-color">Billing Information</span>
                                    </div>
                                    <p class="text-eyebrow-small mb-0 pl-20px payment-des">
                                      Address line 1, Address Line 2<br />
                                      Sacramanto, NewYork(NY) 10012<br />
                                      United States Of America
                                    </p>
                                  </div>
                                </div> -->
                  </div>
                </div>

                <div class="card mt-3">
                  <div class="card-body box-shadow">
                    <div class="row mb-3">
                      <div class="col-lg-12 col-md-12 col-sm-12 mb-0">
                        <div class="d-flex justify-content-between">
                          <span class="spec-text base-color ml-2"
                            >Payment Information</span
                          >
                        </div>
                      </div>
                    </div>

                    <div
                      class="pay-box pay-box2 w-100 float-left"
                      *ngIf="showCardInfo"
                    >
                      <div class="ach-payment">
                        <div class="payment-title py-2">
                          <input
                            type="radio"
                            name="radio"
                            (click)="BankingType(true)"
                            id="backwireDepositOption"
                          />
                          <label
                            for="backwireDepositOption"
                            class="mb-0 font-weight500"
                            >ACH OR Credit Account
                          </label>
                        </div>
                        <div
                          class="payment-title d-flex justify-content-end py-2"
                        >
                          <div
                            class="collapse-text primary-color"
                            data-toggle="collapse"
                            data-target="#bankinfo"
                          >
                            <span class="spec-text-vechile"
                              ><b>Read more about ACH/Credit account</b></span
                            >
                            <span class="mx-1 collapse-drop-btn"
                              ><svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-chevron-down"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                />
                              </svg>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div id="bankinfo" class="collapse">
                        <div>
                          <div class="alert alert-danger mb-2" role="alert">
                            <b
                              >Credit accounts are available for 2 weeks. For a
                              credit account please contact our support team via
                              Email/Phone.
                            </b>
                          </div>
                        </div>
                        <div>
                          <div class="alert alert-primary mb-2" role="alert">
                            <h6 class="alert-heading">
                              ACH payment in case of don't have credit account.
                            </h6>
                            <hr class="my-2" />
                            <div class="ml-2">
                              <b>Bank Of America</b>
                              <p class="mb-0">Ryder Group LLC</p>
                              <p class="mb-0">
                                <b>Account# : </b> 325146708475
                              </p>
                              <p class="mb-0"><b>Routing# : </b> 026009593</p>
                            </div>
                            <hr class="my-2" />
                            <div class="alert alert-warning mb-0" role="alert">
                              <b>Steps for ACH</b>
                              <p class="mb-0" style="font-size: 0.8rem">
                                <b>1.</b> Click on the
                                <b>'Order Confirmed'</b> button. You will
                                receive an order confirmation email containing
                                the order number (e.g., 'CP-00001') and the
                                Total Payable Amount.
                              </p>
                              <p class="mb-0" style="font-size: 0.8rem">
                                <b>2.</b> Deposit the order's payable amount
                                through bank transfer.
                              </p>
                              <p class="mb-0" style="font-size: 0.8rem">
                                <b>3.</b> Inform us regarding your deposit via
                                Email or Call.
                              </p>
                              <div class="alert alert-danger mb-2" role="alert">
                                Your order will be confimed after ACH.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="errorMessage">
                        <div class="alert alert-danger mb-2" role="alert">
                          <b>{{ errorMessage }} </b>
                        </div>
                      </div>
                      <div
                        class="t-generic-btn1 my-2"
                        *ngIf="achcheckoutButton == true"
                        style="text-align: center"
                      >
                        <button
                          mat-button
                          style="background: #3b71ca !important"
                          (click)="createOrder()"
                          [disabled]="disbled == true"
                          [ngClass]="{ disbled: disbled == true }"
                        >
                          Order Confirmed
                        </button>
                      </div>
                    </div>

                    <div class="pay-box w-100 float-left">
                      <div class="payment-title">
                        <input
                          type="radio"
                          name="radio"
                          (click)="BankingType(false)"
                          id="CCPaymentOption"
                        />
                        <label for="CCPaymentOption" class="mb-0 font-weight500"
                          >Card Payment</label
                        >
                      </div>

                      <form [formGroup]="form_Payment" *ngIf="showCard">
                        <ng-template matStepLabel>
                          <p class="mb-0">Payment</p>
                        </ng-template>
                        <div id="link-authentication-element"></div>
                        <div id="payment-element"></div>
                        <div
                          class="t-generic-btn1 my-2"
                          style="text-align: center"
                        >
                          <button
                            mat-button
                            *ngIf="showOrderButton == true"
                            style="background: #3b71ca !important"
                            (click)="createOrderPayment()"
                            style="background: #0275d8 !important"
                          >
                            <span>Pay Now</span>
                          </button>
                        </div>

                        <div id="payment-message" class="hidden"></div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div class="col-lg-4 col-md-12 order-summary-checkout">
            <section class="skele" *ngIf="isLoading == true">
              <div class="">
                <div
                  class="d-flex justify-content-between mx-2 skeleton-loader"
                >
                  <h2 class="summary-heading tr-loader-text">Order Summary</h2>
                  <a href="/disposable/cart" class="tr-loader-text"
                    ><u>Edit order</u></a
                  >
                </div>

                <div class="">
                  <div
                    class="card shadow order-card"
                    *ngFor="let item of cartItems"
                  >
                    <div class="card-body border-bottom order-card-padding">
                      <div class="row">
                        <div class="col-lg-3 col-md-2 col-3">
                          <div
                            class="cart-img-box skeleton-loader"
                            style="height: 70px"
                          ></div>
                        </div>
                        <div class="col-lg-9 col-md-10 col-9 pl-0">
                          <div class="tire-spec">
                            <div
                              class="tire-spec-wrapper tr-font mb-8 skeleton-loader"
                            >
                              <p
                                class="mb-2 text-small tr-loader-text"
                                data-qa-id="tire-product-tireSizeCode"
                              >
                                <b>{{ item?.variant?.catalogueName }}</b>
                              </p>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-7 col-9">
                              <div class="d-flex ml-3">
                                <div class="row mr-1 skeleton-loader">
                                  <h3
                                    class="item-price grand-total tr-loader-text"
                                  >
                                    ${{
                                      item.variant?.salesPrice
                                        | number : "1.2-2"
                                    }}
                                  </h3>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-5 col-3">
                              <div
                                class="d-flex justify-content-end skeleton-loader"
                              >
                                <h3
                                  class="total-price grand-total tr-loader-text"
                                >
                                  ${{
                                    item?.quantity *
                                      (item?.variant?.salesPrice +
                                        (item?.variant?.tax > 0
                                          ? item?.variant?.tax
                                          : 0)) | number : "1.2-2"
                                  }}
                                </h3>
                                <!-- <span class="text-small base-color">ea</span> -->
                              </div>
                            </div>
                            <div class="divider"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card" style="border-bottom: none; margin-top: 20px">
                <div class="card-body p-0">
                  <div class="row" style="margin-bottom: 20px">
                    <div class="col">
                      <div
                        class="d-flex justify-content-between align-items-center skeleton-loader"
                      >
                        <p class="text-standard mb-0 tr-loader-text">
                          Subtotal
                        </p>
                        <strong class="subtitle-small tr-loader-text"
                          >$ {{ subTotalAmount | number : "1.2-2" }}
                        </strong>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin-bottom: 20px">
                    <div class="col">
                      <div
                        class="d-flex justify-content-between align-items-center skeleton-loader"
                      >
                        <p class="text-standard mb-0 tr-loader-text">
                          Shipping
                        </p>
                        <strong class="subtitle-small tr-loader-text"
                          >$0.00</strong
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin-bottom: 20px">
                    <div class="col">
                      <div
                        class="d-flex justify-content-between align-items-center skeleton-loader"
                      >
                        <p class="text-standard mb-0 tr-loader-text">FET</p>
                        <strong class="subtitle-small tr-loader-text"
                          >${{ totalTax | number : "1.2-2" }}</strong
                        >
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-6 skeleton-loader">
                      <h5 class="total-heading tr-loader-text">Total</h5>
                    </div>

                    <div class="col-6 skeleton-loader">
                      <h5
                        class="text-right total-price grand-total tr-loader-text"
                      >
                        ${{ totalAmount | number : "1.2-2" }}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section *ngIf="isLoading == false">
              <div class="">
                <div class="d-flex justify-content-between mx-2">
                  <h2 class="summary-heading base-color">Order Summary</h2>
                  <a href="/cart" class="order-edit mb-3"><u>Edit order</u></a>
                </div>

                <div class="">
                  <div
                    class="card shadow order-card"
                    *ngFor="let item of cartItems"
                  >
                    <div class="card-body border-bottom order-card-padding">
                      <div class="row">
                        <div class="col-lg-3 col-md-2 col-3">
                          <div class="cart-img-box">
                            <img
                              *ngIf="
                                item?.variant?.image == null ||
                                item?.variant?.image == ''
                              "
                              src="assets/images/tyreryder_images/img_not_available.jpg"
                              class="img-fluid"
                              alt="{{ item?.catalogue?.description }}"
                            />
                            <img
                              *ngIf="
                                item?.variant?.image != null &&
                                item?.variant?.image != ''
                              "
                              src="{{
                                item?.variant?.image.replace('lg-', 'sm-')
                              }}"
                              class="img-fluid"
                              alt="{{ item?.catalogue?.description }}"
                            />
                          </div>
                        </div>
                        <div class="col-lg-9 col-md-10 col-9 pl-0">
                          <div class="tire-spec">
                            <div class="tire-spec-wrapper tr-font mb-8">
                              <p
                                class="mb-2 text-small"
                                data-qa-id="tire-product-tireSizeCode"
                              >
                                <b>{{ item?.variant?.catalogueName }}</b>
                              </p>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-7 col-9">
                              <div class="d-flex ml-3">
                                <div class="row mr-1">
                                  <h3 class="item-price grand-total base-color">
                                    ${{
                                      item.variant?.salePrice | number : "1.2-2"
                                    }}
                                  </h3>
                                  <h3
                                    class="item-price grand-total base-color"
                                    *ngIf="item.variant?.tax"
                                  >
                                    + ${{ item.variant?.tax | number : "1.2-2"
                                    }}<small class="color-B31B1B">(FET)</small>
                                  </h3>
                                </div>
                                <div>
                                  <h3 class="item-price grand-total">
                                    X {{ item?.quantity }}
                                  </h3>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-5 col-3">
                              <div class="d-flex justify-content-end">
                                <h3 class="total-price grand-total base-color">
                                  ${{
                                    item?.quantity *
                                      (item?.variant?.salePrice +
                                        (item?.variant?.tax > 0
                                          ? item?.variant?.tax
                                          : 0)) | number : "1.2-2"
                                  }}
                                </h3>
                                <!-- <span class="text-small base-color">ea</span> -->
                              </div>
                            </div>
                            <div class="divider"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card" style="border-bottom: none; margin-top: 20px">
                <div class="card-body p-0">
                  <div class="row" style="margin-bottom: 20px">
                    <div class="col">
                      <div
                        class="d-flex justify-content-between align-items-center"
                      >
                        <p class="text-standard mb-0">Subtotal</p>
                        <strong class="subtitle-small"
                          >$ {{ subTotalAmount | number : "1.2-2" }}
                        </strong>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin-bottom: 20px">
                    <div class="col">
                      <div
                        class="d-flex justify-content-between align-items-center"
                      >
                        <p class="text-standard mb-0">Shipping</p>
                        <strong class="subtitle-small">$0.00</strong>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin-bottom: 20px">
                    <div class="col">
                      <div
                        class="d-flex justify-content-between align-items-center"
                      >
                        <p class="text-standard mb-0">FET</p>
                        <strong class="subtitle-small"
                          >${{ totalTax | number : "1.2-2" }}</strong
                        >
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-6">
                      <h5 class="total-heading base-color">Total</h5>
                    </div>

                    <div class="col-6">
                      <h5 class="text-right total-price grand-total base-color">
                        ${{ totalAmount | number : "1.2-2" }}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
