import { RouterModule, Routes } from '@angular/router';
import { WholesalesComponent } from './wholesales/wholesales.component';
import { B2BAuthGuard } from './core/guard/AuthGuard/b2bauth.guard';
import { CardDetailComponent } from './wholesales/card-detail/card-detail.component';
import { InvoiceDetailComponent } from './wholesales/invoice-detail/invoice-detail.component';
import { InvoiceComponent } from './wholesales/invoice/invoice.component';
import { WholeSaleOrderDetailComponent } from './wholesales/whole-sale-order-detail/whole-sale-order-detail.component';
import { WholesaleCartComponent } from './wholesales/wholesale-cart/wholesale-cart.component';
import { WholesaleCheckoutComponent } from './wholesales/wholesale-checkout/wholesale-checkout.component';
import { WholesaleLoginComponent } from './wholesales/wholesale-login/wholesale-login.component';
import { WholesaleOrderHistoryComponent } from './wholesales/wholesale-order-history/wholesale-order-history.component';
import { WholesaleThankyouComponent } from './wholesales/wholesale-thankyou/wholesale-thankyou.component';
import { WholesaleProductsComponent } from './wholesales/wholesale-products/wholesale-products.component';
import { ProfileComponent } from './wholesales/profile/profile.component';
import { NgModule } from '@angular/core';
import { DisposableOrderListingComponent } from './disposable/disposable-order-listing/disposable-order-listing.component';
import { DisposableOrderDetailComponent } from './disposable/disposable-order-detail/disposable-order-detail.component';
import { DisposableproductComponent } from './disposable/disposableproduct/disposableproduct.component';
import { DisposableCartComponent } from './disposable/disposable-cart/disposable-cart.component';
import { DisposableCheckoutComponent } from './disposable/disposable-checkout/disposable-checkout.component';
import { DisposableThankyouComponent } from './disposable/disposable-thankyou/disposable-thankyou.component';

export const routes: Routes = [
    {
        path: '',
        component: WholesaleLoginComponent,
        children: [
            { path: 'login', component: WholesaleLoginComponent },
        ]
    },
    {
        path: '',
        component: WholesalesComponent,
        children: [
            {
                path: 'wholeSale/products',
                component: WholesaleProductsComponent,
                canActivate: [B2BAuthGuard],
            },
            {
                path: 'wholeSale/orders',
                component: WholesaleOrderHistoryComponent,
                canActivate: [B2BAuthGuard],
            },
            {
                path: 'wholeSale/order-detail/:permaLink',
                component: WholeSaleOrderDetailComponent,
                canActivate: [B2BAuthGuard],
            },
            {
                path: 'disposable/products',
                component: DisposableproductComponent,
                canActivate: [B2BAuthGuard],
            },
            {
                path: 'disposable/orders',
                component: DisposableOrderListingComponent,
                canActivate: [B2BAuthGuard],
            },
            {
                path: 'disposable/order-detail/:permaLink',
                component: DisposableOrderDetailComponent,
                canActivate: [B2BAuthGuard],
            },
            {
                path: 'disposable/cart',
                component: DisposableCartComponent,
                canActivate: [B2BAuthGuard],
            },
            {
                path: 'disposable/checkout',
                component: DisposableCheckoutComponent,
                canActivate: [B2BAuthGuard],
            },
            {
                path: 'wholesale/cart',
                component: WholesaleCartComponent,
                canActivate: [B2BAuthGuard],
            },
            {
                path: 'wholesale/checkout',
                component: WholesaleCheckoutComponent,
                canActivate: [B2BAuthGuard],
            },
            {
                path: 'profile',
                component: ProfileComponent,
                canActivate: [B2BAuthGuard],
            },
            {
                path: 'payments',
                component: CardDetailComponent,
                canActivate: [B2BAuthGuard],
            },
            {
                path: 'invoice',
                component: InvoiceComponent,
                canActivate: [B2BAuthGuard],
            },
            {
                path: 'invoice-detail/:permaLink',
                component: InvoiceDetailComponent,
                canActivate: [B2BAuthGuard],
            },
            {
                path: 'thank-you/:orderId',
                component: WholesaleThankyouComponent,
                canActivate: [B2BAuthGuard],
            },
            {
                path: 'disposable/thank-you/:orderId',
                component: DisposableThankyouComponent,
                canActivate: [B2BAuthGuard],
            },
        ],
    },
];
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }
  
