import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import {FormControl, Validators, FormsModule, ReactiveFormsModule, FormBuilder, FormGroup} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import { NgIf, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { B2bService } from '../../../services/b2b/b2b.service';
import { InvoiceService } from '../../../services/invoice/invoice.service';
import { SharedService } from '../../../services/shared/shared.service';
@Component({
  selector: 'app-card-detail',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    NgIf,
  ],
  templateUrl: './card-detail.component.html',
  styleUrl: './card-detail.component.css'
})
export class CardDetailComponent implements OnInit{
  user:any;
  isBrowser: any;
  isServer: any;
  cardDetail:any;
  cardForm!: FormGroup;
  achDetail:any;
  constructor(private invoiceService:InvoiceService, private sharedService:SharedService ,private b2bService: B2bService ,private formBuilder:FormBuilder ,@Inject(PLATFORM_ID) platformId: Object){
    this.isBrowser = isPlatformBrowser(platformId);
    this.isServer = isPlatformServer(platformId);
  }
  emailFormControl = new FormControl('', [Validators.required]);
  ngOnInit(): void {
    if (this.isBrowser) {
      this.user = localStorage.getItem('user');
      this.user = JSON.parse(this.user);
      this.getCard();
      this.initForm();
      this.getACH();
    }
  }
  initForm() {
    this.cardForm = this.formBuilder.group({
      branchName: ['', [Validators.required]],
      bankName: ['', [Validators.required]],
      accountTitle: ['', [Validators.required]],
      holderName: ['', [Validators.required]],
      accountNumber: ['', [Validators.required]],
      bankRoutingNumber: ['', [Validators.required]],
    });
  }
  getCard() {
    this.invoiceService.getCredit(this.user.userId).subscribe({
      next: (res: any) => {
        console.log(res);
        this.cardDetail=res.body.response
      }
    })
  }
  allFormValidation(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control: any) => {
      control.markAsTouched();
    });
  }
  AddCardDetail() {
    if (this.cardForm?.valid) {
      this.b2bService.cardDetail(this.mapData()).subscribe(
        {
          next: (res: any) => {
            if (res.status == 200) {
              this.sharedService.showGeneralMsgModal('Details Updated Successfully')
            }
            else{
              this.sharedService.showGeneralMsgModal('Error')
            }
          },
        })
    }
    else {
      this.allFormValidation(this.cardForm);
    }
  }
  mapData() {
    let cardInfo = {
      "Id": this.achDetail.id,
      "CustomerId": this.user.userId,
      "BankName": this.cardForm?.controls['bankName'].value,
      "Branch": this.cardForm?.controls['branchName'].value,
      "bankRoutingNumber": this.cardForm?.controls['bankRoutingNumber'].value,
      "accountTitle": this.cardForm?.controls['accountTitle'].value,
      "accountNumber": this.cardForm?.controls['accountNumber'].value,
      "AccountHolderName": this.cardForm?.controls['holderName'].value,
      "SavedBy": this.achDetail.accountTitle,
      "Defult": true
    }
    return cardInfo;
  }
  getACH() {
    this.b2bService.getACH(this.user.userId).subscribe({
      next: (res: any) => {
        this.achDetail=res.body.response;
      }
    })
  }
}

