import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../app/core/enviroment/environment';
import { StaticService } from '../static/static.service';

@Injectable({
    providedIn: 'root'
})
export class InvoiceService {
    private httpOptions: any;
    headers: any;
    private baseUrl = '';

    constructor(private http: HttpClient) {
        this.init();
        this.baseUrl = environment.apiUrl;
    }

    public init(): void {

        this.headers = new HttpHeaders({ 'Content-Type': 'application/json' })

        this.httpOptions = {
            headers: this.headers, observe: 'response'
        };
    }

    public InvoiceListing(
        userId: any,
        PageNo: any,
        PageSize: any,): Observable<any> {
        var apiUrl = StaticService.INVOICE_LISTING.replace(':customerid', userId);

        return this.http.get<any>(this.baseUrl + apiUrl + '?PageNo=' + PageNo + '&PageSize=' + PageSize, this.httpOptions);
    }
    public getInvoiceDetail(userId: any, perma: any): Observable<any> {
        var invoicedetail = StaticService.INVOICE_DETAIL.replace(':customerid', userId)
        invoicedetail = invoicedetail.replace(':invoiceId', perma)
        return this.http.get<any>(this.baseUrl + invoicedetail , this.httpOptions);
    }
    public getCredit(userId: any): Observable<any> {
        var creditUrl = StaticService.GET_CREDIT.replace(':customerId', userId);
        return this.http.get<any>(this.baseUrl + creditUrl , this.httpOptions);
    }
}
