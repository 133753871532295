<!--BANNER SECTION-->
<section
  class="w-100 sec-top-header breadcrumb-section"
  style="background-color: #dcdcdc"
>
  <div class="wrapper4 d-flex tyre-ryder-header">
    <div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb p-0 m-0 breadcrumb-bg-color">
          <li class="breadcrumb-item breadcrumb-headsize">
            <a class="primary-color" href="/wholeSale/products">WholeSale</a>
          </li>

          <li
            class="breadcrumb-item breadcrumb-headsize active"
            aria-current="page"
          >
            Credit Details
          </li>
        </ol>
      </nav>
    </div>
  </div>
</section>
<section class="w-100 padding-top">
  <div class="wrapper3">
    <div class="row mb-3">
      <div class="col-lg-4 col-md-4 col-sm-6 mb-2">
        <a class="t-none">
          <div
            class="card card-effect-new box-shadow py-3 bg-success das-bradius"
          >
            <div class="card-body text-white">
              <div class="d-flex justify-content-between">
                <div class="">
                  <h3 class="text-white">{{ cardDetail?.creditLimit }}</h3>
                  <h6 class="text-white">Credit Limit</h6>
                </div>
                <div class="das-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="60"
                    height="60"
                    fill="currentColor"
                    class="bi bi-credit-card-2-back"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5z"
                    />
                    <path
                      d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zm13 2v5H1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1m-1 9H2a1 1 0 0 1-1-1v-1h14v1a1 1 0 0 1-1 1"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-6 mb-2">
        <a class="t-none">
          <div
            class="card box-shadow card-effect-dispatch bg-primary py-3 das-bradius"
          >
            <div class="card-body text-white">
              <div class="d-flex justify-content-between">
                <div class="">
                  <h3 class="text-white" *ngIf="cardDetail?.paymentTerms == 1">
                    Weekly
                  </h3>
                  <h3 class="text-white" *ngIf="cardDetail?.paymentTerms == 2">
                    Every2ndWeek
                  </h3>
                  <h3 class="text-white" *ngIf="cardDetail?.paymentTerms == 3">
                    Monthly
                  </h3>
                  <h6 class="text-white">Payment Terms</h6>
                </div>
                <div class="das-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="60"
                    height="60"
                    fill="currentColor"
                    class="bi bi-wallet2"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5z"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-6 mb-2">
        <a class="t-none">
          <div
            class="card box-shadow card-effect-completed primary-bg-color py-3 das-bradius"
          >
            <div class="card-body text-white">
              <div class="d-flex justify-content-between">
                <div class="">
                  <h3 class="text-white">{{ cardDetail?.creditUsed }}</h3>
                  <h6 class="text-white">Credit Used</h6>
                </div>
                <div class="das-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="60"
                    height="60"
                    fill="currentColor"
                    class="bi bi-wallet-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542s.987-.254 1.194-.542C9.42 6.644 9.5 6.253 9.5 6a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2z"
                    />
                    <path
                      d="M16 6.5h-5.551a2.7 2.7 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5s-1.613-.412-2.006-.958A2.7 2.7 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5z"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</section>
<section class="my-5">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <form [formGroup]="cardForm">
          <div class="row mb-2">
            <div class="col-sm-6 mb-sm-0 mb-2">
              <mat-form-field class="example-full-width" style="display: block">
                <mat-label>Branch Name</mat-label>
                <input
                  type="text"
                  placeholder="Branch"
                  id="branch"
                  class="form-control"
                  matInput
                  formControlName="branchName"
                  required
                  ngModel="{{ achDetail?.branch }}"
                />
              </mat-form-field>
            </div>
            <div class="col-sm-6 mb-sm-0 mb-2">
              <mat-form-field class="example-full-width" style="display: block">
                <mat-label>Bank Name</mat-label>
                <input
                  type="text"
                  placeholder="Bank"
                  id="bank"
                  class="form-control"
                  matInput
                  formControlName="bankName"
                  required
                  ngModel="{{ achDetail?.bankName }}"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6 mb-sm-0 mb-2">
              <mat-form-field class="example-full-width" style="display: block">
                <mat-label>Account Title</mat-label>
                <input
                  type="text"
                  placeholder="title"
                  id="title"
                  class="form-control"
                  matInput
                  formControlName="accountTitle"
                  required
                  ngModel="{{ achDetail?.accountTitle }}"
                />
              </mat-form-field>
            </div>
            <div class="col-sm-6 mb-sm-0 mb-2">
              <mat-form-field class="example-full-width" style="display: block">
                <mat-label>Account Holder Name</mat-label>
                <input
                  type="text"
                  placeholder="holdername"
                  id="holder name"
                  class="form-control"
                  matInput
                  formControlName="holderName"
                  required
                  ngModel="{{ achDetail?.accountHolderName }}"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6 mb-sm-0 mb-2">
              <mat-form-field class="example-full-width" style="display: block">
                <mat-label>Account Number</mat-label>
                <input
                  type="text"
                  placeholder="holdername"
                  id="account number"
                  class="form-control"
                  matInput
                  formControlName="accountNumber"
                  required
                  ngModel="{{ achDetail?.accountNumber }}"
                />
              </mat-form-field>
            </div>
            <div class="col-sm-6 mb-sm-0 mb-2">
              <mat-form-field class="example-full-width" style="display: block">
                <mat-label>Bank Routing Number</mat-label>
                <input
                  type="text"
                  placeholder="holdername"
                  id="bank Routing Number"
                  class="form-control"
                  matInput
                  formControlName="bankRoutingNumber"
                  required
                  ngModel="{{ achDetail?.bankRoutingNumber }}"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="text-center py-3">
            <button class="btn btn-danger" (click)="AddCardDetail()">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
