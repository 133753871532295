<div class="modal" tabindex="-1" id="modal_Loading" role="dialog" [ngStyle]="{'display': 'block'}" aria-hidden="true">
    <div class="modal-dialog modal-md">
        <div class="modal-content modal-content1">
            <div class="modal-body modal-body1">
                <div style="text-align:center">
                    <span class="spinner-border allmargin-sm" role="status" aria-hidden="true"></span>
                </div>
            </div>
        </div>
    </div>
</div>