import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../app/core/enviroment/environment';
import { StaticService } from '../static/static.service';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
baseUrl:any
headers:any
httpOptions:any
  constructor(private http: HttpClient) { 
    this.init();
    this.baseUrl = environment.apiUrl;
  }
  public init(): void {

    this.headers = new HttpHeaders({ 'Content-Type': 'application/json' })

    this.httpOptions = {
      headers: this.headers, observe: 'response'
    };
  }
  public getAttributeSizeWidth():Observable<any>
  {
    return this.http.get<any>(this.baseUrl + StaticService.ATTRIBUTE_SIZE_WIDTH, this.httpOptions);
  }
  public getAttributeSizeRatio(key1:any):Observable<any>
  {
    var ratioUrl=StaticService.ATTRIBUTE_SIZE_RATIO.replace(':key1',key1);
    return this.http.get<any>(this.baseUrl + ratioUrl, this.httpOptions);
  }
  public getAttributeSizeDiameter(key1:any,key2:any):Observable<any>
  {
    var diameterUrl=StaticService.ATTRIBUTE_SIZE_DIAMETER.replace(':key1',key1).replace(':key2',key2);
    return this.http.get<any>(this.baseUrl + diameterUrl, this.httpOptions);
  }


  public getAttributeVehicalYear():Observable<any>
  {
    return this.http.get<any>(this.baseUrl + StaticService.ATTRIBUTE_VEHICAL_YEAR, this.httpOptions);
  }
  public getAttributeVehicalMake(year:any):Observable<any>
  {
    var ratioUrl=StaticService.ATTRIBUTE_VEHICAL_MAKE.replace(':year',year);
    return this.http.get<any>(this.baseUrl + ratioUrl, this.httpOptions);
  }
  public getAttributeVehicalModel(year:any,make:any):Observable<any>
  {
    var modelUrl=StaticService.ATTRIBUTE_VEHICAL_MODEL.replace(':year',year).replace(':make',make);
    return this.http.get<any>(this.baseUrl + modelUrl, this.httpOptions);
  }
  public getAttributeVehicalTrim(year:any,make:any,model:any):Observable<any>
  {
    var trimUrl=StaticService.ATTRIBUTE_VEHICAL_TRIM.replace(':year',year).replace(':make',make).replace(':model',model);
    return this.http.get<any>(this.baseUrl + trimUrl, this.httpOptions);
  }

  // VEHICEL STARTED
  public getVehicleMake():Observable<any>
  {
    return this.http.get<any>(this.baseUrl + StaticService.VEHICLE_MAKE, this.httpOptions);
  }
  public getVehicleYear(make:any):Observable<any>
  {
    var yearUrl=StaticService.VEHICLE_YEAR.replace(':make',make);
    return this.http.get<any>(this.baseUrl + yearUrl, this.httpOptions);
  }
  public getVehicleModel(make:any,year:any):Observable<any>
  {
    var modelUrl=StaticService.VEHICLE_MODEL.replace(':make',make).replace(':year',year);
    return this.http.get<any>(this.baseUrl + modelUrl, this.httpOptions);
  }
  public getVehicleTrim(make:any,year:any,model:any):Observable<any>
  {
    var trimUrl=StaticService.VEHICLE_TRIM.replace(':make',make).replace(':year',year).replace(':model',model);
    return this.http.get<any>(this.baseUrl + trimUrl, this.httpOptions);
  }
  // VEHICEL ENDED

  public getAttributeBrand():Observable<any>
  {
    return this.http.get<any>(this.baseUrl + StaticService.ATTRIBUTE_BRAND, this.httpOptions);
  }

  public getAttributeCatelouge(parentUrl:any):Observable<any>
  {
    var catelougeUrl=StaticService.ATTRIBUTE_CATELOUGE.replace(':parentUrl',parentUrl);
    return this.http.get<any>(this.baseUrl + catelougeUrl, this.httpOptions);
  }
  public getCatalogueVariantListingByKeyAttributes(
    keyAttributes:any,
    BrandPermaLink:any,
    MinSalesPrice:any,
    MaxSalesPrice:any,
    CatagoryPermalink:any,
    NoneKeyAttribute1Value:any,
    NoneKeyAttribute2Value:any,
    NoneKeyAttribute3Value:any,
    pageNumber:any,
    pageSize:any)
    :Observable<any>
  {
    var variantListingKeyAttributes = StaticService.CATALOGUE_VARIANT_LISTING_KEYATTRIBUTES.replace(':keyattribute',keyAttributes);
    variantListingKeyAttributes= variantListingKeyAttributes+'?NoneKeyAttribute1='+environment.nonKeyType1
                                                            +'&NoneKeyAttribute2='+environment.nonKeyType2
                                                            +'&NoneKeyAttribute3='+environment.nonKeyType3

    if(BrandPermaLink)
    {
      variantListingKeyAttributes= variantListingKeyAttributes+'&BrandPermaLink='+BrandPermaLink
    }
    if(MinSalesPrice)
    {
      variantListingKeyAttributes= variantListingKeyAttributes+'&MinSalesPrice='+MinSalesPrice
    }
    if(MaxSalesPrice)
    {
      variantListingKeyAttributes= variantListingKeyAttributes+'&MaxSalesPrice='+MaxSalesPrice
    }
    if(CatagoryPermalink)
    {
      variantListingKeyAttributes= variantListingKeyAttributes+'&CatagoryPermalink='+CatagoryPermalink
    }
    if(NoneKeyAttribute1Value)
    {
      variantListingKeyAttributes= variantListingKeyAttributes+'&NoneKeyAttribute1Value='+NoneKeyAttribute1Value
    }
    if(NoneKeyAttribute2Value)
    {
      variantListingKeyAttributes= variantListingKeyAttributes+'&NoneKeyAttribute2Value='+NoneKeyAttribute2Value
    }
    if(NoneKeyAttribute3Value)
    {
      variantListingKeyAttributes= variantListingKeyAttributes+'&NoneKeyAttribute3Value='+NoneKeyAttribute3Value
    }
    

    return this.http.get<any>(this.baseUrl + variantListingKeyAttributes+'&page='+pageNumber+'&PageSize='+pageSize, this.httpOptions);

  }
  public getCatalogueByBrandProduct(brand:any,product:any):Observable<any>
  {
    var catalogtirelisting=StaticService.CATALOGUE_DETAIL_BRANDPRODUCTURL.replace(':brand',brand)
    catalogtirelisting = catalogtirelisting.replace(':producturl',product)
    return this.http.get<any>(this.baseUrl + catalogtirelisting, this.httpOptions);

  }
  public getCatalogueByVehicle
  (
    year:any,
    make:any,
    model:any,
    trim:any,
    BrandPermaLink:any,
    MinSalesPrice:any,
    MaxSalesPrice:any,
    CatagoryPermalink:any,
    NoneKeyAttribute1Value:any,
    NoneKeyAttribute2Value:any,
    NoneKeyAttribute3Value:any,
    pageNumber:any,
    pageSize:any,
    ):Observable<any>
  {
    var catalogtirelisting=StaticService.CATALOGUE_DETAIL_BYVEHICLE.replace(':year',year);
    catalogtirelisting = catalogtirelisting.replace(':make',make)
    catalogtirelisting = catalogtirelisting.replace(':model',model)
    catalogtirelisting = catalogtirelisting.replace(':trim',trim)
    catalogtirelisting= catalogtirelisting+'?NoneKeyAttribute1='+environment.nonKeyType1
                                                            +'&NoneKeyAttribute2='+environment.nonKeyType2
                                                            +'&NoneKeyAttribute3='+environment.nonKeyType3
    if(BrandPermaLink)
    {
      catalogtirelisting= catalogtirelisting+'&BrandPermaLink='+BrandPermaLink
    }
    if(MinSalesPrice)
    {
      catalogtirelisting= catalogtirelisting+'&MinSalesPrice='+MinSalesPrice
    }
    if(MaxSalesPrice)
    {
      catalogtirelisting= catalogtirelisting+'&MaxSalesPrice='+MaxSalesPrice
    }
    if(CatagoryPermalink)
    {
      catalogtirelisting= catalogtirelisting+'&CatagoryPermalink='+CatagoryPermalink
    }
    if(NoneKeyAttribute1Value)
    {
      catalogtirelisting= catalogtirelisting+'&NoneKeyAttribute1Value='+NoneKeyAttribute1Value
    }
    if(NoneKeyAttribute2Value)
    {
      catalogtirelisting= catalogtirelisting+'&NoneKeyAttribute2Value='+NoneKeyAttribute2Value
    }
    if(NoneKeyAttribute3Value)
    {
      catalogtirelisting= catalogtirelisting+'&NoneKeyAttribute3Value='+NoneKeyAttribute3Value
    }
    if(pageNumber)
    {
      catalogtirelisting= catalogtirelisting+'&page='+pageNumber
    }
    
    return this.http.get<any>(this.baseUrl + catalogtirelisting+'&PageSize='+pageSize, this.httpOptions);
  }

  public CategoryByUrl(
    parmalink:any,
    pageSize: any,
    pageNo:any,
    BrandPermaLink:any,
    MinSalesPrice:any,
    MaxSalesPrice:any,
    CatagoryPermalink:any,
    NoneKeyAttribute1Value:any,
    NoneKeyAttribute2Value:any,
    NoneKeyAttribute3Value:any,
    ): Observable<any> {
    var categoryUrl=StaticService.CATEGORIES_URL+parmalink;
    if(BrandPermaLink)
    {
      categoryUrl= categoryUrl+'&BrandPermaLink='+BrandPermaLink
    }
    if(MinSalesPrice)
    {
      categoryUrl= categoryUrl+'&MinSalesPrice='+MinSalesPrice
    }
    if(MaxSalesPrice)
    {
      categoryUrl= categoryUrl+'&MaxSalesPrice='+MaxSalesPrice
    }
    if(CatagoryPermalink)
    {
      categoryUrl= categoryUrl+'&CatagoryPermalink='+CatagoryPermalink
    }
    if(NoneKeyAttribute1Value)
    {
      categoryUrl= categoryUrl+'&NoneKeyAttribute1Value='+NoneKeyAttribute1Value
    }
    if(NoneKeyAttribute2Value)
    {
      categoryUrl= categoryUrl+'&NoneKeyAttribute2Value='+NoneKeyAttribute2Value
    }
    if(NoneKeyAttribute3Value)
    {
      categoryUrl= categoryUrl+'&NoneKeyAttribute3Value='+NoneKeyAttribute3Value
    }
    return this.http.get<any>(this.baseUrl + categoryUrl+'?pageSize='+pageSize+'&pageNo='+pageNo, this.httpOptions);
  }

}
