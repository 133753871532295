import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../app/core/enviroment/environment';
import { StaticService } from '../static/static.service';


@Injectable({
  providedIn: 'root'
})
export class interceptorService {
    private baseUrl=environment.apiUrl;
    private callSent: boolean = false;

  constructor(private http: HttpClient) {
    this.init();
   }public init(): void {
    
  }
  getCallSentStatus(): boolean {
    return this.callSent;
  }
  setCallSentStatus(value: boolean): void {
    this.callSent = value;
  }
  public refreshAuth(body:any): Observable<any>{
    return this.http.post<any>(this.baseUrl + StaticService.AUTH_REFRESHAUTHTOKEN, body);
  }
  public refreshB2BAuth(body:any): Observable<any>{
    return this.http.post<any>(this.baseUrl + StaticService.B2BAUTH_REFRESHAUTHTOKEN, body);
  }

}

