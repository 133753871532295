<!-- Cart Modal -->
<div class="tyre-cart-modal">
    <div
      class="modal"
      tabindex="-1"
      id="modal_cart"
      aria-label="modal_cart"
      role="dialog"
      [ngStyle]="{ display: 'block' }"
      aria-hidden="false"
      aria-modal="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-body">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="cartModal">Updating Cart!</h5>
              <a
                class="btn close"
                data-dismiss="modal"
                [mat-dialog-close]="true"
                aria-hidden="true"
                >&times;</a
              >
            </div>
            <div class="modal-body">
              <h5 class="topmargin-sm" style="color: #555">
                Your cart updated successfully.
              </h5>
            </div>
            <div class="modal-footer">
              <div
                class="generic-btn cart-btn d-inline-block aos-init aos-animate"
                [mat-dialog-close]="true"
                data-aos-duration="600"
              >
                <a class="btn"> Continue Shopping</a>
              </div>
              <div
                class="generic-btn cart-btn d-inline-block aos-init aos-animate"
                [mat-dialog-close]="true"
                data-aos-duration="600"
              >
                <a href="wholesale/cart" class="btn">Go To Cart</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  