import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../app/core/enviroment/environment';
import { StaticService } from '../static/static.service';

@Injectable({
  providedIn: 'root'
})
export class B2bService {
  private httpOptions: any;
  headers: any;
  private baseUrl = '';
  isServer: any;
  isBrowser: any;
  currentUser: any
  constructor(private http: HttpClient, @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.isServer = isPlatformServer(platformId);

    this.init();
    this.baseUrl = environment.apiUrl;
  }

  public init(): void {
    if (this.isBrowser) {
      // this.currentUser = localStorage.getItem('token');
      // this.currentUser = JSON.parse(this.currentUser);
      this.headers = new Headers(
        {
          'Content-Type': 'application/json',
        });
    }


    if (this.isBrowser) {
      this.httpOptions = {
        headers: this.headers, observe: 'response',
      };
    }


  }
  public GetVariantListing(customerId: any, key1: any, key2: any, key3: any, brand: any, search: any, pageNo: any): Observable<any> {
    var getVariantListing = StaticService.B2B_GETVARAINTLISTING.replace('{1}', customerId)
    if (key1 != null) {
      getVariantListing = getVariantListing + '?key1=' + key1
    }
    if (key2 != null) {
      getVariantListing = getVariantListing + '&key2=' + key2
    }
    if (key3 != null) {
      getVariantListing = getVariantListing + '&key3=' + key3
    }
    if (brand != null && key1 == null) {
      getVariantListing = getVariantListing + '?brandUrl=' + brand
    }
    if (brand != null && key1 != null) {
      getVariantListing = getVariantListing + '&brandUrl=' + brand
    }
    if (key1 == null && brand == null && search != null && search != '') {
      getVariantListing = getVariantListing + '?search=' + search

    }

    if (search != null && search != '') {
      getVariantListing = getVariantListing + '&search=' + search
    }
    if (key1 == null && brand == null && !(search != null && search != '')) {
      getVariantListing = getVariantListing + '?page=' + pageNo + '&PageSize=' + environment.pageSize
    }
    else {
      getVariantListing = getVariantListing + '&page=' + pageNo + '&PageSize=' + environment.pageSize

    }
    return this.http.get<any>(this.baseUrl + getVariantListing, this.httpOptions);
  }

  public GetShoppingCart(customerId: any) {
    var getShoppingCart = StaticService.B2B_GETSHOPPINGCART.replace('{1}', customerId)
    return this.http.get<any>(this.baseUrl + getShoppingCart, this.httpOptions);
  }
  public GetVariantListingBYCategory(customerId: any,  category: any, pageNo: any): Observable<any> {
    var getVariantListing = StaticService.B2B_GETVARAINTLISTINGBYCATEGORY.replace('{1}', customerId)
   
      getVariantListing = getVariantListing + '?category='+category+'&page=' + pageNo + '&PageSize=' + environment.pageSize

   
    return this.http.get<any>(this.baseUrl + getVariantListing, this.httpOptions);
  }


  public UpdateShoppingCart(customerId: any, cartItem: any) {
    var updateShoppingCart = StaticService.B2B_UPDATESHOPPINGCART.replace('{1}', customerId)
    return this.http.post<any>(this.baseUrl + updateShoppingCart, cartItem, this.httpOptions);
  }
  public getb2bOrderDetail(orderid :any): Observable<any> {
    return this.http.get<any>(this.baseUrl + StaticService.B2B_ORDER_SUMMARY+'/'+orderid+'/shippablesummary' , this.httpOptions);
  }
  public EmptyShoppingCart(customerId: any) {
    var emptyShoppingCart = StaticService.B2B_EMPTYSHOPPINGCART.replace('{1}', customerId)
    return this.http.get<any>(this.baseUrl + emptyShoppingCart, this.httpOptions);
  }
  public orderCheckout(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + StaticService.B2B_GETCREATEORDER, body, this.httpOptions);
  }
  public disposableOrderCheckout(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + StaticService.B2B_CREATEDISPOSABLEORDER, body, this.httpOptions);
  }
  public b2bOrderListing(
    userId: any,
    type: any,
    status:any,
    startDate:any,
    endDate:any,
    currentDate:any,
    orderNumber:any,
    paymentStatus:any,
    criteria:any,
    searchFilter:any,
    page: any,
    size: any): Observable<any> {
      var apiUrl = StaticService.B2B_ORDER_LISTING.replace(':custId', userId);
      let param = new HttpParams;
      if (status) {
        param = param.set('status', status);
      }
      if(criteria)
      {
        param=param.set('searchCriteria',criteria)
      }
      if (startDate) {
        param = param.set('startDate', startDate);
      }
      if (endDate) {
        param = param.set('endDate', endDate);
      }
      if (orderNumber) {
        param = param.set('orderNumber', orderNumber);
      }
      if (paymentStatus) {
        param = param.set('paymentStatus', paymentStatus);
      }
      if (currentDate) {
        param = param.set('currentDate', currentDate);
      }
      if (searchFilter) {
        param = param.set('searchFilter', searchFilter);
      }
      return this.http.get<any>(this.baseUrl + apiUrl + '?type=' + type + '&page=' + page + '&size=' + size, { params: param });
  }
  public cardDetail(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + StaticService.ACH_B2B, body, this.httpOptions);
  }
  public getACH(userId:any){
    var getach=StaticService.B2B_GETBANKING.replace(':customerId',userId);
    return this.http.get<any>(this.baseUrl+ getach ,this.httpOptions);
  }
}
