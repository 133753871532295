import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {BehaviorSubject, Observable, count } from 'rxjs'
import { environment } from '../../app/core/enviroment/environment';
import { StaticService } from '../static/static.service';

@Injectable({
  providedIn: 'root'
})
export class ShopService {

  private httpOptions: any;
  headers: any;
  private baseUrl = '';
  private items: any[] = [];

  private products$ = new BehaviorSubject<any>([]);
  productsList$ = this.products$.asObservable();

  private b2bproducts$ = new BehaviorSubject<any>([]);
  b2bproductsList$ = this.b2bproducts$.asObservable();

  private total$ = new BehaviorSubject<any>([]);
  totalAmount$ = this.total$.asObservable();
  
  private b2btotal$ = new BehaviorSubject<any>([]);
  b2btotalAmount$ = this.b2btotal$.asObservable();

  constructor(private http: HttpClient) {
    this.init();
    this.baseUrl = environment.apiUrl;
   }

   public init(): void {

    this.headers = new HttpHeaders({ 'Content-Type': 'application/json' })

    this.httpOptions = {
      headers: this.headers, observe: 'response'
    };
  }
  updateCartQuantity(cartList:any){
    let totalAmount: any = 0;

    cartList.forEach((product: any,index:number) => {
      totalAmount += (product.catalogue.pricing.salesPrice * product.quantity);
      this.total$.next(totalAmount);

    })
  }
  setProducts(cartList: any) {
    this.products$.next(cartList);

    let totalAmount: any = 0;

    cartList.forEach((product: any,index:number) => {
      // console.log(product.catalogue.costPerItem + " " + product.quantity)
      if(product.quantity==0)
      {
        cartList.splice(index,1)
      }
      totalAmount += product.quantity* (product.variant.pricing.discountPrice>0?product.variant.pricing.discountPrice:product.variant.pricing.salePrice);
    })
    // console.log(totalAmount);
    this.total$.next(totalAmount);
  }
  setCartProductsList(cartList: any) {
    this.products$.next(cartList);

    let totalAmount: any = 0;

    cartList.forEach((product: any,index:number) => {

      if(product.quantity==0)
      {
        cartList.splice(index,1)  
      }
      totalAmount += product.quantity* (product.catalogue.pricing.discountPrice>0?product.catalogue.pricing.discountPrice:product.catalogue.pricing.salePrice);
    })
    console.log(totalAmount)
    this.total$.next(totalAmount);
  
  }
  setb2bCartProductsList(cartList: any) {
    this.b2bproducts$.next(cartList);

    let totalAmount: any = 0;

    cartList.forEach((product: any,index:number) => {
      // console.log(product.catalogue.costPerItem + " " + product.quantity)
      if(product.quantity==0)
      {
        cartList.splice(index,1)
      }
      totalAmount += product.quantity* (product.catalogue.pricing.discountPrice>0?product.catalogue.pricing.discountPrice:product.catalogue.pricing.salePrice);
    })
    this.b2btotal$.next(totalAmount);
  
  }
  public GETNEW_SESSIONSTRIPE(body:any): Observable<any>{
    return this.http.post<any>(this.baseUrl + StaticService.GETNEW_SESSIONSTRIPE, body, this.httpOptions);
  }
  public getProductsList(Type?:any,count?:any): Observable<any> {
    if(Type && count )
    {
        return this.http.get<any>(this.baseUrl + StaticService.AUTH_GETALLPRODUCTURL +'?type='+Type +'&count='+count, this.httpOptions);
    }
    else if(Type)
    {
      return this.http.get<any>(this.baseUrl + StaticService.AUTH_GETALLPRODUCTURL +'?type='+Type , this.httpOptions);

    }
    else if(count)
    {
      return this.http.get<any>(this.baseUrl + StaticService.AUTH_GETALLPRODUCTURL +'?count='+count, this.httpOptions);

    }
    else
      {
        return this.http.get<any>(this.baseUrl + StaticService.AUTH_GETALLPRODUCTURL , this.httpOptions);

      }
  }

  public getProductDetailByid(id:any):Observable<any>
  {
    return this.http.get<any>(this.baseUrl + StaticService.AUTH_GETPRODUCTDETAILBYIDURL + id , this.httpOptions);
  }
  
  public getProductDetailBypermaLink(permaLink:any):Observable<any>
  {
    return this.http.get<any>(this.baseUrl + StaticService.AUTH_GETPRODUCTDETAILBYPERMALINK + permaLink , this.httpOptions);
  }

  public updateCart(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + StaticService.AUTH_TYREUPDATECARTURL, body, this.httpOptions);
  }

  public getCartByCustomerIdOrGuestId(userId:any,guestId:any): Observable<any> {
    if(userId!=undefined)
    {
      return this.http.get<any>(this.baseUrl + StaticService.AUTH_GETCARTBYCUSTOMERIDORGUESTIDURL  + 'guest/' + guestId +'/customer/'+ userId , this.httpOptions);
    }
    else{
      return this.http.get<any>(this.baseUrl + StaticService.AUTH_GETCARTBYCUSTOMERIDORGUESTIDURL + 'guest/' + guestId  , this.httpOptions)
    }
  }
  public getCartByCustomerIdOrGuestIdv1(userId:any,guestId:any): Observable<any> {
    if(userId!=undefined)
    {
      return this.http.get<any>(this.baseUrl + StaticService.AUTH_TYREGETCARTBYCUSTOMERIDORGUESTIDURL  + 'guest/' + guestId +'/customer/'+ userId+'/v1' , this.httpOptions);
    }
    else{
      return this.http.get<any>(this.baseUrl + StaticService.AUTH_TYREGETCARTBYCUSTOMERIDORGUESTIDURL + 'guest/' + guestId +'/v1' , this.httpOptions)
    }
  }
  public ClearCart(userId:any,guestId:any): Observable<any> {
    if(userId!=undefined)
    {
      return this.http.get<any>(this.baseUrl + StaticService.AUTH_CLEARCARTITEM  + '/guest/' + guestId +'/customer/'+ userId , this.httpOptions);
    }
    else{
      return this.http.get<any>(this.baseUrl + StaticService.AUTH_CLEARCARTITEM + '/guest/' + guestId  , this.httpOptions)
    }
  }
  
  public getCartByCustomerId(userId:any): Observable<any> {

    return this.http.get<any>(this.baseUrl + StaticService.AUTH_GETCARTBYCUSTOMERIDURL +'?CustomerId='+ userId , this.httpOptions);
  }
  public getLookup(userId:any): Observable<any> {

    return this.http.get<any>(this.baseUrl + StaticService.AUTH_GETLOOKUP , this.httpOptions);
  }

  public getAllAddress(userId:any): Observable<any> {
    return this.http.get<any>(this.baseUrl + StaticService.AUTH_GETALLADDRESSURL + userId , this.httpOptions);
  }

  public orderCheckout(body:any): Observable<any> {

      return this.http.post<any>(this.baseUrl + StaticService.AUTH_ORDERCHECKOUTURL, body, this.httpOptions);
   
  }
  public GuestorderCheckout(body:any): Observable<any> {

    return this.http.post<any>(this.baseUrl + StaticService.AUTH_GUESTORDERCHECKOUTURL, body, this.httpOptions);
 
  }
  public guestRating(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + StaticService.GUEST_RATING, body, this.httpOptions);
  }
  public customerRating(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + StaticService.CUSTOMER_RATING, body, this.httpOptions);
  }
 
  // public addAddress(body: any): Observable<any> {
  //   return this.http.post<any>(this.baseUrl + StaticService.AUTH_ADDADDRESSURL, body, this.httpOptions);
  // }
    

  public getAvaliablePaymentMethod(userId :any): Observable<any> {
    return this.http.get<any>(this.baseUrl + StaticService.AUTH_AVALIABLEPAYMENTMETHODURL +'/'+userId , this.httpOptions);
  }
  public getSiteAvaliablePaymentMethod(): Observable<any> {
    return this.http.get<any>(this.baseUrl + StaticService.AUTH_AVALIABLESITEPAYMENTMETHODURL, this.httpOptions);
  }
  public getOrderDetail(orderid :any,customerId:any): Observable<any> {
    return this.http.get<any>(this.baseUrl + StaticService.AUTH_ORDERSUMMARY+'/'+orderid+'/' +customerId+'/summary' , this.httpOptions);
  }
  public getShipableOrderDetail(orderid :any): Observable<any> {
    return this.http.get<any>(this.baseUrl + StaticService.AUTH_ORDERSUMMARY+'/'+orderid+'/shippablesummary' , this.httpOptions);
  }
}
