import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { DatePipe, DecimalPipe, NgFor, NgIf, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { UtcToLocalPipe } from '../../core/pipes/utctolocalPipe';
import { InvoiceService } from '../../../services/invoice/invoice.service';
import { SharedService } from '../../../services/shared/shared.service';
@Component({
  selector: 'app-invoice',
  standalone: true,
  providers:
    [
      DatePipe
    ],
  imports:
    [
      NgFor,
      NgIf,
      UtcToLocalPipe,
      DecimalPipe
    ],
  templateUrl: './invoice.component.html',
  styleUrl: './invoice.component.css'
})
export class InvoiceComponent implements OnInit {
  userDetail: any;
  isBrowser: any;
  isServer: any;
  pageNo: number = 1;
  pageSize: number = 20;
  invoiceData:any;
  total: any;
  currentPageIndex: any;
  totalPages: number = 0;
  page: number = 1;
  constructor(private invoiceService:InvoiceService,private sharedService:SharedService,@Inject(PLATFORM_ID) platformId: Object){
    this.isBrowser = isPlatformBrowser(platformId);
    this.isServer = isPlatformServer(platformId);
  }
  ngOnInit(): void {
    this.sharedService.setShowLoading(true);
    if (this.isBrowser) {
      this.userDetail = localStorage.getItem('user');
      this.userDetail = JSON.parse(this.userDetail);
      this.getInvoice()
    }
  }
  getInvoice() {
    this.sharedService.setShowLoading(true);
    this.invoiceService.InvoiceListing(this.userDetail.userId, this.pageNo, this.pageSize).subscribe({
      next: (res: any) => {
        console.log(res);
        this.invoiceData = res.body.response.data;
        this.total = res.body.response.total;
        var pageNumber = this.total % res.body.response.size;
        if (pageNumber == 0) {
          this.totalPages = Math.floor(this.total / res.body.response.size);
        } else {
          this.totalPages =
            Math.floor(this.total / res.body.response.size) + 1;
        }
        this.sharedService.setShowLoading(false);
      }
    })
  }
  pagesToShow(): number[] {
    const start = this.currentPageIndex * 10;
    const end = Math.min(start + 10, this.totalPages);
    return Array.from({ length: end - start }, (_, i) => start + i + 1);
  }
  onPageChange(pageNumber: any) {
    if (pageNumber != this.page) {
      this.page = 1;
      this.page = pageNumber;
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      this.getInvoice();
    }
  }
  onPreviousPageSet() {
    if (this.currentPageIndex > 0) {
      this.currentPageIndex--;
      this.page = this.currentPageIndex * 10 + 1;
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      this.getInvoice();
    }
  }
  onNextPageSet() {
    if (this.currentPageIndex < Math.floor(this.totalPages / 10)) {
      this.currentPageIndex++;
      this.page = this.currentPageIndex * 10 + 1;
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      this.getInvoice();
    }
  }
}
