<section
  class="w-100 sec-top-header breadcrumb-section"
  style="background-color: #dcdcdc"
>
  <div class="wrapper4 d-flex justify-content-between tyre-ryder-header">
    <div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb p-0 m-0 breadcrumb-bg-color">
          <li class="breadcrumb-item breadcrumb-headsize">
            <a class="primary-color" href="/disposable/orders">Disposal</a>
          </li>
         
          <li
            class="breadcrumb-item breadcrumb-headsize active"
            aria-current="page"
          >
            Tires
          </li>
        </ol>
      </nav>
    </div>
    <div>
      <nav class="navbar navbar-expand-lg navbar-light p-0">
        <div class="">
          <div class="d-inline-block">
            <a
              id="cartDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              aria-disabled="cartList?.quantity === 0"
            >
              <img
                src="assets/images/tyreryder_images/shopping-cart-icon.png"
                class="img-fluid"
                alt="Your Image"
                style="width: 30px; height: 30px"
              />
              <!-- <span class="badge badge-primary">0</span> -->
              <span class="badge badge-primary" style="vertical-align: top">{{
                totalQuantity
              }}</span>
            </a>
            <!-- <div class="dropdown-cart" aria-labelledby="navbarDropdown" [ngStyle]="showCar?{} : {'display': 'none'}"> -->
            <div
              class="dropdown-menu dropdown-cart p-0"
              aria-labelledby="cartDropdown"
            >
              <div class="title">
                <h5>Shopping Cart</h5>
              </div>

              <div>
                <div *ngFor="let item of cartItems">
                  <div class="dropdown-cart-item" style="line-height: 20px">
                    <div class="cart-items clearfix">
                      <div class="cart-item-image">
                        <figure class="mb-0">
                          <img
                            *ngIf="
                              item?.catalogue?.image == null ||
                              item?.catalogue?.image == ''
                            "
                            src="/assets/images/tyreryder_images/img_not_available.jpg"
                            class="img-fluid"
                            alt="{{ item?.catalogue?.description }}"
                          />

                          <img
                            *ngIf="
                              item?.catalogue?.image != null &&
                              item?.catalogue?.image != ''
                            "
                            src="{{
                              item?.catalogue?.image.replace('lg-', 'sm-')
                            }}"
                            class="img-fluid"
                            alt="{{ item?.catalogue?.description }}"
                          />
                        </figure>
                      </div>
                      <div>
                        <div class="cart-item-des">
                          <span
                            class="cart-item-price dropdown-price-value"
                            style="color: #212529"
                            >{{ item?.variant?.catalogueName }}
                          </span>

                          <div class="item-price">
                            <div class="flex-grow-1">
                              <span
                                class="cart-item-price dropdown-price-value"
                                style="font-weight: 700"
                              >
                                {{
                                  item?.variant?.salePrice + item?.variant?.tax
                                    | number : "1.2-2"
                                }}
                              </span>
                              <span
                                class="cart-item-price dropdown-price-value"
                                style="font-weight: 700"
                                >X
                                {{ item.quantity }}
                              </span>
                            </div>
                            <div class="">
                              <span class="cart-item-quantity color-333">
                                ${{
                                  item?.quantity *
                                    (item?.variant?.salePrice +
                                      (item?.variant?.tax > 0
                                        ? item?.variant?.tax
                                        : 0)) | number : "1.2-2"
                                }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cart-action clearfix t-generic-btn">
                <div class="">
                  <button class="button_border_none">
                    <a href="/disposable/cart">View Cart</a>
                  </button>
                  <span class="fright checkout-price">${{ totalAmount }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</section>

<section class="w-100 mt-20px padding-bottom d-none d-md-block d-lg-block">
  <div class="wrapper4 wholesale-listing">
    <div class="table-responsive">
      <table class="table table-hover">
        <thead class="text-center">
          <tr>
            <th scope="col" class="align-middle">Tire</th>
            <th
              scope="col"
              class="align-middle d-flex justify-content-between"
              (click)="
                sortColumn = 'salePrice';
                sortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
                sort()
              "
            >
              <span>Price </span>
            </th>
            <th scope="col" class="align-middle">FET</th>

            <th scope="col" class="align-middle">Qty</th>
            <th scope="col" class="align-middle"></th>
            <th scope="col" class="align-middle"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of variantList; let i = index">
            <td>
              <div class="item-img d-inline-block">
                <img
                  *ngIf="item?.imageUrl == null || item?.imageUrl == ''"
                  src="assets/images/tyreryder_images/img_not_available.jpg"
                  class="img-fluid"
                  alt="{{ item?.catalogueName }}"
                />

                <img
                  *ngIf="item?.imageUrl != null && item?.imageUrl != ''"
                  src="{{ item?.imageUrl.replace('lg-', 'sm-') }}"
                  class="img-fluid"
                  alt="{{ item?.catalogueName }}"
                />
              </div>
              <div class="d-inline-block ml-3">
                {{ item.catalogueName }}
              </div>
            </td>
            <td class="align-middle primary-color font-weight-bold text-center">
              ${{ item.salePrice | number : "1.2-2" }}
            </td>
            <td class="align-middle font-weight-bold text-center">
              ${{ item.tax | number : "1.2-2" }}
            </td>

            <td class="align-middle text-center">
              <div class="input-width d-inline-block">
                <input
                  class="form-control input-sm"
                  minlength="1"
                  ng-minlength="1"
                  [formControl]="item.cartItemQuantity"
                  type="number"
                  min="1"
                  (keyup.enter)="AddtoCart(item)"
                />
              </div>
            </td>
            <td class="align-middle text-center">
              <div
                class="d-inline-block"
                style="cursor: pointer; vertical-align: middle !important"
              >
                <a
                  class="cursor-affect"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Add to cart"
                  (click)="AddtoCart(item)"
                >
                  <img
                    src="assets/images/tyreryder_images/shopping-cart-icon.png"
                    class="img-fluid"
                    alt="Your Image"
                    style="width: 28px; height: 28px"
                  />
                  <!-- <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        class="bi bi-bag primary-color"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z"
                        />
                      </svg> -->
                </a>
              </div>
            </td>
            <td class="align-middle text-center">
              <i
                class="fas fa-regular fa-check"
                *ngIf="isInCart(item.variantId)"
                style="color: #14a44d"
              ></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</section>

<!-- code for mobile only -->
<section class="w-100 padding-top padding-bottom d-sm-block d-md-none">
  <div class="wrapper4 wholesale-card-sm">
    <div
      class="card box-shadow mb-2"
      *ngFor="let item of variantList; let i = index"
    >
      <div class="card-body">
        <div class="d-flex">
          <div class="item-img-sm">
            <img
              src="assets/images/tierryder-listingimage.png"
              alt="image not shown"
            />
          </div>
          <div>
            <span class="item-name-heading"> {{ item.partNo }} </span>
            <p class="mb-2 item-description">
              {{ item.additionalDescription }}
            </p>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-6">
            <span class="mr-2 label-heading">Price:</span>
            <span class="pricing-heading primary-color"
              >${{ item.salePrice | number : "1.2-2" }}</span
            >
          </div>
          <div class="col-6">
            <span class="mr-2 label-heading">Avail:</span>
            <span class="pricing-heading">{{ item.availableStock }}</span>
          </div>
        </div>
        <div class="row">
          <div class="Fet-price col-6">
            <span class="mr-2 label-heading">FET:</span>
            <span class="pricing-heading primary-color"
              >${{ item.tax | number : "1.2-2" }}</span
            >
          </div>
          <div class="col-6">
            <div class="d-flex">
              <span class="mr-2 label-heading">Buy:</span>
              <span class="input-width-sm">
                <input
                  class="form-control input-sm"
                  minlength="1"
                  ng-minlength="1"
                  [formControl]="item.cartItemQuantity"
                  type="number"
                  min="1"
                />
              </span>
            </div>
          </div>
        </div>
        <div class="row cart-img mt-1">
          <div class="col-6"></div>
          <div class="col-6" style="text-align: left">
            <a
              (click)="AddtoCart(item)"
              *ngIf="item.availableStock > 0"
              class="add-cart-heading color-B31B1B mr-3"
              >Add to Cart</a
            >
            <i
              class="fas fa-regular fa-check"
              *ngIf="isInCart(item.variantId)"
              style="color: #14a44d"
            ></i>
            <!-- <img src="../assets/images/lock-ico (click)="AddtoCart(item)"n.png" alt="lock-icon" /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="w-100 bg-F5F6F9 float-left">
  <div class="wrapper4">
    <div
      class="d-flex align-items-baseline pagination-block pb-3 justify-content-between flex-wrap"
    >
      <div>
        Showing Page <span class="base-color h6">{{ page }}</span> of
        <span class="base-color h6">{{ totalPages }}</span>
      </div>
      <div class="pagination p1">
        <ul>
          <button
            class="button_border_none cursors"
            (click)="onPreviousPageSet()"
            [disabled]="currentPageIndex === 0"
          >
            <li class="arrows-color">Previous</li>
          </button>

          <ng-container *ngFor="let pageNumber of pagesToShow()">
            <a
              [class.is-active]="page === pageNumber"
              (click)="onPageChange(pageNumber)"
              class="cursors"
            >
              <li>{{ pageNumber }}</li>
            </a>
          </ng-container>

          <button
            class="button_border_none cursors"
            (click)="onNextPageSet()"
            [disabled]="currentPageIndex === totalPages"
          >
            <li class="arrows-color">Next</li>
          </button>
        </ul>
      </div>
      <div>
        Total Data<span class="base-color h6"> ({{ total }})</span>
      </div>
    </div>
  </div>
</section>
