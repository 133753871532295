import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { error } from 'console';
import { catchError, throwError } from 'rxjs';
import { AuthService } from '../../../../services/auth.service';
import { SharedService } from '../../../../services/shared/shared.service';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  var authService = inject(AuthService)
  var service = inject(SharedService)

  return next(req).pipe(
    catchError((error) => {
      if (error.status == 401 || error.status == 403) {
        return next(
          req.clone({
            headers: req.headers.set(
              'Authorization',
              `Bearer ${service._gettoken()?.token}`
            ),
          })
        );
      }
      return throwError(() => error)
    })
  );
};
