import { NgFor, NgIf, DecimalPipe, DatePipe, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { RouterLink } from '@angular/router';
import { UtcToLocalPipe } from '../../core/pipes/utctolocalPipe';
import { B2bService } from '../../../services/b2b/b2b.service';
import { SharedService } from '../../../services/shared/shared.service';

@Component({
  selector: 'app-disposable-order-listing',
  standalone: true,
  imports: [ NgFor,
    NgIf,
    UtcToLocalPipe,
    DecimalPipe,
    RouterLink,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatNativeDateModule],
  templateUrl: './disposable-order-listing.component.html',
  styleUrl: './disposable-order-listing.component.css',
  providers: [DatePipe],

})
export class DisposableOrderListingComponent {
ordersList: any[] = [];
  orders: any[] = [];
  userDetail: any;
  shippableOrderId: any;
  shippableOrdersDetail: any;
  shippableOrdersubtotal: any = 0;
  pageNum: number = 1;
  total: any;
  totalPages: number = 0;
  pageSize: number = 20;
  isBrowser: any;
  isServer: any;
  perma:any[]=[];
  page: number = 1;
  currentPageIndex: number = 0;
  status: any;
  StatusArray:any=new Array();
  startDate: any;
  endDate: any;
  startingDate: any;
  endingDate: any;
  nowDate: any;
  currentDate: any;
  orderNumber: any;
  paymentStatus: any;
  paymentStatusArray:any=new Array();
  criteria: any=1;
  searchFilter: any;
  item: any;
  criteriaControl = new FormControl(1)
  orderControl = new FormControl(null);
  itemControl = new FormControl(null);
  statusControl = new FormControl(null);
  paymentControl = new FormControl(null);
  currentControl = new FormControl(null);
  DateControl = new FormControl(null);
  poNum: any;
  orderTrue: boolean = false;
  datePicker = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  constructor(
    private sharedservice: SharedService,
    private b2bService: B2bService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.isServer = isPlatformServer(platformId);
  }
  ngOnInit(): void {
    this.sharedservice.setShowLoading(true);
    if (this.isBrowser) {
      this.userDetail = localStorage.getItem('user');
      this.userDetail = JSON.parse(this.userDetail);
      this.getB2BOrder()
    }
  }
  getB2BOrder() {
    if (this.isBrowser) {
      this.sharedservice.setShowLoading(true);
      this.b2bService.b2bOrderListing(this.userDetail.userId, 4, this.status ,this.startDate,this.endDate,this.currentDate, this.orderNumber,this.paymentStatus,this.criteria,this.item,this.page, this.pageSize).subscribe(
        {
          next: (res: any) => {
            console.log(res)
            this.sharedservice.setShowLoading(false);
            this.ordersList = res.response.data;
            this.total = res.response.total;
            var pageNumber = this.total % res.response.size;
            if (pageNumber == 0) {
              this.totalPages = Math.floor(this.total / res.response.size);
            } else {
              this.totalPages =
                Math.floor(this.total / res.response.size) + 1;
            }
          },
          error: (error: any) => {
            this.sharedservice.setShowLoading(false);
          }
        });
    }
  }
  getOrderFilter() {
    if (this.isBrowser) {
      this.sharedservice.setShowLoading(true);
      this.orderNumber=this.orderControl.value;
      this.status=this.statusControl.value;
      this.paymentStatus=this.paymentControl.value;
      
      console.log('status Array', this.status)
      console.log('payment Array', this.paymentStatus)
      this.criteria=this.criteriaControl.value;
      this.item=this.itemControl.value;
      this.startingDate=this.startDate;
      this.endingDate=this.endDate;
      this.startingDate=this.startDate;
      this.nowDate=this.currentDate;
      this.b2bService.b2bOrderListing(this.userDetail.userId, 4, this.status ,this.startDate,this.endDate,this.currentDate, this.orderNumber,this.paymentStatus,this.criteria,this.item,this.page, this.pageSize).subscribe(
        {
          next: (res: any) => {
            console.log(res)
            this.sharedservice.setShowLoading(false);
            this.ordersList = res.response.data;
            this.total = res.response.total;
            var pageNumber = this.total % res.response.size;
            if (pageNumber == 0) {
              this.totalPages = Math.floor(this.total / res.response.size);
            } else {
              this.totalPages =
                Math.floor(this.total / res.response.size) + 1;
            }
            if (this.paymentStatus !== null && this.paymentStatus !== undefined && this.paymentStatus !== '') {
              this.startingDate = this.paymentStatus;
            }
          },
          error: (error: any) => {
            this.sharedservice.setShowLoading(false);
          }
        });
    }
  }
  clearFilter() {
    this.orderControl.setValue(null);
    this.itemControl.setValue(null);
    this.statusControl.setValue(null);
    this.paymentControl.setValue(null);
    this.DateControl.setValue(null);
    this.currentControl.setValue(null);
    this.datePicker.reset();
    this.currentControl.reset();
    this.orderNumber=null;
    this.status=null;
    this.startDate=null;
    this.endDate=null;
    this.currentDate=null;
    this.paymentStatus=null;
    this.nowDate=null;
    this.item=null;
    this.getB2BOrder();
  }
  pagesToShow(): number[] {
    const start = this.currentPageIndex * 10;
    const end = Math.min(start + 10, this.totalPages);
    return Array.from({ length: end - start }, (_, i) => start + i + 1);
  }
  onPageChange(pageNumber: any) {
    if (pageNumber != this.page) {
      this.page = 1;
      this.page = pageNumber;
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      this.getB2BOrder();
    }
  }
  onPreviousPageSet() {
    if (this.currentPageIndex > 0) {
      this.currentPageIndex--;
      this.page = this.currentPageIndex * 10 + 1;
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      this.getB2BOrder();
    }
  }
  onNextPageSet() {
    if (this.currentPageIndex < Math.floor(this.totalPages / 10)) {
      this.currentPageIndex++;
      this.page = this.currentPageIndex * 10 + 1;
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      this.getB2BOrder();
    }
  }
  onDateChange(start: HTMLInputElement, end: HTMLInputElement) {
    const sdatePipe = new DatePipe('en-US');
    const StartedDate = sdatePipe.transform(start.value, 'yyyy-MM-dd');
    const edatePipe = new DatePipe('en-US');
    const EndedDate = edatePipe.transform(end.value, 'yyyy-MM-dd');
    this.startDate = StartedDate;
    this.endDate = EndedDate;
  }
  EndDateChange(current:any){
    const currentdatePipe = new DatePipe('en-US');
    const currentDate = currentdatePipe.transform(current.value, 'yyyy-MM-dd');
    this.currentDate=currentDate;
    console.log(this.currentDate);
  }

}
