<section
  class="w-100 sec-top-header breadcrumb-section"
  style="background-color: #dcdcdc"
>
  <div class="wrapper4 d-flex tyre-ryder-header">
    <div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb p-0 m-0 breadcrumb-bg-color">
          <li class="breadcrumb-item breadcrumb-headsize">
            <a class="primary-color" href="/disposable/products">Disposable</a>
          </li>
          <li class="breadcrumb-item breadcrumb-headsize">
            <a class="primary-color" href="/disposable/orders">Orders</a>
          </li>

          <li
            class="breadcrumb-item breadcrumb-headsize active"
            aria-current="page"
          >
            {{ shippableOrdersDetail?.orderNumber }}
          </li>
        </ol>
      </nav>
    </div>
  </div>
</section>

<div class="w-100 wrapper4 logo-on-print">
  <div class="d-flex justify-content-center">
    <img src="assets/images/tyreryder_images/logo128x128.png" />
  </div>
</div>

<section class="w-100">
  <div class="wrapper4 mt-30px">
    <section class="">
      <div class="d-flex" style="gap: 20px">
        <div class="flex-grow-1">
          <div class="d-flex align-items-start mb-2">
            <a href="/orders" class="hidden-on-print">
              <!--begin::Svg Icon | path: icons/duotune/arrows/arr064.svg-->
              <span class="svg-icon svg-icon-2 text-gray-primary">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="23"
                  fill="currentColor"
                  class="bi bi-arrow-left-square"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm11.5 5.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                  ></path>
                </svg>
              </span>
              <!--end::Svg Icon-->
            </a>
            <!-- <div class="export-icon ">
                  <i class="fas fa-sharp fa-regular fa-file-export fa-2x"></i>
                </div> -->

            <div class="d-flex flex-column ml-2 mb-20px">
              <div class="font-weight-bold">
                #<span class="color">{{
                  shippableOrdersDetail?.orderNumber
                }}</span>
              </div>
              <div>
                <!-- Order Date: -->
                <small>
                  <b>
                    {{
                      shippableOrdersDetail?.orderDate
                        | utcToLocal : "dd-MMM-yyyy"
                    }}
                    at
                    {{
                      shippableOrdersDetail?.orderDate | utcToLocal : "h:mm a"
                    }}
                  </b>
                </small>
              </div>
            </div>
          </div>
        </div>

        <div class="hidden-on-print">
          <div class="print-button">
            <button
              class="btn btn-info btn-sm"
              type="button"
              data-toggle="modal"
              data-target="#cancelorder"
              *ngIf="shippableOrdersDetail?.statusId == 13"
            >
              Cancelled
            </button>
            <!-- <button
              class="btn btn-danger btn-sm"
              type="button"
              data-toggle="modal"
              data-target="#returnorder"
              *ngIf="
                shippableOrdersDetail?.statusId == 21 ||
                shippableOrdersDetail?.statusId == 19
              "
            >
              Order Return
            </button> -->
            <button
              class="btn btn-primary btn-sm"
              type="button"
              onclick="window.print()"
            >
              Print
            </button>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="w-100">
        <div class="wrapper4">
          <div class="row">
            <div class="col-12">
              <hr />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 p-0">
              <div
                class="card card-flush overflow-hidden"
                style="border-bottom: 0"
              >
                <div class="card-header pb-0">
                  <div class="card-title mb-0 pb-0">
                    <h5 class="base-color">Customer</h5>
                  </div>
                </div>
                <!--end::Card header-->
                <!--begin::Card body-->
                <div class="card-body pt-0 card-body1 pb-0 mb-3">
                  <div style="font-weight: bold">
                    <!-- Customer: -->
                    <i class="fas fa-regular fa-user mr-2"></i>
                    <span style="font-weight: 500"
                      >{{ shippableOrdersDetail?.customer?.firstName }}
                      {{ shippableOrdersDetail?.customer?.lastName }}</span
                    >
                  </div>

                  <div style="font-weight: bold">
                    <!-- <strong>Contact#:</strong> -->
                    <i class="fas fa-solid fa-phone mr-2"></i>
                    <span style="font-weight: 500">{{
                      shippableOrdersDetail?.customer?.mobile
                    }}</span>
                  </div>

                  <div style="font-weight: bold">
                    <i class="fas fa-solid fa-envelope mr-2"></i>
                    <span style="font-weight: 500">{{
                      shippableOrdersDetail?.customer?.email
                    }}</span>
                  </div>
                  <!--end::Card body-->
                </div>
                <!--end::Customer address-->
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 p-0">
              <div
                class="card card-flush overflow-hidden mb-20px"
                style="border-bottom: 0"
              >
                <div class="card-header pb-0">
                  <div class="card-title mb-0 pb-0">
                    <h5>Shipping Address</h5>
                  </div>
                </div>
                <!--end::Card header-->
                <!--begin::Card body-->
                <div
                  class="card-body pt-0 card-body1 pb-0"
                  style="font-weight: 500"
                >
                  {{ shippableOrdersDetail?.shippingAddress?.addressLine1 }},{{
                    shippableOrdersDetail?.shippingAddress?.addressLine2
                  }}
                  <span
                    *ngIf="shippableOrdersDetail?.shippingAddress?.addressLine2"
                    >,</span
                  ><br />
                  {{ shippableOrdersDetail?.shippingAddress?.city }},
                  {{ shippableOrdersDetail?.shippingAddress?.state }}
                  {{ shippableOrdersDetail?.shippingAddress?.zipCode }}<br />
                  {{ shippableOrdersDetail?.shippingAddress?.country }}
                </div>
                <!--end::Card body-->
              </div>
              <!--end::Customer address-->
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 p-0">
              <div
                class="card card-flush overflow-hidden mb-20px"
                *ngIf="shippableOrdersDetail?.billingAddress"
              >
                <div class="card-header pb-0">
                  <div class="card-title mb-0 pb-0">
                    <h5>Billing Address</h5>
                  </div>
                </div>
                <!--end::Card header-->
                <!--begin::Card body-->
                <div
                  class="card-body pt-0 card-body1 pb-0"
                  style="font-weight: 500"
                >
                  {{ shippableOrdersDetail?.billingAddress?.addressLine1 }},{{
                    shippableOrdersDetail?.billingAddress?.addressLine2
                  }}<span
                    *ngIf="shippableOrdersDetail?.billingAddress?.addressLine2"
                    >,</span
                  ><br />
                  {{ shippableOrdersDetail?.billingAddress?.city }},
                  {{ shippableOrdersDetail?.billingAddress?.state }}
                  {{ shippableOrdersDetail?.billingAddress?.zipCode }}<br />
                  {{ shippableOrdersDetail?.billingAddress?.country }}
                </div>
                <!--end::Card body-->
              </div>
              <!--end::Customer address-->
            </div>
            <div class="col-12">
              <hr />
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="w-100 mt-30px">
      <div class="wrapper4 w-sale">
        <!-- tabs -->
        <!-- Tabs navs -->
        <ul class="nav nav-tabs mb-3" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link active"
              data-toggle="tab"
              href="#main-grid"
              role="tab"
              aria-controls="main-grid"
              aria-selected="true"
              >Products</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              data-toggle="tab"
              href="#payment-grid"
              role="tab"
              aria-controls="payment-grid"
              aria-selected="false"
              >Payments</a
            >
          </li>

          <!-- <li class="nav-item">
            <a
              class="nav-link"
              data-toggle="tab"
              href="#return-grid"
              role="tab"
              aria-controls="return-grid"
              aria-selected="false"
              >Returns</a
            >
          </li> -->
        </ul>

        <!-- Tabs navs -->

        <!-- Tabs content -->
        <div class="tab-content">
          <div
            class="tab-pane active"
            id="main-grid"
            role="tabpanel"
            aria-labelledby="main-grid"
          >
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr class="align-middle">
                    <th class="align-middle" scope="col">Item</th>
                    <th class="align-middle" scope="col">QTY</th>
                    <th class="align-middle" scope="col">status</th>
                    <!-- <th class="align-middle" scope="col"></th> -->
                    <th style="text-align: end" scope="col">Subtotal</th>
                  </tr>
                </thead>
                <tbody style="border-bottom: 2px solid lightgray">
                  <tr *ngFor="let product of shippableOrdersDetail?.orderLines">
                    <td class="align-middle">
                      <div class="d-flex align-items-center">
                        <div class="cart-img-box">
                          <img
                            *ngIf="
                              product?.images == null || product?.images == ''
                            "
                            src="assets/images/tyreryder_images/img_not_available.jpg"
                            class="img-fluid"
                            alt="{{ product?.catalogueName }}"
                          />

                          <img
                            *ngIf="
                              product?.images != null && product?.images != ''
                            "
                            src="{{ product?.images[0].replace('lg-', 'sm-') }}"
                            class="img-fluid"
                            alt="{{ product?.catalogueName }}"
                          />
                        </div>

                        <div>
                          <div class="tire-spec">
                            <div class="tire-spec-wrapper tr-font mb-1">
                              <span class="mb-0 text-small font-weight500">
                                {{ product.catalogueMPN }}
                              </span>
                            </div>
                          </div>

                          <div class="">
                            <div class="tyre-name base-color mb-8">
                              <span data-qa-id="tire-product-name mb-0">
                                {{ product.catalogueName }}
                              </span>
                            </div>
                          </div>

                          <div class="d-flex">
                            <div class="mr-1">
                              <span class="item-price grand-total fs-item">
                                <del
                                  class="cart-product-media cart-product-row"
                                  *ngIf="product.discountAmount > 0"
                                >
                                  ${{ product.unitPrice | number : "1.2-2" }}
                                </del>
                                <small
                                  class="cart-product-media cart-product-row"
                                  *ngIf="product.discountAmount"
                                >
                                  ${{
                                    product.unitDiscountAmount
                                      | number : "1.2-2"
                                  }}</small
                                >
                                <small
                                  class="cart-product-media cart-product-row"
                                  *ngIf="product.discountAmount == 0"
                                  >${{
                                    product.unitPrice | number : "1.2-2"
                                  }}</small
                                >
                              </span>
                            </div>
                            <div class="d-flex">
                              <span class="item-price grand-total fs-item mr-1">
                                x {{ product.quantity }}
                              </span>
                              <span
                                class="item-price grand-total fs-item"
                                *ngIf="product.taxAmount > 0"
                              >
                                +
                                {{
                                  product.taxAmount / product.quantity
                                    | number : "1.2-2"
                                }}<span class="h6 base-color">(FET)</span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="align-middle">
                      <span class="font-weight500">
                        {{ product.quantity }}
                      </span>
                    </td>

                    <td class="align-middle">
                      <span
                        class="badge badge-warning"
                        *ngIf="product.returnedQuantity < 0"
                      >
                        Return Request</span
                      >
                      <span
                        class="badge badge-success"
                        *ngIf="product.returnedQuantity == 0"
                      >
                        {{ product.status }}
                      </span>
                    </td>
                    <td class="align-middle" style="text-align: end">
                      <h5 class="total-price grand-total base-color">
                        ${{ product.grossAmount | number : "1.2-2" }}
                      </h5>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="w-100 place-con mb-4 wholesale-subtotal">
              <div class="wrapper4">
                <div class="total-price float-right">
                  <table class="float-right total-data mt-4">
                    <tbody>
                      <tr>
                        <td>Subtotal</td>
                        <td class="tright">
                          ${{
                            shippableOrdersDetail?.netAmount | number : "1.2-2"
                          }}
                        </td>
                      </tr>
                      <tr *ngIf="shippableOrdersDetail?.discountAmount > 0">
                        <td>Discount</td>
                        <td class="tright">
                          - ${{
                            shippableOrdersDetail?.discountAmount
                              | number : "1.2-2"
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>Shipping Cost</td>
                        <td class="tright">$0.00</td>
                      </tr>
                      <tr></tr>
                      <tr>
                        <td>FET</td>
                        <td class="tright">
                          ${{
                            shippableOrdersDetail?.taxAmount | number : "1.2-2"
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>Total</td>
                        <td class="color-Prices font-weight-bold">
                          ${{
                            shippableOrdersDetail?.grossAmount
                              | number : "1.2-2"
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div style="clear: both"></div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="tab-pane fade"
            id="payment-grid"
            role="tabpanel"
            aria-labelledby="payment-grid"
          >
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr class="align-middle">
                    <th class="align-middle" scope="col">Amout</th>
                    <th class="align-middle" scope="col">Date</th>
                    <th class="align-middle" scope="col">Type</th>
                    <th class="align-middle" scope="col">Payment Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody style="border-bottom: 2px solid lightgray">
                  <tr
                    *ngFor="
                      let payment of shippableOrdersDetail?.orderPayments
                        .orderPaymentDetails
                    "
                  >
                    <td class="align-middle">
                      <span class="font-weight500">
                        <h5 class="total-price grand-total base-color">
                          ${{ payment.amount | number : "1.2-2" }}
                        </h5>
                      </span>
                    </td>
                    <td class="align-middle">
                      <span class="font-weight500">
                        {{ payment.createdAt | utcToLocal : "dd-MMM-yyyy" }} at
                        {{ payment.createdAt | utcToLocal : "h:mm a" }}
                      </span>
                    </td>
                    <td class="align-middle">
                      <span class="font-weight500">
                        {{ payment.customerPaymentMethod.paymentMethodName }}
                      </span>
                    </td>
                    <td class="align-middle">
                      <span class="font-weight500">
                        <span class="badge badge-warning text-white">{{
                          shippableOrdersDetail?.orderPayments?.paymentStatus
                        }}</span>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div
            class="tab-pane fade"
            id="return-grid"
            role="tabpanel"
            aria-labelledby="return-grid"
          >
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr class="align-middle">
                    <th class="align-middle" scope="col">Items</th>
                    <th class="align-middle" scope="col">Return Status</th>
                    <th class="align-middle" scope="col">Unit</th>
                    <th class="align-middle" scope="col">
                      Return Requested Qty
                    </th>
                    <th class="align-middle" scope="col">Return Date</th>
                    <!-- <th class="align-middle" scope="col"></th> -->
                    <th style="text-align: end" scope="col">Subtotal</th>
                  </tr>
                </thead>
                <tbody style="border-bottom: 2px solid lightgray">
                  <tr
                    *ngFor="
                      let product of shippableOrdersDetail?.returnOrderLines
                    "
                  >
                    <td class="align-middle">
                      <div class="d-flex align-items-center">
                        <div class="cart-img-box">
                          <img
                            *ngIf="
                              product?.images == null || product?.images == ''
                            "
                            src="assets/images/tyreryder_images/img_not_available.jpg"
                            class="img-fluid"
                            alt="{{ product?.catalogueAddtionalDescription }}"
                          />

                          <img
                            *ngIf="
                              product?.images != null && product?.images != ''
                            "
                            src="{{ product?.images[0].replace('lg-', 'sm-') }}"
                            class="img-fluid"
                            alt="{{ product?.catalogueAddtionalDescription }}"
                          />
                        </div>

                        <div>
                          <div class="tire-spec">
                            <div class="tire-spec-wrapper tr-font mb-1">
                              <span class="mb-0 text-small font-weight500">
                                {{ product.catalogueMPN }}
                              </span>
                            </div>
                          </div>

                          <div class="">
                            <div class="tyre-name base-color mb-8">
                              <span data-qa-id="tire-product-name mb-0">
                                {{ product.catalogueAddtionalDescription }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="align-middle">
                      <span class="badge badge-warning">
                        {{ product.status }}
                      </span>
                    </td>
                    <td class="align-middle">
                      <span class="base-color"> ${{ product.unitPrice }} </span>
                    </td>
                    <td class="align-middle">
                      <span class="font-weight500">
                        {{ product.quantity | abs }}
                      </span>
                    </td>
                    <td class="align-middle">
                      <small>
                        {{ product.createdOn | utcToLocal : "dd-MMM-yyyy" }} at
                        {{ product.createdOn | utcToLocal : "h:mm a" }}
                      </small>
                    </td>
                    <td class="align-middle" style="text-align: end">
                      <h5 class="total-price grand-total base-color">
                        ${{ product.grossAmount | abs | number : "1.2-2" }}
                      </h5>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="w-100 place-con mb-4 wholesale-subtotal"
              *ngIf="shippableOrdersDetail?.returnOrderLines != null"
            >
              <div class="wrapper4">
                <div class="total-price float-right">
                  <table class="float-right total-data mt-4">
                    <tbody>
                      <tr>
                        <td>Subtotal</td>
                        <td class="tright">
                          ${{ returnSubtotal | abs | number : "1.2-2" }}
                        </td>
                      </tr>
                      <tr>
                        <td>Shipping Cost</td>
                        <td class="tright">$0.00</td>
                      </tr>
                      <tr>
                        <td>FET</td>
                        <td class="tright">
                          ${{ returnfet | abs | number : "1.2-2" }}
                        </td>
                      </tr>
                      <tr>
                        <td>Total</td>
                        <td class="color-Prices font-weight-bold">
                          ${{ returntotal | abs | number : "1.2-2" }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div style="clear: both"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Tabs content -->
      </div>
    </div>
  </div>
  <!-- Modal -->
  <div
    class="modal fade"
    id="cancelorder"
    #cancelorder
    tabindex="-1"
    role="dialog"
    aria-labelledby="cancelOrderModal"
    aria-hidden="true"
  >
    <form [formGroup]="CancelForm">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="cancelOrderModal">Modal title</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label class="mb-0 font-weight500" for="exampleFormControlSelect1"
                >Reason</label
              >
              <select
                class="form-control"
                id="exampleFormControlSelect1"
                formControlName="reason"
              >
                <option value="Change of mind">Change of mind</option>
                <option value="Wrong Order">Wrong Order</option>
                <option value="Wrong Address">Wrong Address</option>
                <option value="Payment issues">Payment issues</option>
              </select>
            </div>
            <div
              *ngIf="
                CancelForm.get('reason').touched &&
                CancelForm.get('reason').errors?.required
              "
              class="text-danger"
            >
              Reason is required
            </div>
            <div class="form-group mb-0">
              <label class="mb-0 font-weight500" for="exampleFormControlInput1"
                >Confirmation Text</label
              >
              <p class="mb-0" style="font-size: 14px">
                Pleaes type 'cancel' in below text box to confirm the
                cancellation.
              </p>
              <input
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
                formControlName="cancelText"
                placeholder=""
              />
              <div
                *ngIf="
                  CancelForm.get('cancelText').touched &&
                  CancelForm.get('cancelText').errors
                "
              >
                <div
                  *ngIf="CancelForm.get('cancelText').errors.required"
                  class="text-danger"
                >
                  Confirmation text is required
                </div>
                <div
                  *ngIf="CancelForm.get('cancelText').errors.pattern"
                  class="text-danger"
                >
                  Please type 'cancel' to confirm
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              (click)="CancelOrder()"
            >
              Cancel Order
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div
    class="modal fade-modal-lg"
    id="returnorder"
    #returnorder
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <form [formGroup]="returnForm">
          <div class="modal-header">
            <h5 class="modal-title primary-color" id="exampleModalLabel">
              Return Order Request
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="order-returnlisting">
              <table class="table table-hover table-responsive">
                <thead>
                  <tr>
                    <th class="col-3">Item(s)</th>
                    <th class="col-2">Order Status</th>
                    <th class="col-2">Unit Price($)</th>
                    <th class="col-1">Qty to Return</th>
                    <th class="col-2">Sub Total($)</th>
                    <th class="col-2">Request Return Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let product of returnOrderLine; index as i">
                    <td>
                      <div
                        class="text-small primary-color"
                        style="font-weight: bold"
                      >
                        {{ product.MfA }}
                      </div>
                      <div>
                        {{ product.AlternateMFA }}
                      </div>
                    </td>
                    <td>
                      <div
                        class="badge badge-warning bg-light-warning font-weight500 complete-btn text-white"
                        *ngIf="product.orderLineStatus == 20"
                      >
                        Return Request
                      </div>
                      <div
                        class="badge badge-success bg-light-warning font-weight500 complete-btn text-white"
                        *ngIf="product.orderLineStatus == 19"
                      >
                        Delivered
                      </div>
                    </td>
                    <td>
                      <div
                        class="primary-color text-small"
                        style="font-weight: bold"
                      >
                        <div class="d-flex">
                          <div class="mr-1">
                            <h3 class="item-price grand-total fs-item">
                              <del
                                class="cart-product-media cart-product-row"
                                style="font-weight: bold"
                                *ngIf="product.discountAmount > 0"
                              >
                                ${{ product.unitPrice | number : "1.2-2" }}
                              </del>
                              <small
                                class="cart-product-media cart-product-row fw-bold"
                                style="font-weight: bold"
                                *ngIf="product.discountAmount"
                              >
                                ${{
                                  product.unitDiscountAmount | number : "1.2-2"
                                }}</small
                              >
                              <small
                                class="cart-product-media cart-product-row fw-bold"
                                style="font-weight: bold"
                                *ngIf="product.discountAmount == 0"
                                >${{
                                  product.unitPrice | number : "1.2-2"
                                }}</small
                              >
                            </h3>
                            <h3
                              class="item-price grand-total fs-item"
                              *ngIf="product.unitTaxAmount > 0"
                            >
                              + {{ product.unitTaxAmount | number : "1.2-2"
                              }}<span class="h6 base-color">(FET)</span>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div
                        class="primary-color text-small"
                        style="font-weight: bold"
                      >
                        <div>
                          {{ product.actualQuantity + product.returnQuantity }}
                        </div>
                      </div>
                    </td>
                    <td>${{ product.grossAmount | number : "1.2-2" }}</td>
                    <td>
                      <div class="form-group">
                        <input
                          type="number"
                          min="0"
                          class="form-control"
                          (keyup.enter)="valuechange(product)"
                          (change)="valuechange(product)"
                          [readonly]="product.returnQuantity < 0"
                          [formControl]="product.returnedQuantity"
                          id="usr"
                          style="width: 60%"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group mb-2">
                  <label
                    class="mb-0 font-weight500"
                    for="exampleFormControlSelect1"
                    >Reason</label
                  >
                  <select
                    class="form-control"
                    formControlName="reason"
                    id="exampleFormControlSelect1"
                  >
                    <option value="no used">No used</option>
                    <option value="extra">extra</option>
                  </select>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group mb-2">
                  <label
                    class="mb-0 font-weight500"
                    for="exampleFormControlSelect1"
                    >Condition</label
                  >
                  <select
                    class="form-control"
                    formControlName="condition"
                    id="exampleFormControlSelect1"
                  >
                    <option value="good">Good</option>
                    <option value="damage">Damage</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-group mb-2">
              <label
                class="mb-0 font-weight500"
                for="exampleFormControlTextarea1"
                >Reason Notes</label
              >
              <textarea
                class="form-control"
                formControlName="description"
                id="exampleFormControlTextarea1"
                rows="3"
              ></textarea>
            </div>

            <div class="orderReturn-subtotal">
              <div class="">
                <div class="total-price float-right">
                  <table class="float-right total-data">
                    <tbody>
                      <tr>
                        <td>Return Qty(s)</td>
                        <td class="tright">{{ itemsReturnCount }} items</td>
                      </tr>
                      <tr>
                        <td>Value of Return</td>
                        <td class="tright" style="color: #b31b1b">
                          ${{ itemsReturnAmount | number : "1.2-2" }}
                        </td>
                      </tr>
                      <tr>
                        <td>Return Charges (i)</td>
                        <td class="tright primary-color" style="color: #b31b1b">
                          ${{ returnCharges | number : "1.2-2" }}
                        </td>
                      </tr>
                      <tr>
                        <td>FET</td>
                        <td class="tright primary-color" style="color: #b31b1b">
                          ${{ fetCount | number : "1.2-2" }}
                        </td>
                      </tr>
                      <tr>
                        <td>Total Return Value</td>
                        <td class="tright primary-color" style="color: #b31b1b">
                          ${{
                            itemsReturnAmount + returnCharges + fetCount
                              | number : "1.2-2"
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div style="clear: both"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              cancel
            </button>
            <button
              type="button"
              class="btn btn-primary"
              (click)="returnOrder()"
              style="background-color: #b31b1b"
            >
              Request for Return
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
