import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {BehaviorSubject, Observable } from 'rxjs'
import { environment } from '../../app/core/enviroment/environment';
import { StaticService } from '../static/static.service';
@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private httpOptions: any;
  headers: any;
  private baseUrl = '';

  constructor(private http: HttpClient) {
    this.init();
    this.baseUrl = environment.apiUrl;
   }

   
   public init(): void {

    this.headers = new HttpHeaders({ 'Content-Type': 'application/json' })

    this.httpOptions = {
      headers: this.headers, observe: 'response'
    };
  }
  public refreshAuth(body:any): Observable<any>{
    return this.http.post<any>(this.baseUrl + StaticService.AUTH_REFRESHAUTHTOKEN, body, this.httpOptions);
  }
  public getCustomerOrdersList(userId: any): Observable<any> {
    return this.http.get<any>(this.baseUrl + StaticService.AUTH_GETCUSTOMERORDERSLISTURL + userId + "/all", this.httpOptions);
  }
  public saveOrder(userId: any, token: any): Observable<any> {
    var saveOrderUrl = StaticService.ORDER_SaveOrderStatus.replace('{0}', userId);
    return this.http.post<any>(this.baseUrl + saveOrderUrl, token, this.httpOptions);
  }
  public getCustomerProfileDetail(userId: any): Observable<any> {
    return this.http.get<any>(this.baseUrl + StaticService.AUTH_GETCUSTOMERPROFILEDETAILURL + userId, this.httpOptions);
  }

  public submitProfileDetail(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + StaticService.AUTH_SUBMITPROFILEDETAILURL, body, this.httpOptions);
  }
  public B2BProfile(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + StaticService.WHOLESALE_PROFILE, body, this.httpOptions);
  }

  public updateAddress(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + StaticService.AUTH_UPDATEADDRESSURL, body, this.httpOptions);
  }

  public uploadProfilePicture(userId: any,body:any): Observable<any>{
    return this.http.post<any>(this.baseUrl + StaticService.AUTH_UPLOADPROFILEPICTUREURL + "?customerId=" + userId , body, this.httpOptions);
  }
  public getOrderDetail(orderid :any): Observable<any> {
    return this.http.get<any>(this.baseUrl + StaticService.B2B_ORDER_SUMMARY +'/'+ orderid + '/shippablesummary' , this.httpOptions);
  }
  public getServiceOrderDetail(orderid :any): Observable<any> {
    return this.http.get<any>(this.baseUrl + StaticService.AUTH_ORDERDETAILURL + orderid + '/summary' , this.httpOptions);
  }
  public getVendorOrderDetail(orderid :any): Observable<any> {
    return this.http.get<any>(this.baseUrl + StaticService.AUTH_ORDERVENDORDETAILURL + orderid + '/summary' , this.httpOptions);
  }
  public getvendorOrderList(vendorId :any): Observable<any> {
    return this.http.get<any>(this.baseUrl + StaticService.AUTH_VENDORORDERLIST + vendorId + '/all' , this.httpOptions);
  }
}
