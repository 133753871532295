import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JsonPipe, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { interceptorService } from './interceptor/interceptor.service';
import { Route, Router } from '@angular/router';
import { environment } from '../app/core/enviroment/environment';
import { StaticService } from './static/static.service';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private httpOptions: any;
  headers: any;
  private baseUrl = '';
  isServer: any;
  isBrowser: any;
  currentUser: any;
  user:any;
  private callSent: boolean = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private interService: interceptorService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.isServer = isPlatformServer(platformId);
    this.init();
    this.baseUrl = environment.apiUrl;
    
  }

  public init(): void {
    if (this.isBrowser) {
      this.currentUser = localStorage.getItem('token');
      this.currentUser = JSON.parse(this.currentUser);
      this.user=localStorage.getItem('user');
      this.user = JSON.parse(this.user);
    }
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json' })

    this.httpOptions = {
      headers: this.headers, observe: 'response'
    };
  }

  public isAuthenticated() {
    if (this.isBrowser) {
      if (!localStorage.getItem('user')) {
        return false;
      } else {
        return true;
      }
    }
    return true
  }
  public saveToken(res:any){
    if(this.isBrowser){
      console.log('res',res)

      localStorage.setItem('token', JSON.stringify(res));
    }
    
  }
  getCallSentStatus(): boolean {
    return this.callSent;
  }
  setCallSentStatus(value: boolean): void {
    this.callSent = value;
  }
  public isB2BAuthenticated() {
    if (this.isBrowser) {
      var user: any = localStorage.getItem('user')
      if (user) {
        user = JSON.parse(user)
        if (user.customerType == 2) {
          return true;
        } else {
          return false;
        }
      } else { return false }
    }
    return true
  }

  public signupCustomer(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + StaticService.AUTH_SIGNUPCUSTOMER, body, this.httpOptions);
  }

  public signupTyreCustomer(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + StaticService.AUTH_SIGNUPTYRECUSTOMER, body, this.httpOptions);
  }

  public refreshAuth(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + StaticService.AUTH_REFRESHAUTHTOKEN, body, this.httpOptions);
  }
  public refreshB2BAuth(body:any): Observable<any>{
    return this.http.post<any>(this.baseUrl + StaticService.B2BAUTH_REFRESHAUTHTOKEN, body);
  }
  public verifySignupCustomerPin(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + StaticService.AUTH_VERIFYSIGNUPCUSTOMERPINURL, body, this.httpOptions);
  }

  public getRegenerateSignupCustomerPin(): Observable<any> {
    return this.http.get<any>(this.baseUrl + StaticService.AUTH_GETREGENERATESIGNUPCUSTOMERPINURL, this.httpOptions);
  }
  public getResendToken(token:any): Observable<any> {
    return this.http.get<any>(this.baseUrl + StaticService.AUTH_SIGNUPTYRECUSTOMERVERIFYTOKEN.replace(':token',token), this.httpOptions);
  }
  public getResetPAsswordVerify(token:any): Observable<any> {
    return this.http.get<any>(this.baseUrl + StaticService.AUTH_RESERPASSWORD_VERIFY.replace(':token',token), this.httpOptions);
  }
  public generateLoginCustomerPin(): Observable<any> {
    return this.http.get<any>(this.baseUrl + StaticService.AUTH_GENERATELOGINCUSTOMERPINURL, this.httpOptions);
  }

  public loginCustomerwithPassword(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + StaticService.AUTH_LOGINCUSTOMERWITHPASSWORDURL, body, this.httpOptions);
  }

  public loginB2bCustomerwithPassword(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + StaticService.AUTH_LOGINB2BCUSTOMERWITHPASSWORDURL, body, this.httpOptions);
  }

  public verifyLoginCustomerPin(): Observable<any> {
    return this.http.get<any>(this.baseUrl + StaticService.AUTH_VERIFYLOGINCUSTOMERPINURL, this.httpOptions);
  }

  public generateForgetCustomerPassPin(): Observable<any> {
    return this.http.get<any>(this.baseUrl + StaticService.AUTH_GENERATEFORGETCUSTOMERPASSPINURL, this.httpOptions);
  }

  public updateCustomerPassByPin(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + StaticService.AUTH_UPDATECUSTOMERPASSBYPINURL, body, this.httpOptions);
  }

  public regenerateBeauticianSignUp(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + StaticService.AUTH_REGENERATEBEAUTICIANSIGNUPPIN, body, this.httpOptions);
  }

  public regenerateCustomerSignUp(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + StaticService.AUTH_REGENERATECUSTOMERSIGNUPPIN, body, this.httpOptions);
  }

  public updateBeauticianPassByPin(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + StaticService.AUTH_UPDATEBEAUTICIANPASSBYPINURL, body, this.httpOptions);
  }

  // signup and login for Beautician

  public signupBeautician(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + StaticService.AUTH_SIGNUPBEAUTICIANURL, body, this.httpOptions);
  }

  public loginBeauticianwithPassword(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + StaticService.AUTH_LOGINBEAUTICIANWITHPASSWORDURL, body, this.httpOptions);
  }

  public signupProfileUpdate(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + StaticService.AUTH_SIGNUPPROFILEUPDATEURL, body, this.httpOptions);
  }

  public verifySignupBeauticianPin(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + StaticService.AUTH_VERIFYSIGNUPBEAUTICIANPINURL, body, this.httpOptions);
  }

  public newsLetterSubscription(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + StaticService.AUTH_NEWSLETTER, body, this.httpOptions);
  }

  public contactUs(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + StaticService.AUTH_CONTACTUS, body, this.httpOptions);
  }

  public newAddress(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + StaticService.AUTH_NEWADDRESS, body, this.httpOptions);
  }
  public forgotPassword(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + StaticService.AUTH_FORGOTPASSWORD, body, this.httpOptions);
  }
  public resestPassword(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + StaticService.AUTH_FORGOTPASSWORDVERIFICATION, body, this.httpOptions);
  }
  public verifyToken(token: any): Observable<any> {
    return this.http.get<any>(this.baseUrl+StaticService.AUTH_SIGNUPVERIFYTYRECUSTOMER+'?token='+token, this.httpOptions);
  }
  public refreshToken(request: any) {
    if(this.isBrowser)
    {
      this.interService.setCallSentStatus(true);
      let usertoken: RefreshRequest = new RefreshRequest();
      if (this.currentUser) {
        usertoken.refreshToken = this.currentUser.refreshToken,
          usertoken.accessToken = this.currentUser.token

        if (this.user.customerType == 2) {
          this.interService.refreshB2BAuth(usertoken).subscribe({
            next: (res: any) => {
              if (res.status.statusCode != 'ERROR') {
                this.saveToken(res.response)
                this.setCallSentStatus(false);
                this.currentUser = res.response
                return this.currentUser.token
              }
              else {
                this.setCallSentStatus(false);
                this.onB2BLogout();
              }
            },
            error: (error: any) => {
              this.setCallSentStatus(false);
              this.onB2BLogout();
            }
          });
        }
        else {
          this.interService.refreshAuth(usertoken).subscribe({
            next: (res: any) => {
              if (res.status.statusCode != 'ERROR') {
                this.saveToken(res.status.response)
                this.setCallSentStatus(false);
                this.currentUser = res.response
                return this.currentUser.token
              }
              else {
                this.setCallSentStatus(false);
                this.onLogout();
              }
            },
            error: (error: any) => {
              this.setCallSentStatus(false);
              this.onLogout();
            }
          });
        }
      }
    }
  }

  onLogout() {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    this.router.navigate(['/login']); // navigate to same route
  }
  onB2BLogout() {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    this.router.navigate(['/login']); // navigate to same route
  }

}

export class RefreshRequest {
  refreshToken: string = "";
  accessToken: string = "";
  constructor() {
    this.refreshToken = "";
    this.accessToken = "";
  }
}

