import {
  Component,
  Inject,
  PLATFORM_ID,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInput, MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  AsyncPipe,
  DecimalPipe,
  NgClass,
  NgFor,
  NgIf,
  isPlatformBrowser,
  isPlatformServer,
} from '@angular/common';
import { startWith, map, Observable } from 'rxjs';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FilterService } from '../../../services/filter/filter.service';
import { B2bService } from '../../../services/b2b/b2b.service';
import { SharedService, selectData } from '../../../services/shared/shared.service';
import { ShopService } from '../../../services/shop/shop.service';


@Component({
  selector: 'app-wholesale-products',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    MatAutocompleteModule,
    NgIf,
    NgFor,
    AsyncPipe,
    MatProgressSpinnerModule,
    DecimalPipe,
  ],
  templateUrl: './wholesale-products.component.html',
  styleUrl: './wholesale-products.component.css'
})
export class WholesaleProductsComponent {
  isBrowser: any;
  isServer: any;
  widthControl = new FormControl('');
  widthOptions: selectData[] = [];
  widthOption!: Observable<selectData[]>;
  widthloader = false;

  ratioControl = new FormControl('');
  ratioOptions: selectData[] = [];
  ratioOption!: Observable<selectData[]>;
  ratioloader = false;

  diameterControl = new FormControl('');
  diameterOptions: selectData[] = [];
  diameterOption!: Observable<selectData[]>;

  brandControl = new FormControl('');
  brandOptions: selectData[] = [];
  brandOption!: Observable<selectData[]>;
  searchControl = new FormControl('');

  currentPageIndex: number = 0;
  page: number = 1;
  diameterloader = false;
  widthTyre: any;
  ratioTyre: any;
  diameterTyre: any;
  userDetail: any;
  variantList: any;
  tyreBrand: any;
  total: number = 0;
  cartItems: any;
  totalQuantity:any;
  showBillingForm: boolean = false;
  quan: any;
  varId: any;
  totalPages: number = 0;
  width: any;
  ratio: any;
  diameter: any;
  tyre: any;
  partNo: string = '';
  partTrue: boolean = false;
  totalAmount:any
  addedcart = new Set<string>();
  @ViewChildren(MatInput) input!: QueryList<MatInput>;
  sortDirection: string = 'asc';
  sortedData: any[] = [];
  sortColumn: string = 'salePrice';
  allPages: any;
  currentPage: number=1;
  discountAmount:any;
  taxAmount:any;
  netAmount:any;

  constructor(
    private filterService: FilterService,
    public sharedService: SharedService,
    private shopService: ShopService,
    private b2bService: B2bService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.isServer = isPlatformServer(platformId);
  }

  ngOnInit() {
    if (this.isBrowser) {
      this.userDetail = localStorage.getItem('user');
      this.userDetail = JSON.parse(this.userDetail);
      this.getTireWidth();
      this.getTireBrand();
      this.getVariantListing();
    }
  }

  sort(): void {
    this.sortedData = this.variantList.sort((a:any, b:any) => {
      if (this.sortDirection === 'asc') {
        return a[this.sortColumn] > b[this.sortColumn] ? 1 : -1;
      } else {
        return a[this.sortColumn] < b[this.sortColumn] ? 1 : -1;
      }
    });
  }
  
  clearform() {
    this.widthTyre = null;
    this.ratioTyre = null;
    this.diameterTyre = null;
    this.tyreBrand = null;
    this.width = null;
    this.ratio = null;
    this.diameter = null;
    this.tyre = null;
    this.partTrue = false;
    this.searchControl.setValue(null);
    this.widthControl.setValue(null);
    this.brandControl.setValue(null);
    this.ratioControl.setValue(null);
    this.diameterControl.setValue(null);
    this.getVariantListingSearch();
  }

  cartVariantMapping() {
    this.variantList.forEach((variant: any) => {
      this.cartItems.forEach((cartItem: any) => {
        if (cartItem.catalogue.variantId == variant.variantId) {
          variant.cartItemQuantity.setValue(cartItem.quantity);
          this.addedcart.add(cartItem.catalogue.variantId);
        }
      });
    });
  }

  getVariantListing() {
    if (this.isBrowser) {
      this.sharedService.setShowLoading(true);
      this.b2bService
        .GetVariantListing(
          this.userDetail.userId,
          this.widthTyre,
          this.ratioTyre,
          this.diameterTyre,
          this.tyreBrand,
          this.searchControl.value,
          this.page
        )
        .subscribe(
          {
            next: (res: any) => {
              if ((res.status = 200)) {
                this.sharedService.setShowLoading(false);
                this.variantList = res.body.response.data;
                this.total = res.body.response.total;

                this.variantList.forEach((element: any) => {
                  element.cartItemQuantity = new FormControl(0, [
                    Validators.max(element.availableStock),
                    Validators.min(1),
                  ]);
                });
                this.getCart();

                var pageNumber = this.total % res.body.response.size;
                if (pageNumber == 0) {
                  this.totalPages = Math.floor(this.total / res.body.response.size);
                } else {
                  this.totalPages =
                    Math.floor(this.total / res.body.response.size) + 1;
                }
                this.allPages=this.totalPages % 10;
                this.sharedService.setShowLoading(false);
              }
        },error:(error:any)=>
        {
          this.sharedService.setShowLoading(false);
        }
      });
    }
  }
  getVariantListingSearch() {
    if (this.isBrowser) {
      this.page = 1;
      this.sharedService.setShowLoading(true);
      this.b2bService
        .GetVariantListing(
          this.userDetail.userId,
          this.widthTyre,
          this.ratioTyre,
          this.diameterTyre,
          this.tyreBrand,
          this.searchControl.value,
          this.page
        )
        .subscribe((res: any) => {
          if ((res.status = 200)) {
            this.sharedService.setShowLoading(false);
            this.variantList = res.body.response.data;
            this.total = res.body.response.total;
            if (
              this.widthTyre !== null &&
              this.widthTyre !== undefined &&
              this.ratioTyre == null
            ) {
              this.width = this.widthTyre;
              this.input.get(3)?.focus();
            }
            if (
              this.ratioTyre !== null &&
              this.ratioTyre !== undefined &&
              this.diameterTyre == null
            ) {
              this.ratio = this.ratioTyre;
              this.input.get(4)?.focus();
            }
            if (this.diameterTyre !== null && this.diameterTyre !== undefined) {
              this.diameter = this.diameterTyre;
            }
            if (this.tyreBrand !== null && this.tyreBrand !== undefined) {
              this.tyre = this.tyreBrand;
            }
            if (
              this.searchControl.value !== null &&
              this.searchControl.value !== undefined &&
              this.searchControl.value !== ''
            ) {
              this.partNo = this.searchControl.value;
              if (
                this.searchControl.value == null &&
                this.searchControl.value == ''
              ) {
                this.partTrue = false;
              } else {
                this.partTrue = true;
              }
              console.log(this.partNo);
            }
            this.variantList.forEach((element: any) => {
              element.cartItemQuantity = new FormControl(0, [
                Validators.max(element.availableStock),
                Validators.min(1),
              ]);
            });
            this.getCart();

            var pageNumber = this.total % res.body.response.size;
            if (pageNumber == 0) {
              this.totalPages = Math.floor(this.total / res.body.response.size);
            } else {
              this.totalPages =
                Math.floor(this.total / res.body.response.size) + 1;
            }
            this.sharedService.setShowLoading(false);
          }
        });
    }
  }
  pagesToShow(): number[] {
    const start = this.currentPageIndex * 10;
    const end = Math.min(start + 10, this.totalPages);
    return Array.from({ length: end - start }, (_, i) => start + i + 1);
  }
  onPageChange(pageNumber: any) {
    if (pageNumber != this.page) {
      this.page = 1;
      this.page = pageNumber;
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      this.getVariantListing();
    }
  }
  onPreviousPageSet() {
    if (this.currentPageIndex > 0) {
      this.currentPageIndex--;
      this.currentPage--;
      this.page = this.currentPageIndex * 10 + 1;
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      this.getVariantListing();
    }
  }
  onNextPageSet() {
    if (this.currentPageIndex < Math.floor(this.totalPages / 10)) {
      this.currentPageIndex++;
      this.currentPage++;
      this.page = this.currentPageIndex * 10 + 1;
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });

      this.getVariantListing();
    }
  }
  validateQuantity(index: any) {
    if (
      this.variantList[index].cartItemQuantity.value >
      this.variantList[index].availableStock
    ) {
      this.variantList[index].cartItemQuantity.setValue(
        this.variantList[index].availableStock
      );
    }
  }

  getTireWidth() {
    if (this.isBrowser) {
      this.widthloader = true;

      this.filterService.getAttributeSizeWidth().subscribe((res: any) => {
        this.widthOptions = res.body.response;
        this.widthloader = false;

        this.widthOption = this.widthControl!.valueChanges.pipe(
          startWith(''),
          map((value) =>
            this.sharedService._filterGroup(value || '', this.widthOptions)
          )
        );
      });
    }
  }

  getTireBrand() {
    if (this.isBrowser) {
      this.filterService.getAttributeBrand().subscribe((res: any) => {
        this.brandOptions = res.body.response;
        this.brandOption = this.brandControl!.valueChanges.pipe(
          startWith(''),
          map((value) =>
            this.sharedService._filterGroup(value || '', this.brandOptions)
          )
        );
      });
    }
  }

  getTireRatio(value: any) {
    if (this.isBrowser) {
      this.ratioloader = true;
      this.filterService.getAttributeSizeRatio(value).subscribe((res: any) => {
        this.ratioOptions = res.body.response;
        this.ratioloader = false;
        this.ratioOption = this.ratioControl!.valueChanges.pipe(
          startWith(''),
          map((value) =>
            this.sharedService._filterGroup(value || '', this.ratioOptions)
          )
        );
      });
    }
  }

  getTireDiameter(value1: any, value2: any) {
    if (this.isBrowser) {
      this.diameterloader = true;
      this.filterService
        .getAttributeSizeDiameter(value1, value2)
        .subscribe((res: any) => {
          this.diameterOptions = res.body.response;
          this.diameterloader = false;
          this.diameterOption = this.diameterControl!.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.sharedService._filterGroup(value || '', this.diameterOptions)
            )
          );
        });
    }
  }

  selectedWidth(selectedValue: any) {
    if (this.isBrowser) {
      this.widthTyre = this.widthOptions.filter(
        (x) => x.name == selectedValue
      )[0].value;
      this.ratioControl.setValue(null);
      this.diameterControl.setValue(null);
      this.getTireRatio(this.widthTyre);
      this.widthOption = this.widthControl!.valueChanges.pipe(
        startWith(''),
        map((value) =>
          this.sharedService._filterGroup(value || '', this.widthOptions)
        )
      );
    }
  }

  selectedBrand(selectedValue: any) {
    if (this.isBrowser) {
      this.tyreBrand = this.brandOptions.filter(
        (x) => x.name == selectedValue
      )[0].value;
      this.brandOption = this.ratioControl!.valueChanges.pipe(
        startWith(''),
        map((value) =>
          this.sharedService._filterGroup(value || '', this.brandOptions)
        )
      );
    }
  }

  selectedRatio(selectedRatioValue: any) {
    if (this.isBrowser) {
      this.ratioTyre = this.ratioOptions.filter(
        (x) => x.name == selectedRatioValue
      )[0].value;
      this.diameterControl.setValue(null);
      this.getTireDiameter(this.widthTyre, this.ratioTyre);
      this.ratioOption = this.ratioControl!.valueChanges.pipe(
        startWith(''),
        map((value) =>
          this.sharedService._filterGroup(value || '', this.ratioOptions)
        )
      );
    }
  }

  selectedDiameter(selectedValue: any) {
    if (this.isBrowser) {
      this.diameterTyre = this.diameterOptions.filter(
        (x) => x.name == selectedValue
      )[0].value;
      this.diameterOption = this.ratioControl!.valueChanges.pipe(
        startWith(''),
        map((value) =>
          this.sharedService._filterGroup(value || '', this.diameterOptions)
        )
      );
    }
  }

  getCart() {
    if (this.isBrowser) {
      this.totalAmount=0
      this.taxAmount=0
      this.netAmount=0
      this.discountAmount=0
      this.b2bService.GetShoppingCart(this.userDetail.userId).subscribe({
        next: (res: any) => {
          if (res.status == 200) {
            this.cartItems = res.body.response;
            this.cartItems.forEach((element: { catalogue: { totalAmount: number; pricing: { discountPrice: number; salePrice: number; tax: number; }; }; quantity: number; }) => {
              element.catalogue.totalAmount = element.quantity * (element.catalogue?.pricing.discountPrice > 0
                ? element.catalogue?.pricing.discountPrice
                : element.catalogue?.pricing.salePrice);
              this.taxAmount = element.quantity * (element.catalogue?.pricing.tax > 0
                  ? element.catalogue?.pricing.tax
                  : element.catalogue?.pricing.tax);
              this.netAmount = this.netAmount + (element.catalogue?.pricing.salePrice * element.quantity);
              if (element.catalogue?.pricing.discountPrice > 0) {
                this.discountAmount = this.discountAmount + (element.catalogue?.pricing.salePrice - element.catalogue?.pricing.discountPrice) * element.quantity
              }
            });
            this.totalAmount = this.netAmount - this.discountAmount + this.taxAmount;
            this.calculateTotalQuantity();
            this.cartVariantMapping();
          }
        },
        error: (error: any) => {},
      });
    }
  }
  calculateTotalQuantity() {
    this.totalQuantity = 0;
    for (var i = 0; i < this.cartItems.length; i++) {
      this.totalQuantity = this.totalQuantity + this.cartItems[i].quantity;
    }
  }
  AddtoCart(variant: any) {
    if (variant.cartItemQuantity.value > variant.availableStock) {
      console.log('cant add quantity');
    }
    let itemQuantity;
    let item = {
      variant: '',
      quantity: 0,
    };

    let itemIndex = this.cartItems.findIndex(
      (item: any) => item.variant?.variantId == variant.variantId
    );

    if (itemIndex >= 0) {
      itemQuantity = variant.cartItemQuantity.value;
    } else {
      item.variant = variant;
      itemQuantity = variant.cartItemQuantity.value;
    }
    item.quantity = itemQuantity;
    if (item.quantity > variant.availableStock) {
      this.sharedService.showGeneralMsgModal(
        `Quantity exceeds maximum quantity of ${variant.availableStock}`
      );
    } else if (item.quantity <= 0) {
      this.sharedService.showGeneralMsgModal('Minimum quantity is 1');
    } else {
      this.sharedService.setShowLoading(true);
      this.b2bService
        .UpdateShoppingCart(
          this.userDetail.userId,
          this.mapCatalogueData(
            itemQuantity,
            variant.variantId,
            variant.catalogueId
          )
        )
        .subscribe({
          next: (res: any) => {
            if (res.status == 200) {
              this.sharedService.setShowLoading(false);
              this.cartItems = res.body.response;
              this.cartItems.forEach((element: any) => {
                this.quan = element.quantity;

                this.addedcart.add(variant.variantId);
              });
              this.shopService.setb2bCartProductsList(this.cartItems);
              this.sharedService.showCart();
              this.getCart();
            }
          },
          error: (error: any) => {
            this.sharedService.setShowLoading(false);
          },
        });
    }
  }
  isInCart(item: any): boolean {
    return this.addedcart.has(item);
  }
  mapCatalogueData(quantity: any, variantId: any, productId: any) {
    if (this.isBrowser) {
      let user: any = localStorage.getItem('user');
      user = JSON.parse(user);

      let productDetail = {
        CustomerId: user ? user.userId : null,
        Catalogues: [
          {
            catalogueId: productId,
            Quantity: quantity,
            variantId: variantId,
          },
        ],
        guestId: localStorage.getItem('guestId')
          ? localStorage.getItem('guestId')
          : null,
      };
      return productDetail;
    }
    return null;
  }
  getTire() {
    if (this.widthTyre !== null && this.widthTyre !== undefined) {
      this.width = this.widthTyre;
      this.input.get(1)?.focus();
      this.getVariantListingSearch();
    }
  }
}
