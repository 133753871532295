import { NgClass, NgIf, NgFor, AsyncPipe, DecimalPipe, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Component, Inject, PLATFORM_ID, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInput, MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { Observable, startWith, map } from 'rxjs';
import { B2bService } from '../../../services/b2b/b2b.service';
import { FilterService } from '../../../services/filter/filter.service';
import { selectData, SharedService } from '../../../services/shared/shared.service';
import { ShopService } from '../../../services/shop/shop.service';

@Component({
  selector: 'app-disposableproduct',
  standalone: true,
  imports: [ MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    MatAutocompleteModule,
    NgIf,
    NgFor,
    AsyncPipe,
    MatProgressSpinnerModule,
    DecimalPipe,],
  templateUrl: './disposableproduct.component.html',
  styleUrl: './disposableproduct.component.css'
})
export class DisposableproductComponent {
  isBrowser: any;
  isServer: any;
  widthControl = new FormControl('');
  widthOptions: selectData[] = [];
  widthOption!: Observable<selectData[]>;
  widthloader = false;

  ratioControl = new FormControl('');
  ratioOptions: selectData[] = [];
  ratioOption!: Observable<selectData[]>;
  ratioloader = false;

  diameterControl = new FormControl('');
  diameterOptions: selectData[] = [];
  diameterOption!: Observable<selectData[]>;

  brandControl = new FormControl('');
  brandOptions: selectData[] = [];
  brandOption!: Observable<selectData[]>;
  searchControl = new FormControl('');

  currentPageIndex: number = 0;
  page: number = 1;
  diameterloader = false;
  widthTyre: any;
  ratioTyre: any;
  diameterTyre: any;
  userDetail: any;
  variantList: any;
  tyreBrand: any;
  total: number = 0;
  cartItems: any;
  showBillingForm: boolean = false;
  quan: any;
  varId: any;
  totalPages: number = 0;
  width: any;
  ratio: any;
  diameter: any;
  tyre: any;
  partNo: string = '';
  partTrue: boolean = false;
  addedcart = new Set<string>();
  @ViewChildren(MatInput) input!: QueryList<MatInput>;
  sortDirection: string = 'asc';
  sortedData: any[] = [];
  sortColumn: string = 'salePrice';
  allPages: any;
  currentPage: number=1;
  category:string = 'Disposable'
  totalQuantity: any;
  totalAmount: any
  discountAmount: any;
  taxAmount: any;
  netAmount: any;


  constructor(
    private filterService: FilterService,
    public sharedService: SharedService,
    private shopService: ShopService,
    private b2bService: B2bService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.isServer = isPlatformServer(platformId);
  }

  ngOnInit() {
    if (this.isBrowser) {
      this.userDetail = localStorage.getItem('user');
      this.userDetail = JSON.parse(this.userDetail);
   
      this.getVariantListing();
    }
  }

  sort(): void {
    this.sortedData = this.variantList.sort((a:any, b:any) => {
      if (this.sortDirection === 'asc') {
        return a[this.sortColumn] > b[this.sortColumn] ? 1 : -1;
      } else {
        return a[this.sortColumn] < b[this.sortColumn] ? 1 : -1;
      }
    });
  }
  
  clearform() {
    this.widthTyre = null;
    this.ratioTyre = null;
    this.diameterTyre = null;
    this.tyreBrand = null;
    this.width = null;
    this.ratio = null;
    this.diameter = null;
    this.tyre = null;
    this.partTrue = false;
    this.searchControl.setValue(null);
    this.widthControl.setValue(null);
    this.brandControl.setValue(null);
    this.ratioControl.setValue(null);
    this.diameterControl.setValue(null);
    this.getVariantListingSearch();
  }

  cartVariantMapping() {
    this.variantList.forEach((variant: any) => {
      this.cartItems.forEach((cartItem: any) => {
        if (cartItem.variant.variantId == variant.variantId) {
          variant.cartItemQuantity.setValue(cartItem.quantity);
          this.addedcart.add(cartItem.variant.variantId);
        }
      });
    });
  }

  getVariantListing() {
    if (this.isBrowser) {
      this.sharedService.setShowLoading(true);
      this.b2bService
        .GetVariantListingBYCategory(
          this.userDetail.userId,
          this.category,
          this.page
        )
        .subscribe(
          {
            next: (res: any) => {
              if ((res.status = 200)) {
                this.sharedService.setShowLoading(false);
                this.variantList = res.body.response.data;
                this.total = res.body.response.total;

                this.variantList.forEach((element: any) => {
                  element.cartItemQuantity = new FormControl(0, [
                    Validators.max(element.availableStock),
                    Validators.min(1),
                  ]);
                });
                this.getCart();

                var pageNumber = this.total % res.body.response.size;
                if (pageNumber == 0) {
                  this.totalPages = Math.floor(this.total / res.body.response.size);
                } else {
                  this.totalPages =
                    Math.floor(this.total / res.body.response.size) + 1;
                }
                this.allPages=this.totalPages % 10;
                this.sharedService.setShowLoading(false);
              }
        },error:(error:any)=>
        {
          this.sharedService.setShowLoading(false);
        }
      });
    }
  }
  getVariantListingSearch() {
    if (this.isBrowser) {
      this.page = 1;
      this.sharedService.setShowLoading(true);
      this.b2bService
      .GetVariantListingBYCategory(
        this.userDetail.userId,
        this.category,
        this.page
      )
        .subscribe((res: any) => {
          if ((res.status = 200)) {
            this.sharedService.setShowLoading(false);
            this.variantList = res.body.response.data;
            this.total = res.body.response.total;
            if (
              this.widthTyre !== null &&
              this.widthTyre !== undefined &&
              this.ratioTyre == null
            ) {
              this.width = this.widthTyre;
              this.input.get(3)?.focus();
            }
            if (
              this.ratioTyre !== null &&
              this.ratioTyre !== undefined &&
              this.diameterTyre == null
            ) {
              this.ratio = this.ratioTyre;
              this.input.get(4)?.focus();
            }
            if (this.diameterTyre !== null && this.diameterTyre !== undefined) {
              this.diameter = this.diameterTyre;
            }
            if (this.tyreBrand !== null && this.tyreBrand !== undefined) {
              this.tyre = this.tyreBrand;
            }
            if (
              this.searchControl.value !== null &&
              this.searchControl.value !== undefined &&
              this.searchControl.value !== ''
            ) {
              this.partNo = this.searchControl.value;
              if (
                this.searchControl.value == null &&
                this.searchControl.value == ''
              ) {
                this.partTrue = false;
              } else {
                this.partTrue = true;
              }
              console.log(this.partNo);
            }
            this.variantList.forEach((element: any) => {
              element.cartItemQuantity = new FormControl(0, [
                Validators.max(element.availableStock),
                Validators.min(1),
              ]);
            });
            this.getCart();

            var pageNumber = this.total % res.body.response.size;
            if (pageNumber == 0) {
              this.totalPages = Math.floor(this.total / res.body.response.size);
            } else {
              this.totalPages =
                Math.floor(this.total / res.body.response.size) + 1;
            }
            this.sharedService.setShowLoading(false);
          }
        });
    }
  }
  pagesToShow(): number[] {
    const start = this.currentPageIndex * 10;
    const end = Math.min(start + 10, this.totalPages);
    return Array.from({ length: end - start }, (_, i) => start + i + 1);
  }
  onPageChange(pageNumber: any) {
    if (pageNumber != this.page) {
      this.page = 1;
      this.page = pageNumber;
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      this.getVariantListing();
    }
  }
  onPreviousPageSet() {
    if (this.currentPageIndex > 0) {
      this.currentPageIndex--;
      this.currentPage--;
      this.page = this.currentPageIndex * 10 + 1;
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      this.getVariantListing();
    }
  }
  onNextPageSet() {
    if (this.currentPageIndex < Math.floor(this.totalPages / 10)) {
      this.currentPageIndex++;
      this.currentPage++;
      this.page = this.currentPageIndex * 10 + 1;
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });

      this.getVariantListing();
    }
  }
  validateQuantity(index: any) {
    if (
      this.variantList[index].cartItemQuantity.value >
      this.variantList[index].availableStock
    ) {
      this.variantList[index].cartItemQuantity.setValue(
        this.variantList[index].availableStock
      );
    }
  }



  getCart() {
      if(this.isBrowser)
        {
        this.totalAmount = 0
        this.taxAmount = 0
        this.netAmount = 0
        this.discountAmount = 0
          this.cartItems=localStorage.getItem('cart');
          if(this.cartItems)
          {
            this.cartItems=JSON.parse(this.cartItems);
            this.cartItems.forEach((element: any) => {
              element.variant.totalAmount = element.quantity * (element.variant?.salePrice);
              this.taxAmount = element.quantity * (element.variant?.tax > 0
                  ? element.variant?.tax
                  : element.variant?.tax);
              this.netAmount = this.netAmount + (element.variant?.salePrice * element.quantity);
              if (element.variant?.discountPrice > 0) {
                this.discountAmount = this.discountAmount + (element.variant?.salePrice - element.variant?.discountPrice) * element.quantity
              }
            });
            this.totalAmount = this.netAmount - this.discountAmount + this.taxAmount;
            this.sharedService.setb2bCartProductsList(this.cartItems);
           }
           else this.cartItems=[]
        }
        this.cartVariantMapping()
        this.calculateTotalQuantity();
  }

  AddtoCart(variant: any) {
    let itemQuantity;
    let item = {
      variant: variant,
      quantity: 0,
    };
    let itemIndex = this.cartItems.findIndex(
      (item: any) => item.variant?.variantId == variant.variantId
    );

    if (itemIndex >= 0) {
      itemQuantity = variant.cartItemQuantity.value;
      this.cartItems.splice(itemIndex,1); 
      this.cartItems.push(item)

    } else {
      item.variant = variant;
      itemQuantity = variant.cartItemQuantity.value;
      this.cartItems.push(item)
    }
    item.quantity = itemQuantity;
   
    this.sharedService.setb2bCartProductsList(this.cartItems);
    if(this.isBrowser)
    {
        localStorage.setItem('cart', JSON.stringify(this.cartItems));
    }this.addedcart.add(item.variant.variantId)

  }
  calculateTotalQuantity() {
    this.totalQuantity = 0;
    for (var i = 0; i < this.cartItems.length; i++) {
      this.totalQuantity = this.totalQuantity + this.cartItems[i].quantity;
    }
  }
  isInCart(item: any): boolean {
    return this.addedcart.has(item);
  }
  mapCatalogueData(quantity: any, variantId: any, productId: any) {
    if (this.isBrowser) {
      let user: any = localStorage.getItem('user');
      user = JSON.parse(user);

      let productDetail = {
        CustomerId: user ? user.userId : null,
        Catalogues: [
          {
            catalogueId: productId,
            Quantity: quantity,
            variantId: variantId,
          },
        ],
        guestId: localStorage.getItem('guestId')
          ? localStorage.getItem('guestId')
          : null,
      };
      return productDetail;
    }
    return null;
  }
  getTire() {
    if (this.widthTyre !== null && this.widthTyre !== undefined) {
      this.width = this.widthTyre;
      this.input.get(1)?.focus();
      this.getVariantListingSearch();
    }
  }

}
