<!--BANNER SECTION-->
<section
  class="w-100 sec-top-header breadcrumb-section"
  style="background-color: #dcdcdc"
>
  <div class="wrapper4 d-flex tyre-ryder-header">
    <div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb p-0 m-0 breadcrumb-bg-color">
          <li class="breadcrumb-item breadcrumb-headsize">
            <a class="primary-color" href="/disposable/products">Disposal</a>
          </li>

          <li
            class="breadcrumb-item breadcrumb-headsize active"
            aria-current="page"
          >
            Cart
          </li>
        </ol>
      </nav>
    </div>
  </div>
</section>
<section
  class="w-100 padding-top bg-F5F6F9 float-left"
  *ngIf="isLoading == true"
>
  <div class="wrapper4">
    <div class="cart-card">
      <div class="row">
        <div class="col-lg-8">
          <div class="mb-5" style="margin: 0 0px">
            <div class="card main-section cart-listing-card mb-3">
              <div class="card-header m-2">
                <div
                  class="image-section cart-mobile skeleton-loader"
                  style="height: 250px"
                ></div>

                <div class="attribute-container cart-mobile">
                  <div class="card-body card-section-padding">
                    <div class="border-bottom">
                      <div class="skeleton-loader mb-2 loader-height"></div>
                      <div class="skeleton-loader mb-2 loader-height"></div>
                      <div class="skeleton-loader mb-2 loader-height"></div>

                      <div class="row">
                        <div class="col-6">
                          <div class="skeleton-loader mb-2 loader-height"></div>
                        </div>

                        <div class="col-6">
                          <div class="skeleton-loader mb-2 loader-height"></div>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-end mt-3">
                      <h3 class="skeleton-loader tr-loader-text">$203.00</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card main-section cart-listing-card mb-3">
              <div class="card-header m-2">
                <div
                  class="image-section cart-mobile skeleton-loader"
                  style="height: 250px"
                ></div>

                <div class="attribute-container cart-mobile">
                  <div class="card-body card-section-padding">
                    <div class="border-bottom">
                      <div class="skeleton-loader mb-2 loader-height"></div>
                      <div class="skeleton-loader mb-2 loader-height"></div>
                      <div class="skeleton-loader mb-2 loader-height"></div>

                      <div class="row">
                        <div class="col-6">
                          <div class="skeleton-loader mb-2 loader-height"></div>
                        </div>

                        <div class="col-6">
                          <div class="skeleton-loader mb-2 loader-height"></div>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-end mt-3">
                      <h3 class="skeleton-loader tr-loader-text">$203.00</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-lg-4 col-md-12 pt-3 order-summary-card"
          style="margin-bottom: 30px"
        >
          <h2 class="skeleton-loader tr-loader-text">Order Summary</h2>
          <div class="card" style="border-bottom: none; background: none">
            <div class="card-body">
              <div class="row" style="margin-bottom: 20px">
                <div class="col">
                  <div class="skeleton-loader mb-2 loader-height"></div>
                </div>
              </div>

              <div class="row" style="margin-bottom: 20px">
                <div class="col">
                  <div class="skeleton-loader mb-2 loader-height"></div>
                </div>
              </div>

              <div class="row mx-0 no-gutters d-lg-flex">
                <div class="col-6">
                  <h3 class="skeleton-loader tr-loader-text">Total</h3>
                </div>
                <div class="col-6">
                  <div class="text-right">
                    <h3 class="skeleton-loader tr-loader-text">$1,018.44</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="">
            <div class="skeleton-loader" style="height: 40px"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Cart SECTION -->
<section
  class="w-100 padding-top bg-F5F6F9 float-left"
  *ngIf="isLoading == false"
>
  <div class="wrapper4">
    <div class="cart-card">
      <div class="row">
        <div class="col-lg-8">
          <div class="mb-3" style="margin: 0 0px">
            <div
              class="card main-section cart-listing-card mb-2"
              *ngFor="let item of cartList; let i = index"
            >
              <div class="card-header">
                <div class="image-section cart-mobile">
                  <div
                    class="image-container brand-img-sm"
                    style="mix-blend-mode: multiply"
                  >
                    <img
                      *ngIf="
                        item?.variant?.image == null ||
                        item?.variant?.image == ''
                      "
                      src="assets/images/tyreryder_images/img_not_available.jpg"
                      class="img-fluid"
                      alt="{{ item?.catalogue?.description }}"
                    />
                    <img
                      *ngIf="
                        item?.variant?.image != null &&
                        item?.variant?.image != ''
                      "
                      src="{{ item?.variant?.image.replace('lg-', 'sm-') }}"
                      class="img-fluid"
                      alt="{{ item?.variant?.description }}"
                    />
                  </div>
                </div>

                <div class="attribute-container cart-mobile">
                  <!-- <div class="">
                            <i class="badge-warning seller-badge-label pl-2"
                              >best seller</i
                            >
                          </div> -->
                  <div class="card-body card-section-padding">
                    <div class="border-bottom pb-15px">
                      <h4 class="tyre-name-heading">
                        {{ item?.variant?.catalogueName }}
                      </h4>

                      <div class="mb-2">
                        <div class="review-section">
                          <a (click)="deleteItem(i)" style="cursor: pointer">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-trash base-color"
                              viewBox="0 0 16 16"
                            >
                              <path
                                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"
                              />
                              <path
                                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"
                              />
                            </svg>
                          </a>
                        </div>
                        <div style="clear: both"></div>
                      </div>

                      <div class="discount-price-secion diccount-margin">
                        <div class="size-value my-1 size-value-sm">
                          <div class="d-flex align-items-center">
                            <del
                              class="mb-0 total-amount mr-1"
                              *ngIf="item?.variant?.discountPrice != 0"
                            >
                              {{ item?.variant?.salePrice | number : "1.2-2" }}
                            </del>

                            <p
                              class="mb-0 discount-amount mr-2"
                              *ngIf="item?.variant?.discountPrice != 0"
                            >
                              ${{
                                item?.variant?.discountPrice | number : "1.2-2"
                              }}
                            </p>

                            <p
                              class="mb-0 discount-amount mr-2"
                              *ngIf="item?.variant?.discountPrice == 0"
                            >
                              ${{ item?.variant?.salePrice | number : "1.2-2" }}
                            </p>
                            <p
                              class="mb-0 discount-amount"
                              *ngIf="item?.variant?.tax > 0"
                            >
                              + ${{ item?.variant?.tax | number : "1.2-2"
                              }}<small class="color-B31B1B">(FET)</small>
                            </p>
                            <p class="text-small ml-1 fw-500 mb-0">
                              (Per Tire)
                            </p>
                          </div>
                        </div>
                        <div
                          class="row d-flex align-items-center review-section"
                        >
                          <div
                            class="col-lg-12 col-12 d-flex align-items-start"
                          >
                            <div class="d-inline-flex align-items-center mb-8">
                              <!-- <button type="button" class="minus change-quantity" aria-label="Decrease Quantity"
                                  (click)="SubtractQuantity(i)">
                                  <span>-</span>
                                </button> -->
                              <span class="cross-show">x</span
                              ><input
                                class="input input-box"
                                size="1"
                                best
                                disabled
                                type="text"
                                placeholder="{{ item?.quantity }}"
                              />

                              <!-- <button type="button" class="minus change-quantity" aria-label="Increase Quantity"
                                  (click)="AddQuantity(i)">
                                  +
                                </button> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-end mt-2">
                      <h3 class="m-0 total-price-heading">
                        ${{
                          item?.variant?.totalAmount + item?.variant?.taxAmount
                            | number : "1.2-2"
                        }}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-lg-4 col-md-12 pt-3 order-summary-card"
          style="margin-bottom: 30px"
        >
          <h2 class="order-summary-heading base-color">Order Summary</h2>
          <div class="card" style="border-bottom: none; background: none">
            <div class="card-body">
              <div class="row" style="margin-bottom: 20px">
                <div class="col">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <p class="text-standard mb-0">Subtotal</p>
                    <strong class="subtitle-small"
                      >${{ netAmount | number : "1.2-2" }}</strong
                    >
                  </div>
                </div>
              </div>
              <div
                class="row"
                style="margin-bottom: 20px"
                *ngIf="discountAmount > 0"
              >
                <div class="col">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <p class="text-standard mb-0">discount</p>
                    <strong class="subtitle-small">
                      - ${{ discountAmount | number : "1.2-2" }}</strong
                    >
                  </div>
                </div>
              </div>

              <div class="row" style="margin-bottom: 20px">
                <div class="col">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <p class="text-standard mb-0">Shipping</p>
                    <strong class="subtitle-small">$0.00</strong>
                  </div>
                </div>
              </div>

              <div class="row" style="margin-bottom: 20px">
                <div class="col">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <p class="text-standard mb-0">FET</p>
                    <strong class="subtitle-small"
                      >${{ totalTax | number : "1.2-2" }}</strong
                    >
                  </div>
                </div>
              </div>

              <div class="row mx-0 no-gutters d-lg-flex">
                <div class="col-6">
                  <h3 class="total-price-heading base-color">Total</h3>
                </div>

                <div class="col-6">
                  <div class="text-right">
                    <h3 class="total-price-heading grand-total base-color">
                      ${{ totalAmount | number : "1.2-2" }}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="">
            <div class="t-generic-btn1 mt-1 aos-init">
              <button
                class="button_border_none w-100"
                (click)="checkout()"
                [disabled]="cartList.length == 0"
                type="button"
              >
                Review & checkout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Cart SECTION -->
