import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../app/core/enviroment/environment';
import { AddCartComponent } from '../../app/wholesales/common/add-cart/add-cart.component';
import { WholeSaleCartComponent } from '../../app/wholesales/common/whole-sale-cart/whole-sale-cart.component';
import { MessageBoxComponentComponent } from '../../app/wholesales/common/message-box-component/message-box-component.component';
export class selectData {
  name: any = ""
  value: any = ""
}
export class SessionRequest {
  AmountInUSD: any;
  PM_CustomerId: any;
  constructor() {
    this.AmountInUSD = 0.0
    this.PM_CustomerId = ''
  }
}
@Injectable({
  providedIn: 'root'
})
export class SharedService {
  _filter(arg0: any) {
    throw new Error('Method not implemented.');
  }

  private products$ = new BehaviorSubject<any>([]);
  productsList$ = this.products$.asObservable();
  private total$ = new BehaviorSubject<any>([]);
  totalAmount$ = this.total$.asObservable();
  addedcart: Set<string> = new Set<string>();
  isBrowser:any;
  isServer:any;
    constructor(
        private dialog: MatDialog,
         @Inject(PLATFORM_ID) platformId: Object
    ) {

    this.isBrowser = isPlatformBrowser(platformId);
    this.isServer = isPlatformServer(platformId);
  }
  private showLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  _gettoken(){
    if(this.isBrowser)
    {
      var currentUser:any = localStorage.getItem('token');
      currentUser = JSON.parse(currentUser);
      if(currentUser)
      {
        return currentUser
      }
      else return null
    }
  }
  _getUser(){
    if(this.isBrowser)
    {
      var user:any = localStorage.getItem('user');
      user = JSON.parse(user);
      if(user)
      {
        return user
      }
      else return null
    }
  }
  setb2bCartProductsList(cartList: any) {
    this.products$.next(cartList);

    let totalAmount: any = 0;

    cartList.forEach((product: any,index:number) => {
      if(product.quantity==0)
      {
        cartList.splice(index,1)
      }
      totalAmount += product.quantity* (product.variant.discountPrice>0?product.variant.discountPrice:product.variant.salePrice);
    })
    this.total$.next(totalAmount);
  
    
  }
//   Search Filter in Home Page
//   _filterGroup(value: any, Array: selectData[]): selectData[] {
//     var data = Array.filter(data => data.name.toLowerCase().includes(value.toLowerCase()));
//     console.log('data',data)
//     return data
//   }
  _discountPercentage(discountPrice: any, salePrice: any): number {
    if (discountPrice > 0) {
      var res = Math.floor(((salePrice - discountPrice) / salePrice) * 100)
      return res;
    }
    return 0;
  }
  _filterNonKeys(nonKeys: any[]) {
    var returnvalue: any[] = [];
    var nonkey1 = environment.nonKeyType1
    var nonkey2 = environment.nonKeyType2
    var nonkey3 = environment.nonKeyType3

    nonKeys.forEach(element => {
      if (element.control_name == nonkey1 || element.control_name == nonkey2 || element.control_name == nonkey3) {
        returnvalue.push(element);
      }
    });
    return returnvalue;
  }
  states: any = [
    {
      "value": "AL",
      "text": "Alabama",
      "order": null
    },
    {
      "value": "AK",
      "text": "Alaska",
      "order": null
    },
    {
      "value": "AZ",
      "text": "Arizona",
      "order": null
    },
    {
      "value": "AR",
      "text": "Arkansas",
      "order": null
    },
    {
      "value": "CA",
      "text": "California",
      "order": null
    },
    {
      "value": "CO",
      "text": "Colorado",
      "order": null
    },
    {
      "value": "CT",
      "text": "Connecticut",
      "order": null
    },
    {
      "value": "DE",
      "text": "Delaware",
      "order": null
    },
    {
      "value": "DC",
      "text": "District Of Columbia",
      "order": null
    },
    {
      "value": "FL",
      "text": "Florida",
      "order": null
    },
    {
      "value": "GA",
      "text": "Georgia",
      "order": null
    },
    {
      "value": "HI",
      "text": "Hawaii",
      "order": null
    },
    {
      "value": "ID",
      "text": "Idaho",
      "order": null
    },
    {
      "value": "IL",
      "text": "Illinois",
      "order": null
    },
    {
      "value": "IN",
      "text": "Indiana",
      "order": null
    },
    {
      "value": "IA",
      "text": "Iowa",
      "order": null
    },
    {
      "value": "KS",
      "text": "Kansas",
      "order": null
    },
    {
      "value": "KY",
      "text": "Kentucky",
      "order": null
    },
    {
      "value": "LA",
      "text": "Louisiana",
      "order": null
    },
    {
      "value": "ME",
      "text": "Maine",
      "order": null
    },
    {
      "value": "MD",
      "text": "Maryland",
      "order": null
    },
    {
      "value": "MA",
      "text": "Massachusetts",
      "order": null
    },
    {
      "value": "MI",
      "text": "Michigan",
      "order": null
    },
    {
      "value": "MN",
      "text": "Minnesota",
      "order": null
    },
    {
      "value": "MS",
      "text": "Mississippi",
      "order": null
    },
    {
      "value": "MO",
      "text": "Missouri",
      "order": null
    },
    {
      "value": "MT",
      "text": "Montana",
      "order": null
    },
    {
      "value": "NE",
      "text": "Nebraska",
      "order": null
    },
    {
      "value": "NV",
      "text": "Nevada",
      "order": null
    },
    {
      "value": "NH",
      "text": "New Hampshire",
      "order": null
    },
    {
      "value": "NJ",
      "text": "New Jersey",
      "order": null
    },
    {
      "value": "NM",
      "text": "New Mexico",
      "order": null
    },
    {
      "value": "NY",
      "text": "New York",
      "order": null
    },
    {
      "value": "NC",
      "text": "North Carolina",
      "order": null
    },
    {
      "value": "ND",
      "text": "North Dakota",
      "order": null
    },
    {
      "value": "OH",
      "text": "Ohio",
      "order": null
    },
    {
      "value": "OK",
      "text": "Oklahoma",
      "order": null
    },
    {
      "value": "OR",
      "text": "Oregon",
      "order": null
    },
    {
      "value": "PA",
      "text": "Pennsylvania",
      "order": null
    },
    {
      "value": "RI",
      "text": "Rhode Island",
      "order": null
    },
    {
      "value": "SC",
      "text": "South Carolina",
      "order": null
    },
    {
      "value": "SD",
      "text": "South Dakota",
      "order": null
    },
    {
      "value": "TN",
      "text": "Tennessee",
      "order": null
    },
    {
      "value": "TX",
      "text": "Texas",
      "order": null
    },
    {
      "value": "UT",
      "text": "Utah",
      "order": null
    },
    {
      "value": "VT",
      "text": "Vermont",
      "order": null
    },
    {
      "value": "VA",
      "text": "Virginia",
      "order": null
    },
    {
      "value": "WA",
      "text": "Washington",
      "order": null
    },
    {
      "value": "WV",
      "text": "West Virginia",
      "order": null
    },
    {
      "value": "WI",
      "text": "Wisconsin",
      "order": null
    },
    {
      "value": "WY",
      "text": "Wyoming",
      "order": null
    },
    {
      "value": "AA",
      "text": "Armed Forces America",
      "order": null
    },
    {
      "value": "AE",
      "text": "Armed Forces EU/ME/AF",
      "order": null
    },
    {
      "value": "AP",
      "text": "Armed Forces Pacific",
      "order": null
    },
    {
      "value": "AS",
      "text": "American Samoa",
      "order": null
    },
    {
      "value": "GU",
      "text": "Guam",
      "order": null
    },
    {
      "value": "PR",
      "text": "Puerto Rico",
      "order": null
    },
    {
      "value": "VI",
      "text": "Virgin Islands",
      "order": null
    }
  ]
  _filterGroup(value: any, Array: selectData[]): selectData[] {
    var data = Array.filter(data => data.name.toLowerCase().includes(value.toLowerCase()));
    // console.log('data',data)
    return data
  }
  setShowLoading(value: boolean): void {
    this.showLoading = new BehaviorSubject<boolean>(value);
  }
  getShowLoading(): Observable<boolean> {
    return this.showLoading;
  }
  showTyreCartModal() {
    this.dialog.open(AddCartComponent, {
      disableClose: true, hasBackdrop: true,
    });
  }
  showCart() {
    this.dialog.open(WholeSaleCartComponent, {
      disableClose: true, hasBackdrop: true,
    });
  }
  showGeneralMsgModal(msg?: string, head?: string) {
    this.dialog.open(MessageBoxComponentComponent, {
      disableClose: true, hasBackdrop: true,
      data: {
        msg: msg,
        head: head,
      }
    });
  }
  clearCart() {
    if(this.isBrowser){
    }
    this.addedcart.clear();
    localStorage.removeItem('addedcart');
  }
}
export class SaveOrderStatusModel {
  returnOrderLines:returnOrderLineModel[]=[];
  orderId:string=''
  orderStatus: number = 0;
  orderLineStatus: number = 0;
  shipableorderType: number = 0;
  AdminUser:string=''
  description:string=''

  constructor() {
    this.orderId = "";
    this.orderStatus = 0;
    this.orderLineStatus = 0;
    this.shipableorderType = 0;
    this.AdminUser="";
    this.description="";
  }
}

export class returnOrderLineModel {
  orderId: string = "";
  orderLineId: string = "";
  returnedQuantity: number = 0;
  orderLineStatus: number = 0;
  constructor(){
    this.orderId="";
    this.orderLineId="";
    this.returnedQuantity=0;
    this.orderLineStatus=0;
  }
}
export class orderLineReturn {
  orderId: string="";
  variantId: string="";
  orderLineId:number= 0;
  actualQuantity:number=0;
  returnQuantity:number=0;
  discountAmount:number=0;
  grossAmount:number=0;
  unitDiscountAmount:number=0;
  unitPrice:number=0;
  taxAmount:number=0;
  unitTaxAmount:number=0;
  returnedQuantity:FormControl=new FormControl();
  orderLineStatus:number=0;
  status:string = "";
  MfA:string ="";
  AlternateMFA="";
  constructor() {
    this.orderId = "";
    this.variantId = "";
    this.orderLineId = 0;
    this.discountAmount = 0;
    this.grossAmount = 0;
    this.unitDiscountAmount = 0;
    this.unitPrice = 0;
    this.taxAmount = 0;
    this.unitTaxAmount = 0;
    this.actualQuantity=0;
    this.returnQuantity=0;
    this.returnedQuantity = new FormControl();
    this.orderLineStatus = 0;
    this.MfA="";
    this.AlternateMFA="";
    this.status=""
  }
}
