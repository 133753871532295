import { Component } from '@angular/core';
import { NgStyle } from '@angular/common';
import { MatDialogClose } from '@angular/material/dialog';

@Component({
  selector: 'app-whole-sale-cart',
  standalone: true,
  imports: [MatDialogClose, NgStyle],
  templateUrl: './whole-sale-cart.component.html',
  styleUrl: './whole-sale-cart.component.css'
})
export class WholeSaleCartComponent {

}
