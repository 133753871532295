<section
  class="w-100 sec-top-header breadcrumb-section"
  style="background-color: #dcdcdc"
>
  <div class="wrapper4 d-flex tyre-ryder-header">
    <div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb p-0 m-0 breadcrumb-bg-color">
          <li class="breadcrumb-item breadcrumb-headsize">
            <a class="primary-color" href="/wholeSale/products">WholeSale</a>
          </li>

          <li
            class="breadcrumb-item breadcrumb-headsize active"
            aria-current="page"
          >
            Order History
          </li>
        </ol>
      </nav>
    </div>
  </div>
</section>

<section class="w-100">
  <div class="">
    <div class="wrapper4 wholesale-fill">
      <div class="mb-3 mt-3">
        <h4 class="mb-3 filter-heading color-333">
          Search By: <small>Order number, item,order status and date</small>
        </h4>
        <form class="product-filter-section">
          <div class="row mb-2 filters-sm">
            <div class="col-lg-3 col-md-3 col-sm-12 pr-0">
              <mat-form-field class="d-block">
                <mat-label>Order Number</mat-label>
                <input
                  matInput
                  [formControl]="orderControl"
                  (keyup.enter)="getOrderFilter()"
                />
              </mat-form-field>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-12 pr-0">
              <mat-form-field class="d-block">
                <mat-label>Item</mat-label>
                <input
                  matInput
                  [formControl]="itemControl"
                  (keyup.enter)="getOrderFilter()"
                />
              </mat-form-field>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-12 pr-0">
              <mat-form-field class="d-block">
                <mat-label>Order Status</mat-label>
                <mat-select [formControl]="statusControl" multiple>
                  <mat-option value="13">New Orders</mat-option>
                  <mat-option value="19">Dispatch Orders</mat-option>
                  <mat-option value="8">Completed Orders</mat-option>
                  <mat-option value="21">Delivered Orders</mat-option>
                  <mat-option value="18">Returned Orders</mat-option>
                  <mat-option value="14">Out of Stock</mat-option>
                  <mat-option value="9">Cancelled</mat-option>
                  <mat-option value="22">Return Requested</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-12">
              <mat-form-field class="d-block">
                <mat-label>Payment Status</mat-label>
                <mat-select [formControl]="paymentControl" multiple>
                  <mat-option value="0">Pending</mat-option>
                  <mat-option value="1">AwaitingPayment</mat-option>
                  <mat-option value="2">Completed</mat-option>
                  <mat-option value="3">Failed</mat-option>
                  <mat-option value="4">Rejected</mat-option>
                  <mat-option value="5">Cancelled</mat-option>
                  <mat-option value="6">PartialComplete</mat-option>
                  <mat-option value="7">Authorized</mat-option>
                  <mat-option value="8">AuthorizedReversed</mat-option>
                  <mat-option value="9">AuthorizedFailed</mat-option>
                  <mat-option value="13">Refund</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row filters-sm">
            <div class="col-lg-4 col-md-4 col-sm-12">
              <mat-form-field class="d-block">
                <mat-label>Search Criteria</mat-label>
                <mat-select [formControl]="criteriaControl">
                  <mat-option value="1" [ariaSelected]="true">All</mat-option>
                  <mat-option value="2">Today</mat-option>
                  <mat-option value="4">Last 1 Week</mat-option>
                  <mat-option value="5">Last 1 Month</mat-option>
                  <mat-option value="9">Last 2 Month</mat-option>
                  <mat-option value="8">Custom Range</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div
              class="col-sm-12 col-lg-4 col-md-4"
              *ngIf="criteriaControl.value == 8"
            >
              <mat-form-field class="d-block">
                <mat-label>Enter a date range</mat-label>
                <mat-date-range-input
                  [rangePicker]="dateRangePicker"
                  [formGroup]="datePicker"
                >
                  <input
                    matStartDate
                    placeholder="Start date"
                    formControlName="start"
                    #dateRangeStart
                    (click)="dateRangePicker.open()"
                  />
                  <input
                    matEndDate
                    placeholder="End date"
                    formControlName="end"
                    #dateRangeEnd
                    (click)="dateRangePicker.open()"
                    (dateChange)="onDateChange(dateRangeStart, dateRangeEnd)"
                  />
                </mat-date-range-input>
                <mat-datepicker-toggle matPrefix [for]="dateRangePicker">
                </mat-datepicker-toggle>
                <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
              </mat-form-field>
            </div>
          </div>
        </form>
      </div>

      <div class="d-flex flex-wrap mt-3">
        <span
          class="badge badge-pill badge-light px-2 py-1 mr-2 mb-1"
          *ngIf="orderNumber != null && orderNumber != undefined"
          style="background-color: #e4e6ef"
          >Order Number : {{ orderNumber }}</span
        >

        <span
          class="badge badge-pill badge-light px-2 py-1 mr-2 mb-1"
          *ngIf="item != null && item != undefined"
          style="background-color: #e4e6ef"
          >Item : {{ item }}</span
        >

        <span
          class="badge badge-pill badge-light px-2 py-1 mr-2 mb-1"
          *ngIf="nowDate != null && nowDate != undefined"
          style="background-color: #e4e6ef"
          >Current Date :{{ nowDate }}</span
        >

        <span
          class="badge badge-pill badge-light px-2 py-1 mr-2 mb-1"
          *ngIf="status != null && status != undefined"
          style="background-color: #e4e6ef"
        >
          <span>Order Status: </span>
          <span *ngFor="let item of status; let i = index">
            <span *ngIf="item == 'Inprogress' || item == 13">New </span>
            <span *ngIf="item == 'Dispatched' || item == 19"> Dispatched </span>
            <span *ngIf="item == 'Completed' || item == 8"> Completed </span>
            <span *ngIf="item == 'Delivered' || item == 21"> Delivered </span>
            <span *ngIf="item == 'Returned' || item == 18"> Returned </span>
            <span *ngIf="item == 'Failed Pick' || item == 14">
              Out of Stock
            </span>
            <span *ngIf="item == 'Cancelled' || item == 9"> Cancelled </span>
            <span *ngIf="item == 'Delivered' || item == 22"> Cancelled </span>
            <span *ngIf="i < status.length - 1">,</span>
          </span>
        </span>
        <span
          class="badge badge-pill badge-light px-2 py-1 mr-2 mb-1"
          *ngIf="criteria != null && criteria != undefined"
          style="background-color: #e4e6ef"
        >
          <span *ngIf="criteria == 'All' || criteria == 1"
            >Search Criteria: All</span
          >
          <span *ngIf="criteria == 'Today' || criteria == 2"
            >Search Criteria: Today</span
          >
          <span *ngIf="criteria == 'Yesterday' || criteria == 3"
            >Search Criteria: Yesterday</span
          >
          <span *ngIf="criteria == 'Last7Days' || criteria == 4"
            >Search Criteria: Last Week</span
          >
          <span *ngIf="criteria == 'Last30Days' || criteria == 5"
            >Search Criteria: Last Month</span
          >
          <span *ngIf="criteria == 'Last60Month' || criteria == 9"
            >Search Criteria: Last 2 Month</span
          >
          <span *ngIf="criteria == 'CustomRange' || criteria == 8"
            >Search Criteria: Custom Range</span
          >
        </span>
        <span
          class="badge badge-pill badge-light px-2 py-1 mr-2 mb-1"
          *ngIf="startingDate != null && endingDate != null"
          style="background-color: #e4e6ef"
          >Date: {{ startingDate }} to {{ endingDate }}
        </span>
        <span
          class="badge badge-pill badge-light px-2 py-1 mr-2 mb-1"
          *ngIf="paymentStatus != null && paymentStatus != undefined"
          style="background-color: #e4e6ef"
        >
          <span>Payment Status: </span>
          <span *ngFor="let item of paymentStatus; let i = index">
            <span *ngIf="item == 0">Pending </span>
            <span *ngIf="item == 1">AwaitingPayment </span>
            <span *ngIf="item == 2">Completed </span>
            <span *ngIf="item == 3">Failed </span>
            <span *ngIf="item == 4">Rejected </span>
            <span *ngIf="item == 5">Cancelled </span>
            <span *ngIf="item == 6">PartialComplete </span>
            <span *ngIf="item == 7">Authorized </span>
            <span *ngIf="item == 8">AuthorizedReversed </span>
            <span *ngIf="item == 9">AuthorizedFailed </span>
            <span *ngIf="item == 13">Refund</span>
            <span *ngIf="i < paymentStatus.length - 1">,</span>
          </span></span
        >
      </div>
      <!-- <div class="d-flex flex-wrap mt-3">
          <span
            class="badge badge-pill badge-light px-2 py-1 mr-2 mb-1"
            style="background-color: #e4e6ef"
            *ngIf="width != null || width != undefined"
            >Width : {{ width }}
          </span>
          <span
            class="badge badge-pill badge-light px-2 py-1 mr-2 mb-1"
            style="background-color: #e4e6ef"
            *ngIf="ratio != null || ratio != undefined"
            >Ratio : {{ ratio }}
          </span>
          <span
            class="badge badge-pill badge-light px-2 py-1 mr-2 mb-1"
            style="background-color: #e4e6ef"
            *ngIf="diameter != null || diameter != undefined"
            >Diameter: {{ diameter }}
          </span>
          <span
            class="badge badge-pill badge-light px-2 py-1 mr-2 mb-1"
            style="background-color: #e4e6ef"
            *ngIf="tyre != null || tyre != undefined"
            >Brand: {{ tyreBrand }}
          </span>
          <span
            class="badge badge-pill badge-light px-2 py-1 mr-2 mb-1"
            style="background-color: #e4e6ef"
            *ngIf="partTrue == true"
            >Part No:{{ partNo }}
          </span>
        </div> -->
      <div class="mb-5 mt-3">
        <div class="" style="text-align: center">
          <button
            type="button"
            class="btn btn-circle btn-lg p-12 primary-bg-color"
            (click)="getOrderFilter()"
          >
            <span class="text-btn mr-2">Search</span>
            <i class="fas fa-search" style="color: #fff"></i>
          </button>
          <a
            class="b-primary-color fw-500 cursor-affect ml-2"
            style="font-size: 16px; font-weight: 700"
            (click)="clearFilter()"
          >
            Clear Filters
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <section class="float-left py-4 w-100">
    <div class="wrapper3 das-filters">
      <form>
        <div class="row no-gutters">
          <div class="col-sm-4 mb-2">
            <mat-form-field class="d-block">
              <mat-label>Order Number</mat-label>
              <input
                matInput
                [formControl]="orderControl"
                (keyup.enter)="getOrderFilter()"
              />
            </mat-form-field>
          </div>
          <div class="col-sm-4 mb-2">
            <mat-form-field class="d-block">
              <mat-label>Item</mat-label>
              <input
                matInput
                [formControl]="itemControl"
                (keyup.enter)="getOrderFilter()"
              />
            </mat-form-field>
          </div>
          <div class="col-sm-4 mb-2">
            <mat-form-field>
              <mat-label>Choose a date</mat-label>
              <input
                matInput
                [matDatepicker]="picker"
                [formControl]="currentControl"
                (dateChange)="EndDateChange($event)"
              />
              <mat-datepicker-toggle
                matIconSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-sm-4 mb-2">
            <mat-form-field class="d-block">
              <mat-label>Enter a date range</mat-label>
              <mat-date-range-input
                [rangePicker]="dateRangePicker"
                [formGroup]="datePicker"
              >
                <input
                  matStartDate
                  placeholder="Start date"
                  formControlName="start"
                  #dateRangeStart
                  (click)="dateRangePicker.open()"
                />
                <input
                  matEndDate
                  placeholder="End date"
                  formControlName="end"
                  #dateRangeEnd
                  (click)="dateRangePicker.open()"
                  (dateChange)="onDateChange(dateRangeStart, dateRangeEnd)"
                />
              </mat-date-range-input>
              <mat-datepicker-toggle matPrefix [for]="dateRangePicker">
              </mat-datepicker-toggle>
              <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
            </mat-form-field>
          </div>
          <div class="col-sm-4 mb-2 px-sm-2">
            <mat-form-field class="d-block">
              <mat-label>Order Status</mat-label>
              <mat-select [formControl]="statusControl">
                <mat-option value="13">New Orders</mat-option>
                <mat-option value="19">Dispatch Orders</mat-option>
                <mat-option value="8">Completed Orders</mat-option>
                <mat-option value="21">Delivered Orders</mat-option>
                <mat-option value="18">Returned Orders</mat-option>
                <mat-option value="14">Out of Stock</mat-option>
                <mat-option value="9">Cancelled</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-sm-4 mb-2 px-sm-2">
            <mat-form-field class="d-block">
              <mat-label>Payment Status</mat-label>
              <mat-select [formControl]="paymentControl">
                <mat-option value="0">Pending</mat-option>
                <mat-option value="1">AwaitingPayment</mat-option>
                <mat-option value="2">Completed</mat-option>
                <mat-option value="3">Failed</mat-option>
                <mat-option value="4">Rejected</mat-option>
                <mat-option value="5">Cancelled</mat-option>
                <mat-option value="6">PartialComplete</mat-option>
                <mat-option value="7">Authorized</mat-option>
                <mat-option value="8">AuthorizedReversed</mat-option>
                <mat-option value="9">AuthorizedFailed</mat-option>
                <mat-option value="13">Refund</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div>
          <span
            class="badge badge-pill badge-light px-2 py-1 mr-2 mb-1"
            *ngIf="orderNumber != null && orderNumber != undefined"
            style="background-color: #e4e6ef"
            >{{ orderNumber }}</span
          >
  
          <span
            class="badge badge-pill badge-light px-2 py-1 mr-2 mb-1"
            *ngIf="item != null && item != undefined"
            style="background-color: #e4e6ef"
            >{{ item }}</span
          >
  
          <span
            class="badge badge-pill badge-light px-2 py-1 mr-2 mb-1"
            *ngIf="nowDate != null && nowDate != undefined"
            style="background-color: #e4e6ef"
            >{{ nowDate }}</span
          >
  
          <span
            class="badge badge-pill badge-light px-2 py-1 mr-2 mb-1"
            *ngIf="startingDate != null && endingDate != null"
            style="background-color: #e4e6ef"
            >Date: {{ startingDate }} to {{ endingDate }}
          </span>
  
          <span
            class="badge badge-pill badge-light px-2 py-1 mr-2 mb-1"
            *ngIf="status != null && status != undefined"
            style="background-color: #e4e6ef"
          >
            <span *ngIf="status == 'Inprogress' || status == 13"
              >Order Status: New</span
            >
            <span *ngIf="status == 'Dispatched' || status == 19"
              >Order Status: AwaitingPayment</span
            >
            <span *ngIf="status == 'Completed' || status == 8"
              >Order Status: Completed</span
            >
            <span *ngIf="status == 'Delivered' || status == 21"
              >Order Status: Delivered</span
            >
            <span *ngIf="status == 'Returned' || status == 18"
              >Order Status: Returned</span
            >
            <span *ngIf="status == 'Failed Pick' || status == 14"
              >Order Status: Out of Stock</span
            >
            <span *ngIf="status == 'Cancelled' || status == 9"
              >Order Status: Cancelled</span
            >
          </span>
          <span
            class="badge badge-pill badge-light px-2 py-1 mr-2 mb-1"
            *ngIf="paymentStatus != null && paymentStatus != undefined"
            style="background-color: #e4e6ef"
          >
            <span *ngIf="paymentStatus == 0">Payment Status: Pending</span>
            <span *ngIf="paymentStatus == 1"
              >Payment Status: AwaitingPayment</span
            >
            <span *ngIf="paymentStatus == 2">Payment Status: Completed</span>
            <span *ngIf="paymentStatus == 3">Payment Status: Failed</span>
            <span *ngIf="paymentStatus == 4">Payment Status: Rejected</span>
            <span *ngIf="paymentStatus == 5">Payment Status: Cancelled</span>
            <span *ngIf="paymentStatus == 6"
              >Payment Status: PartialComplete</span
            >
            <span *ngIf="paymentStatus == 7">Payment Status: Authorized</span>
            <span *ngIf="paymentStatus == 8"
              >Payment Status: AuthorizedReversed</span
            >
            <span *ngIf="paymentStatus == 9"
              >Payment Status: AuthorizedFailed</span
            >
            <span *ngIf="paymentStatus == 13">Payment Status: Refund</span>
          </span>
        </div>
        <div class="row mt-4 text-center">
          <div class="col pr-4 text-right">
            <button
              type="button"
              class="btn btn-danger"
              (click)="getOrderFilter()"
            >
              <span class="text-btn mr-2">Search</span>
              <i class="fas fa-search" style="color: #fff"></i>
            </button>
            <a
              class="b-primary-color fw-500 cursor-affect ml-2"
              style="font-size: 16px; font-weight: 700"
              (click)="clearFilter()"
            >
              Clear Filters
            </a>
          </div>
        </div>
      </form>
    </div>
  </section> -->
<section class="w-100" style="overflow: hidden">
  <div class="wrapper4">
    <div class="">
      <div class="w-100 float-left d-md-block d-none">
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr class="align-middle">
                <th class="align-middle" scope="col">Order</th>
                <th class="align-middle" scope="col">
                  Order Total <br />
                  Items
                </th>
                <th class="align-middle" scope="col">Order Status</th>
                <th class="align-middle" scope="col">Payment Status</th>
                <th class="align-middle" scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let order of ordersList">
                <td>
                  <a
                    [href]="['/wholeSale/order-detail/' + order.id]"
                    class="color-B31B1B font-weight500"
                  >
                    {{ order.orderNumber }}
                  </a>
                  <br />
                  {{ order.orderDate | utcToLocal : "dd-MMM-yyyy" }} at
                  {{ order.orderDate | utcToLocal : "h:mm a" }}
                </td>
                <td>
                  <span class="color-B31B1B font-weight500">
                    ${{ order.totalPrice | number : "1.2-2" }}
                  </span>
                  <br />
                  {{ order.items }}x item(s)
                </td>
                <td class="align-middle">
                  <a
                    class="badge badge-success bg-light-success font-weight500 complete-btn text-white"
                    *ngIf="order.statusId == 13"
                    >New</a
                  >
                  <a
                    class="badge badge-info bg-light-success font-weight500 complete-btn text-white"
                    *ngIf="order.statusId == 19"
                    >{{ order.orderStatus }}</a
                  >
                  <a
                    class="badge badge-success bg-light-success font-weight500 complete-btn text-white"
                    *ngIf="order.statusId == 8 || order.statusId == 21"
                    >{{ order.orderStatus }}</a
                  >
                  <a
                    class="badge badge-danger bg-light-success font-weight500 complete-btn text-white"
                    *ngIf="order.statusId == 18"
                    >{{ order.orderStatus }}</a
                  >
                  <a
                    class="badge badge-danger bg-light-success font-weight500 complete-btn text-white"
                    *ngIf="order.statusId == 14"
                    >Out of Stock</a
                  >
                  <a
                    class="badge badge-danger bg-light-success font-weight500 complete-btn text-white"
                    *ngIf="order.statusId == 9"
                    >{{ order.orderStatus }}</a
                  >
                  <a
                    class="badge badge-danger bg-light-success font-weight500 complete-btn text-white"
                    *ngIf="order.statusId == 22"
                    >Returned Reuested</a
                  >
                </td>
                <td class="align-middle">
                  <a
                    class="badge badge-warning bg-light-warning font-weight500 complete-btn text-white"
                    >{{ order.paymentStatus }}</a
                  >
                </td>
                <td class="align-middle">
                  <a
                    style="cursor: pointer"
                    [href]="['/wholeSale/order-detail/' + order.id]"
                  >
                    <i class="fas fa-regular fa-eye primary-color"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        class="card card-flush d-block d-md-none card-shadow mb-3"
        data-aos="fade-up"
        data-aos-duration="600"
        *ngFor="let order of ordersList"
      >
        <!--begin::Card body-->
        <div class="card-body pt-3">
          <!-- <div> <span>Status</span></div> -->

          <div class="status-mb w-100 d-flex justify-content-between">
            <span class="status-heading">
              <a
                class="badge badge-success bg-light-success mr-2 order-card-sm px-2 py-1"
                >{{ order.orderStatus }}</a
              >
              <a
                class="badge badge-success bg-light-success order-card-sm px-2 py-1"
                >{{ order.paymentStatus }}</a
              >
            </span>
            <div class="">
              <div>
                <div class="dropdown show">
                  <a
                    class="info"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fas fa-ellipsis-v"></i>
                  </a>

                  <div
                    class="dropdown-menu dropdown-menu-right"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      class="dropdown-item"
                      style="cursor: pointer"
                      [href]="['/order-detail/' + order.id]"
                      >View</a
                    >
                    <!-- <a class="dropdown-item" href="#">Download</a> -->
                    <!-- <a class="dropdown-item" href="#">Print</a> -->
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <i class="fas fa-solid fa-lock fa-2x"></i>
          </div>
          <div class="order-position">
            <div>
              <span class="font-weight-bold color-B31B1B">Order Number</span>
            </div>
            <div>
              <a [href]="['/order-detail/' + order.id]" class="color-B31B1B">{{
                order.orderNumber
              }}</a
              ><br />
              <span class="card-data"
                >{{ order.orderDate | utcToLocal : "dd-MMM-yyyy" }} at
                {{ order.orderDate | utcToLocal : "h:mm a" }}</span
              >
            </div>
          </div>

          <!-- <div>
                  <i class="fas fa-regular fa-calendar fa-2x"></i>
                </div>
                <div class="order-position">
                  <div><span class="font-weight-bold color-B31B1B">Date</span></div>
                  <div>
                    <span class="card-data">{{ order.orderDate }} {{ order.orderDate }}</span>
                  </div>
                </div> -->

          <div>
            <i class="fas fa-regular fa-tag fa-2x"></i>
          </div>
          <div class="order-position">
            <div><span class="font-weight-bold color-B31B1B">Items</span></div>
            <div>
              <span class="card-data"
                >${{ order.totalPrice | number : "1.2-2" }}</span
              ><br />
              <span class="card-data">{{ order.items }}x item(s)</span>
            </div>
          </div>

          <!-- <div>
                  <i class="fas fa-solid fa-book fa-2x"></i>
                </div>
                <div class="order-position">
                  <div><span class="font-weight-bold color-B31B1B">Total</span></div>
                  <div>
                    <span class="card-data"
                      >${{ order.totalPrice | number : "1.2-2" }}</span
                    >
                  </div>
                </div> -->
        </div>
        <!--end::Customer address-->
      </div>
    </div>
  </div>
</section>
<section class="w-100 bg-F5F6F9 float-left t-padding-bottom">
  <div class="wrapper4">
    <div
      class="d-flex align-items-baseline pagination-block pb-3 justify-content-between flex-wrap"
    >
      <div>
        Showing Page <span class="base-color h6">{{ page }}</span> of
        <span class="base-color h6">{{ totalPages }}</span>
      </div>
      <div class="pagination p1">
        <ul>
          <button
            class="button_border_none cursors"
            (click)="onPreviousPageSet()"
            [disabled]="currentPageIndex === 0"
          >
            <li class="arrows-color">Previous</li>
          </button>

          <ng-container *ngFor="let pageNumber of pagesToShow()">
            <a
              [class.is-active]="page === pageNumber"
              (click)="onPageChange(pageNumber)"
              class="cursors"
            >
              <li>{{ pageNumber }}</li>
            </a>
          </ng-container>

          <button
            class="button_border_none cursors"
            (click)="onNextPageSet()"
            [disabled]="currentPageIndex === totalPages"
          >
            <li class="arrows-color">Next</li>
          </button>
        </ul>
      </div>
      <div>
        Total Data<span class="base-color h6"> ({{ total }})</span>
      </div>
    </div>
  </div>
</section>
