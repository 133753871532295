import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { SharedService } from '../../../../services/shared/shared.service';
import { AuthService, RefreshRequest } from '../../../../services/auth.service';

export const loggerInterceptor: HttpInterceptorFn = (req, next) => {

  var authreq: any = req;
  var service = inject(SharedService);
  var authService = inject(AuthService);
  var user: any = service._getUser();
  var currentToken: any = service._gettoken();

  if (currentToken != null) {

    var expDate = new Date(currentToken.expiresAt).getTime();
    var now = new Date().getTime();

    if (expDate <= now) {

      if (authService.getCallSentStatus() == false) {

        authService.setCallSentStatus(true);

        let reqUser: RefreshRequest = new RefreshRequest();
        reqUser.refreshToken = currentToken.refreshToken;
        reqUser.accessToken = currentToken.token

        console.log(" logger interceptor RefreshRequest --- " + reqUser)

        if (user.customerType == 2) {

          authService.refreshB2BAuth(reqUser).subscribe({
            next: (res: any) => {
              if (res.status.statusCode != 'ERROR') {
                authService.saveToken(res.response);
                authService.setCallSentStatus(false);
                currentToken = service._gettoken();
                authreq = req.clone({
                  headers: req.headers.set('Authorization', 'bearer ' + currentToken?.token)
                })
                return next(authreq);
              }
              else {
                authService.setCallSentStatus(false);
                authService.onB2BLogout();
                return next(authreq);

              }
            },
            error: (error: any) => {
              authService.setCallSentStatus(false);
              authService.onB2BLogout();
            }
          });

        }
        else {
          authService.refreshAuth(reqUser).subscribe({
            next: (res: any) => {

              if (res.body.status.statusCode != 'ERROR') {
                authService.saveToken(res.body.response);
                authService.setCallSentStatus(false);
                currentToken = service._gettoken();

                authreq = req.clone({
                  headers: req.headers.set('Authorization', 'bearer ' + currentToken?.token)
                })
                return next(authreq);

              }
              else {

                authService.setCallSentStatus(false);
                authService.onLogout();

                return next(authreq);

              }
            },
            error: (error: any) => {

              authService.setCallSentStatus(false);
              authService.onLogout();

              return next(authreq);


            }
          });
        }

      }
    }
    else {
      authreq = req.clone({
        headers: req.headers.set('Authorization', 'bearer ' + currentToken?.token)
      })
      return next(authreq);
    }
  }

  return next(authreq);
};
