<section
  class="w-100 sec-top-header breadcrumb-section"
  style="background-color: #dcdcdc"
>
  <div class="wrapper4 d-flex tyre-ryder-header">
    <div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb p-0 m-0 breadcrumb-bg-color">
          <li class="breadcrumb-item breadcrumb-headsize">
            <a class="primary-color" href="#">WholeSale</a>
          </li>
          <li class="breadcrumb-item breadcrumb-headsize">
            <a class="primary-color" href="#">Statements</a>
          </li>

          <li
            class="breadcrumb-item breadcrumb-headsize active"
            aria-current="page"
          >
            {{ invoiceDetail?.invoiceNumber }}
          </li>
        </ol>
      </nav>
    </div>
  </div>
</section>

<div class="w-100 4 logo-on-print">
  <div class="d-flex justify-content-center">
    <img src="assets/images/tyreryder_images/logo128x128.png" />
  </div>
</div>

<section class="w-100">
  <div class="wrapper4 mt-30px">
    <section>
      <div class="d-flex" style="gap: 20px">
        <div class="flex-grow-1">
          <div class="d-flex align-items-start mb-2">
            <a href="/invoice" class="hidden-on-print">
              <!--begin::Svg Icon | path: icons/duotune/arrows/arr064.svg-->
              <span class="svg-icon svg-icon-2 text-gray-primary">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="23"
                  fill="currentColor"
                  class="bi bi-arrow-left-square"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm11.5 5.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                  ></path>
                </svg>
              </span>
              <!--end::Svg Icon-->
            </a>
            <!-- <div class="export-icon ">
                            <i class="fas fa-sharp fa-regular fa-file-export fa-2x"></i>
                        </div> -->

            <div class="d-flex flex-column ml-2 mb-20px">
              <div class="font-weight-bold">
                #<span class="color">{{ invoiceDetail?.invoiceNumber }}</span>
                <span class="hidden-on-print badge badge-primary ml-2">{{
                  invoiceDetail?.status
                }}</span>
              </div>
              <div>
                <!-- Order Date: -->
                <small class="mb-4">
                  <b>
                    {{
                      invoiceDetail?.invoiceDate | utcToLocal : "dd-MMM-yyyy"
                    }}
                  </b>
                </small>
                <br />
                <span
                  *ngIf="
                    invoiceDetail?.invoiceFrom &&
                    invoiceDetail?.invoiceTo != null
                  "
                  ><span class="base-color h6 mr-2">Duration:</span
                  ><small
                    ><b
                      >{{
                        invoiceDetail?.invoiceFrom | utcToLocal : "dd-MMM-yyyy"
                      }}
                      to
                      {{
                        invoiceDetail?.invoiceTo | utcToLocal : "dd-MMM-yyyy"
                      }}</b
                    ></small
                  ></span
                >
              </div>
            </div>
          </div>
        </div>

        <div class="hidden-on-print">
          <div class="print-button">
            <a
              class="btn text-white btn-primary btn-sm"
              onclick="window.print()"
              >Print
            </a>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="w-100">
        <div class="wrapper4">
          <div class="row">
            <div class="col-12">
              <hr />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 p-0">
              <div
                class="card card-flush overflow-hidden"
                style="border-bottom: 0"
              >
                <div class="card-header pb-0">
                  <div class="card-title mb-0 pb-0">
                    <h5 class="base-color">Customer</h5>
                  </div>
                </div>
                <!--end::Card header-->
                <!--begin::Card body-->
                <div class="card-body pt-0 card-body1 pb-0 mb-3">
                  <div style="font-weight: bold">
                    <!-- Customer: -->
                    <i class="fas fa-regular fa-user mr-2"></i>
                    <span style="font-weight: 500"
                      >{{ invoiceDetail?.customer?.firstName }}
                      {{ invoiceDetail?.customer?.lastName }}</span
                    >
                  </div>

                  <div style="font-weight: bold">
                    <!-- <strong>Contact#:</strong> -->
                    <i class="fas fa-solid fa-phone mr-2"></i>
                    <span style="font-weight: 500">{{
                      invoiceDetail?.branch?.phoneNumber
                    }}</span>
                  </div>

                  <div style="font-weight: bold">
                    <i class="fas fa-solid fa-envelope mr-2"></i>
                    <span style="font-weight: 500">{{
                      invoiceDetail?.branch?.email
                    }}</span>
                  </div>
                  <!--end::Card body-->
                </div>
                <!--end::Customer address-->
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 p-0">
              <div
                class="card card-flush overflow-hidden mb-20px"
                style="border-bottom: 0"
              >
                <div class="card-header pb-0">
                  <div class="card-title mb-0 pb-0">
                    <h5>Billing Address</h5>
                  </div>
                </div>
                <!--end::Card header-->
                <!--begin::Card body-->
                <div
                  class="card-body pt-0 card-body1 pb-0"
                  style="font-weight: 500"
                  *ngIf="!invoiceDetail?.billingAddress"
                >
                  {{ invoiceDetail?.customer?.addressLine1 }},{{
                    invoiceDetail?.customer?.addressLine2
                  }}
                  <span *ngIf="invoiceDetail?.customer?.addressLine2">,</span
                  ><br />
                  {{ invoiceDetail?.customer?.city }},
                  {{ invoiceDetail?.customer?.state }}
                  {{ invoiceDetail?.customer?.zipCode }}<br />
                  {{ invoiceDetail?.customer?.country }}
                </div>
                <div
                  class="card-body pt-0 card-body1 pb-0"
                  style="font-weight: 500"
                  *ngIf="invoiceDetail?.billingAddress"
                >
                  {{ invoiceDetail?.branch?.addressLine1 }},{{
                    invoiceDetail?.branch?.addressLine2
                  }}
                  <span *ngIf="invoiceDetail?.shippingAddress?.addressLine2"
                    >,</span
                  ><br />
                  {{ invoiceDetail?.branch?.city }},
                  {{ invoiceDetail?.branch?.state }}
                  {{ invoiceDetail?.branch?.zipCode }}<br />
                  {{ invoiceDetail?.branch?.country }}
                </div>
                <!--end::Card body-->
              </div>
              <!--end::Customer address-->
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="w-100 mt-30px">
      <div class="wrapper4 w-sale">
        <div class="w-100 d-md-block d-none">
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr class="align-middle">
                  <th class="align-middle" scope="col">Order Number</th>
                  <th class="align-middle" scope="col">Order Date</th>
                  <th class="align-middle" scope="col">Order Total</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let invoice of invoiceDetail?.invoiceDetails">
                  <td>
                    <a
                      [href]="['/order-detail/' + invoice.orderId]"
                      class="color-B31B1B font-weight500"
                    >
                      {{ invoice.orderNumber }}
                    </a>
                  </td>
                  <td>
                    {{ invoice.orderDate | utcToLocal : "dd-MMM-yyyy" }}
                  </td>
                  <td>
                    <span class="color-B31B1B font-weight500">
                      ${{ invoice.orderTotal | number : "1.2-2" }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- Dual HTML for mobile responsive -->
        <div
          class="card card-flush d-block d-md-none card-shadow mb-3"
          data-aos="fade-up"
          data-aos-duration="600"
          *ngFor="let invoice of invoiceDetail?.invoiceDetails"
        >
          <!--begin::Card body-->
          <div class="card-body pt-3">
            <!-- <div> <span>Status</span></div> -->

            <div class="status-mb w-100 d-flex justify-content-between">
              <div class="">
                <div>
                  <div class="dropdown show">
                    <a
                      class="info"
                      href="#"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i class="fas fa-ellipsis-v"></i>
                    </a>

                    <div
                      class="dropdown-menu dropdown-menu-right"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a
                        class="dropdown-item"
                        style="cursor: pointer"
                        [href]="['/order-detail/' + invoice.orderId]"
                        >View</a
                      >
                      <!-- <a class="dropdown-item" href="#">Download</a> -->
                      <!-- <a class="dropdown-item" href="#">Print</a> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <i class="fas fa-solid fa-lock fa-2x"></i>
            </div>
            <div class="order-position">
              <div>
                <span class="font-weight-bold color-B31B1B"
                  >Invoice Number</span
                >
              </div>
              <div>
                <a
                  [href]="['/order-detail/' + invoice.id]"
                  class="color-B31B1B"
                >
                  {{ invoice.orderNumber }}
                </a>
                <br />
                <span class="card-data"
                  >{{ invoice.orderDate | utcToLocal : "dd-MMM-yyyy" }} at
                  {{ invoice.orderDate | utcToLocal : "h:mm a" }}</span
                >
              </div>
            </div>

            <!-- <div>
                        <i class="fas fa-regular fa-calendar fa-2x"></i>
                      </div>
                      <div class="order-position">
                        <div><span class="font-weight-bold color-B31B1B">Date</span></div>
                        <div>
                          <span class="card-data">{{ invoice.invoiceDate }} {{ invoice.invoiceDate }}</span>
                        </div>
                      </div> -->

            <div>
              <i class="fas fa-regular fa-tag fa-2x"></i>
            </div>
            <div class="order-position">
              <div>
                <span class="font-weight-bold color-B31B1B">Items</span>
              </div>
              <div>
                <span class="card-data"
                  >${{ invoice.orderTotal | number : "1.2-2" }}</span
                >
              </div>
            </div>

            <!-- <div>
                        <i class="fas fa-solid fa-book fa-2x"></i>
                      </div>
                      <div class="order-position">
                        <div><span class="font-weight-bold color-B31B1B">Total</span></div>
                        <div>
                          <span class="card-data"
                            >${{ order.totalPrice | number : "1.2-2" }}</span
                          >
                        </div>
                      </div> -->
          </div>
          <!--end::Customer address-->
        </div>
      </div>
    </div>

    <div class="w-100 place-con mb-4 wholesale-subtotal">
      <div class="wrapper4">
        <div class="total-price float-right">
          <table class="float-right total-data mt-4">
            <tbody>
              <tr>
                <td>Invoice Total</td>
                <td class="tright">
                  ${{ invoiceDetail?.totalAmount | number : "1.2-2" }}
                </td>
              </tr>

              <tr>
                <td>Paid</td>
                <td class="tright">
                  - ${{ invoiceDetail?.paidAmount | number : "1.2-2" }}
                </td>
              </tr>

              <tr>
                <td>Balance Total</td>
                <td class="color-Prices font-weight-bold">
                  ${{
                    invoiceDetail?.totalAmount - invoiceDetail?.paidAmount
                      | number : "1.2-2"
                  }}
                </td>
              </tr>
            </tbody>
          </table>
          <div style="clear: both"></div>
        </div>
        <div style="clear: both"></div>
      </div>
    </div>

    <div style="clear: both"></div>

    <div class="w-100 mt-30px pb-30px">
      <div
        class="wrapper4 w-sale payment"
        *ngIf="invoiceDetail?.invoicePayment != null"
      >
        <div class="card card-flush">
          <div class="card-header">
            <div class="card-title">
              <h3 class="base-color">Payment History</h3>
            </div>
          </div>
        </div>

        <div class="w-100 d-md-block d-none">
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr class="align-middle">
                  <th class="align-middle" scope="col">Payment Date</th>
                  <th class="align-middle" scope="col">Payment Source</th>
                  <th class="align-middle" scope="col">Paid Amount</th>
                  <th class="align-middle" scope="col">Payment Reference</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let payments of invoiceDetail?.invoicePayment">
                  <td>
                    {{ payments.paymentDate | utcToLocal : "dd-MMM-yyyy" }} at
                    {{ payments.paymentDate | utcToLocal : "h:mm a" }}
                  </td>
                  <td>{{ payments.paymentSource }}</td>
                  <td>
                    <span class="color-B31B1B font-weight500"
                      >${{ payments.paidAmount | number : "1.2-2" }}</span
                    >
                  </td>
                  <td>{{ payments.paymentReference }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- Dual HTML for mobile responsive -->
        <div
          class="card card-flush d-block d-md-none card-shadow mb-3"
          data-aos="fade-up"
          data-aos-duration="600"
          *ngFor="let payments of invoiceDetail?.invoiceDetails"
        >
          <!--begin::Card body-->
          <div class="card-body pt-3">
            <!-- <div> <span>Status</span></div> -->
            <div>
              <i class="fas fa-solid fa-lock fa-2x"></i>
            </div>
            <div class="order-position">
              <div>
                <span class="font-weight-bold color-B31B1B"
                  >Invoice Number</span
                >
              </div>
              <div>
                <span class="card-data">{{ payments.paymentSource }}</span>
                <span class="card-data"
                  >{{ payments.paymentDate | utcToLocal : "dd-MMM-yyyy" }} at
                  {{ payments.paymentDate | utcToLocal : "h:mm a" }}</span
                >
              </div>
            </div>

            <!-- <div>
                        <i class="fas fa-regular fa-calendar fa-2x"></i>
                      </div>
                      <div class="order-position">
                        <div><span class="font-weight-bold color-B31B1B">Date</span></div>
                        <div>
                          <span class="card-data">{{ invoice.invoiceDate }} {{ invoice.invoiceDate }}</span>
                        </div>
                      </div> -->

            <div>
              <i class="fas fa-regular fa-tag fa-2x"></i>
            </div>
            <div class="order-position">
              <div><span class="font-weight-bold color-B31B1B"></span></div>
              <div>
                <span class="card-data"
                  >${{ payments.paymentReference | number : "1.2-2" }}</span
                >
                <span class="card-data"
                  >${{ payments.paidAmount | number : "1.2-2" }}</span
                >
              </div>
            </div>

            <!-- <div>
                        <i class="fas fa-solid fa-book fa-2x"></i>
                      </div>
                      <div class="order-position">
                        <div><span class="font-weight-bold color-B31B1B">Total</span></div>
                        <div>
                          <span class="card-data"
                            >${{ order.totalPrice | number : "1.2-2" }}</span
                          >
                        </div>
                      </div> -->
          </div>
          <!--end::Customer address-->
        </div>
      </div>
    </div>
  </div>
</section>
