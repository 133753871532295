<!--BANNER SECTION-->
<section
  class="w-100 sec-top-header breadcrumb-section"
  style="background-color: #dcdcdc"
>
  <div class="wrapper4 d-flex tyre-ryder-header">
    <div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb p-0 m-0 breadcrumb-bg-color">
          <li class="breadcrumb-item breadcrumb-headsize">
            <a class="primary-color" href="/wholeSale/products">WholeSale</a>
          </li>

          <li
            class="breadcrumb-item breadcrumb-headsize active"
            aria-current="page"
          >
            Profile
          </li>
        </ol>
      </nav>
    </div>
  </div>
</section>

<div class="customer-profile-info">
  <section>
    <div class="wrapper my-5">
      <div class="row">
        <div class="col-12">
          <div class="job-tab">
            <ul class="nav nav-tabs justify-content-center mb-0" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  data-toggle="tab"
                  href="#Personal-info"
                  role="tab"
                  aria-controls="Personal-info"
                  aria-selected="true"
                  >Personal information</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  href="#address"
                  role="tab"
                  aria-controls="Address"
                  aria-selected="false"
                  >Address</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="tab-content my-5">
        <div
          class="tab-pane active"
          id="Personal-info"
          role="tabpanel"
          aria-labelledby="Personal-info-tab"
        >
          <form
            *ngIf="customerProfileUpdateForm"
            class="tab-form-con"
            [formGroup]="customerProfileUpdateForm"
            style="margin-bottom: 20px"
          >
            <label class="d-block">First Name</label>
            <input
              type="text"
              placeholder="First Name"
              formControlName="firstName"
              required
            />
            <div
              *ngIf="
                customerProfileUpdateForm.get('firstName')?.invalid &&
                (customerProfileUpdateForm.get('firstName')?.dirty ||
                  customerProfileUpdateForm.get('firstName')?.touched)
              "
              class="textMessage error-message"
            >
              <div
                class=""
                *ngIf="customerProfileUpdateForm.get('firstName')?.errors?.['required'] || customerProfileUpdateForm.get('firstName')?.errors?.['minlength'] || customerProfileUpdateForm.get('firstName')?.errors?.['pattern']"
              >
                Enter valid first name
              </div>
            </div>
            <label class="d-block">Last Name</label>
            <input
              type="text"
              placeholder="Last name"
              formControlName="lastName"
              required
            />
            <div
              *ngIf="
                customerProfileUpdateForm.get('lastName')?.invalid &&
                (customerProfileUpdateForm.get('lastName')?.dirty ||
                  customerProfileUpdateForm.get('lastName')?.touched)
              "
              class="textMessage error-message"
            >
              <div
                class=""
                *ngIf="customerProfileUpdateForm.get('lastName')?.errors?.['required'] || customerProfileUpdateForm.get('lastName')?.errors?.['minlength'] || customerProfileUpdateForm.get('lastName')?.errors?.['pattern']"
              >
                Enter valid first name
              </div>
            </div>
            <!-- <label class="d-block">Date of Birth</label>
                  <input
                    type="date"
                    placeholder="Date of birth"
                    formControlName="dateOfBirth"
                    required
                  />
                  <div
                    *ngIf="
                      getStatus(
                        customerProfileUpdateForm?.get('dateOfBirth'),
                        'required'
                      )
                    "
                    class="textMessage"
                    style="color: #b31b1b; font-size: 12px"
                  >
                    Enter date of birth
                  </div> -->

            <label class="d-block">email</label>
            <input
              type="email"
              placeholder="sample@gmail.com"
              formControlName="email"
              required
            />
            <div
              class="textMessage error-message"
              *ngIf="
                getStatus(
                  customerProfileUpdateForm?.get('email'),
                  'required'
                ) ||
                getStatus(customerProfileUpdateForm?.get('email'), 'pattern')
              "
            >
              Enter Valid Email
            </div>
            <label class="d-block">Phone Number</label>
            <input
              type="tel"
              placeholder="+92 300 8725885"
              formControlName="mobileNo"
              maxlength="10"
              required
            />
            <div
              class=""
              *ngIf="
                customerProfileUpdateForm.get('mobileNo')?.invalid &&
                (customerProfileUpdateForm.get('mobileNo')?.dirty ||
                  customerProfileUpdateForm.get('mobileNo')?.touched)
              "
              class="textMessage error-message"
            >
              <div
                class="textMessage error-message"
                *ngIf="customerProfileUpdateForm.get('mobileNo')?.errors?.['required'] || customerProfileUpdateForm.get('mobileNo')?.errors?.['minlength'] || customerProfileUpdateForm.get('mobileNo')?.errors?.['pattern']"
              >
                Enter valid Number
              </div>
            </div>
            <label class="d-block">Previous Password</label>
            <input
              type="password"
              placeholder="previous password"
              formControlName="previouspassword"
              minlength="8"
            />
            <div class="error">
              <div
                class="error-message"
                *ngIf="customerProfileUpdateForm.get('previouspassword')?.errors?.['minlength']"
              >
                Password should have at least 8 characters.
              </div>
            </div>
            <label class="d-block">New Password</label>
            <input
              type="password"
              placeholder="new password"
              formControlName="Newpassword"
              minlength="8"
            />
            <div class="error">
              <div
                class="error-message"
                *ngIf="customerProfileUpdateForm.get('Newpassword')?.errors?.['minlength']"
              >
                Password should have at least 8 characters.
              </div>
            </div>
            <label class="d-block">Confirm Password</label>
            <input
              type="password"
              placeholder="Confirm password"
              formControlName="Confirmpassword"
              minlength="8"
            />
            <div class="error">
              <div
                class="error-message"
                *ngIf="customerProfileUpdateForm.get('Confirmpassword')?.errors?.['minlength']"
              >
                Password should have at least 8 characters.
              </div>
            </div>
            <div class="mt-2 text-right">
              <button
                type="submit"
                class="save-btn text-uppercase form-save mt-2"
                (click)="submitProfileDetail()"
              >
                Save Profile
              </button>
            </div>
          </form>
        </div>

        <div
          class="tab-pane"
          id="address"
          role="tabpanel"
          aria-labelledby="Address"
        >
          <div class="tab-form-con mb-3">
            <div
              class="pay-box w-100"
              *ngFor="let address of addressList; let i = index"
            >
              <div class="payment-title">
                <input
                  type="radio"
                  [id]="address.id"
                  name="address.id"
                  value="{{ address.id }}"
                  (change)="onAddressChange(address, i)"
                  [checked]="address.checked"
                />
                <label for="address" class="mb-0 font-weight500"
                  >{{ address.addressLine1 }} , {{ address.addressLine2 }}
                  <span *ngIf="address.addressLine2">,</span>
                  {{ address.city }} {{ address.state }}
                  {{ address.zipCode }} ,{{ address.country }}</label
                >
              </div>
              <div class="payment-body" *ngIf="address.checked">
                <form class="shipping-form m-3" [formGroup]="addressInfoForm">
                  <div class="row">
                    <div class="col-md-6">
                      <input
                        type="text"
                        placeholder="Address Line 1"
                        formControlName="addressLine1"
                      />
                    </div>
                    <div class="col-md-6">
                      <input
                        type="text"
                        placeholder="Address Line 2"
                        formControlName="addressLine2"
                      />
                    </div>
                  </div>
                  <div class="">
                    <input
                      type="text"
                      placeholder="LandMark"
                      formControlName="landMark"
                    />
                  </div>

                  <div class="">
                    <select name="State" formControlName="state">
                      <option
                        [ngValue]="null"
                        selected
                        disabled
                        style="display: none"
                      >
                        State
                      </option>
                      <!-- <option selected>State</option> -->
                      <option
                        *ngFor="let state of sharedService.states"
                        [value]="state.value"
                      >
                        {{ state.text }}
                      </option>
                    </select>
                  </div>
                  <div class="personal-title">
                    <input
                      type="text"
                      placeholder="City"
                      formControlName="city"
                    />
                    <input
                      type="text"
                      placeholder="Postal Code Enter (0000)"
                      class="mr-0"
                      formControlName="zipCode"
                    />
                  </div>

                  <div class="">
                    <select name="Country" formControlName="country">
                      <option
                        [ngValue]="null"
                        style="display: none"
                        selected
                        disabled
                      >
                        Country
                      </option>
                      <option value="US">United States of America</option>
                    </select>
                  </div>
                </form>
                <div class="address-btn">
                  <button
                    type="button"
                    [disabled]="addressInfoForm?.invalid"
                    class="save-btn text-uppercase form-save btn-lg mt-3 setbutton"
                    (click)="updateSelectedAddress()"
                  >
                    Update
                  </button>
                  <button
                    type="button"
                    class="save-btn text-uppercase form-save btn-lg mt-3 setbutton"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>

            <div class="generic-btn my-4 text-right" style="cursor: pointer">
              <a class="px-4 py-3" (click)="showAddressform()">New address</a>
            </div>

            <form
              class="address-form new-form"
              *ngIf="showAddressForm"
              (ngSubmit)="submitForm()"
              [formGroup]="newAddressInfoForm"
            >
              <div class="row address form-group">
                <div class="col-6">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio1"
                      value="3"
                    />
                    <label
                      class="form-check-label radio-label"
                      for="inlineRadio1"
                      >Billing Address</label
                    >
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio2"
                      value="4"
                    />
                    <label
                      class="form-check-label radio-label"
                      for="inlineRadio2"
                      >Shipping Address</label
                    >
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-6 margin-sm">
                  <input
                    class="mb-0"
                    type="text"
                    placeholder="Address Line 1"
                    formControlName="addressLine1"
                    [ngClass]="{
                      'invalid-input':
                        newAddressInfoForm.get('addressLine1')?.invalid &&
                        newAddressInfoForm.get('addressLine1')?.touched
                    }"
                  />
                  <div
                    class="mb-2"
                    *ngIf="
                      newAddressInfoForm?.get('addressLine1')?.invalid &&
                      (newAddressInfoForm?.get('addressLine1')?.dirty ||
                        newAddressInfoForm?.get('addressLine1')?.touched)
                    "
                    class="error error-message"
                  >
                    <div
                      *ngIf="newAddressInfoForm?.get('addressLine1')?.errors?.['required']"
                      class=""
                    >
                      AddressLine1 is required
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <input
                    class="mb-0"
                    type="text"
                    placeholder="Address Line 2"
                    formControlName="addressLine2"
                    [ngClass]="{
                      'invalid-input':
                        newAddressInfoForm.get('addressLine2')?.invalid &&
                        newAddressInfoForm.get('addressLine2')?.touched
                    }"
                  />
                  <div
                    class="mb-2"
                    *ngIf="
                      newAddressInfoForm?.get('addressLine2')?.invalid &&
                      (newAddressInfoForm?.get('addressLine2')?.dirty ||
                        newAddressInfoForm?.get('addressLine2')?.touched)
                    "
                    class="error error-message"
                  >
                    <div
                      *ngIf="newAddressInfoForm?.get('addressLine2')?.errors?.['required']"
                      class=""
                    >
                      AddressLine2 is required
                    </div>
                  </div>
                </div>
              </div>

              <div class="mb-3">
                <input
                  class="mb-0"
                  type="text"
                  placeholder="LandMark"
                  formControlName="landMark"
                  [ngClass]="{
                    'invalid-input':
                      newAddressInfoForm.get('landMark')?.invalid &&
                      newAddressInfoForm.get('landMark')?.touched
                  }"
                />
                <div
                  class="mb-2"
                  *ngIf="
                    newAddressInfoForm?.get('landMark')?.invalid &&
                    (newAddressInfoForm?.get('landMark')?.dirty ||
                      newAddressInfoForm?.get('landMark')?.touched)
                  "
                  class="error error-message"
                >
                  <div
                    *ngIf="newAddressInfoForm?.get('landMark')?.errors?.['required']"
                    class=""
                  >
                    Landmark is required
                  </div>
                </div>
              </div>
              <select name="State" formControlName="state">
                <option
                  [ngValue]="null"
                  selected
                  disabled
                  style="display: none"
                >
                  State
                </option>
                <option
                  *ngFor="let state of sharedService.states"
                  [value]="state.value"
                >
                  {{ state.text }}
                </option>
              </select>
              <div class="row mb-3">
                <div class="col-md-6 margin-sm">
                  <input
                    class="mb-0"
                    type="text"
                    placeholder="City"
                    formControlName="city"
                    [ngClass]="{
                      'invalid-input':
                        newAddressInfoForm.get('city')?.invalid &&
                        newAddressInfoForm.get('city')?.touched
                    }"
                  />
                  <div
                    class="mb-2"
                    *ngIf="
                      newAddressInfoForm?.get('city')?.invalid &&
                      (newAddressInfoForm?.get('city')?.dirty ||
                        newAddressInfoForm?.get('city')?.touched)
                    "
                    class="error error-message"
                  >
                    <div
                      *ngIf="newAddressInfoForm?.get('city')?.errors?.['required']"
                      class=""
                    >
                      City is required
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <input
                    class="mb-0 mr-0"
                    type="text"
                    placeholder="Postal Code Enter"
                    formControlName="zipCode"
                    [ngClass]="{
                      'invalid-input':
                        newAddressInfoForm.get('zipCode')?.invalid &&
                        newAddressInfoForm.get('zipCode')?.touched
                    }"
                  />
                  <div
                    class="mb-2"
                    *ngIf="
                      newAddressInfoForm?.get('zipCode')?.invalid &&
                      (newAddressInfoForm?.get('zipCode')?.dirty ||
                        newAddressInfoForm?.get('zipCode')?.touched)
                    "
                    class="error error-message"
                  >
                    <div
                      *ngIf="newAddressInfoForm?.get('zipCode')?.errors?.['required']"
                      class=""
                    >
                      Postal Code is required
                    </div>
                    <div
                      *ngIf="newAddressInfoForm?.get('zipCode')?.errors?.['pattern']"
                      class=""
                    >
                      Postal Code should have number
                    </div>
                  </div>
                </div>
              </div>

              <select name="Country" formControlName="country">
                <option
                  [ngValue]="null"
                  style="display: none"
                  selected
                  disabled
                >
                  Country
                </option>
                <option value="US">United States of America</option>
              </select>

              <button
                type="submit"
                class="from-save"
                [disabled]="newAddressInfoForm.invalid"
              >
                Save Address
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
