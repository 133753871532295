<section
  class="w-100 sec-top-header breadcrumb-section"
  style="background-color: #dcdcdc"
>
  <div class="wrapper4 d-flex justify-content-between tyre-ryder-header">
    <!-- <div class="wh-nav">
      <nav class="navbar navbar-expand-sm justify">
        <ul class="navbar-nav nav-text text-white wholesale-nav">
          <li class="nav-item pt-0">
            <a class="nav-link text-white active" href="wholesale/listing"
              >Tires & Accessories</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link text-white cursor-affect"
              href="/wholesale/order"
            >
              Order History</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link text-white" href="wholesale/cart"
              >Cart
              <span class="warning-color">( {{ cartItems?.length }} )</span></a
            >
          </li>
        </ul>
      </nav>
    </div> -->
    <!-- <div class="sub-banner-txt text-center">
      <h3
        class="text-white text-uppercase font-weight700 mb-0"
        data-aos="fade-up"
        data-aos-duration="600"
      >
        Tires
      </h3>
    </div> -->

    <div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb p-0 m-0 breadcrumb-bg-color">
          <li class="breadcrumb-item breadcrumb-headsize">
            <a class="primary-color" href="/wholeSale/products">WholeSale</a>
          </li>
          
          <li
            class="breadcrumb-item breadcrumb-headsize active"
            aria-current="page"
          >
            Tires
          </li>
        </ol>
      </nav>
    </div>
    <div>
      <nav class="navbar navbar-expand-lg navbar-light p-0">
        <div class="">
          <div class="d-inline-block">
            <a
              id="cartDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              aria-disabled="cartList?.quantity === 0"
            >
              <img
                src="assets/images/tyreryder_images/shopping-cart-icon.png"
                class="img-fluid"
                alt="Your Image"
                style="width: 30px; height: 30px"
              />
              <!-- <span class="badge badge-primary">0</span> -->
              <span class="badge badge-primary" style="vertical-align: top">{{
                totalQuantity
              }}</span>
            </a>
            <!-- <div class="dropdown-cart" aria-labelledby="navbarDropdown" [ngStyle]="showCar?{} : {'display': 'none'}"> -->
            <div
              class="dropdown-menu dropdown-cart p-0"
              aria-labelledby="cartDropdown"
            >
              <div class="title">
                <h5>Shopping Cart</h5>
              </div>

              <div>
                <div *ngFor="let item of cartItems">
                  <div class="dropdown-cart-item" style="line-height: 20px">
                    <div class="cart-items clearfix">
                      <div class="cart-item-image">
                        <figure class="mb-0">
                          <img
                            *ngIf="
                              item?.catalogue?.image == null ||
                              item?.catalogue?.image == ''
                            "
                            src="/assets/images/tyreryder_images/img_not_available.jpg"
                            class="img-fluid"
                            alt="{{ item?.catalogue?.description }}"
                          />

                          <img
                            *ngIf="
                              item?.catalogue?.image != null &&
                              item?.catalogue?.image != ''
                            "
                            src="{{
                              item?.catalogue?.image.replace('lg-', 'sm-')
                            }}"
                            class="img-fluid"
                            alt="{{ item?.catalogue?.description }}"
                          />
                        </figure>
                      </div>
                      <div>
                        <div class="cart-item-des">
                          <span
                            class="cart-item-price dropdown-price-value"
                            style="color: #212529"
                            >{{ item?.catalogue?.description }}
                          </span>

                          <div class="item-price">
                            <div class="flex-grow-1">
                              <span
                                class="cart-item-price dropdown-price-value"
                                style="font-weight: 700"
                              >
                                {{
                                  item?.catalogue?.pricing?.salePrice +
                                    item?.catalogue?.pricing?.tax
                                    | number : "1.2-2"
                                }}
                              </span>
                              <span
                                class="cart-item-price dropdown-price-value"
                                style="font-weight: 700"
                                >X
                                {{ item.quantity }}
                              </span>
                            </div>
                            <div class="">
                              <span class="cart-item-quantity color-333">
                                ${{
                                  item?.quantity *
                                    (item?.catalogue?.pricing?.salePrice +
                                      (item?.catalogue?.pricing?.tax > 0
                                        ? item?.catalogue?.pricing?.tax
                                        : 0)) | number : "1.2-2"
                                }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cart-action clearfix t-generic-btn">
                <div class="">
                  <button class="button_border_none">
                    <a href="wholesale/cart">View Cart</a>
                  </button>
                  <span class="fright checkout-price"
                    >${{ totalAmount | number : "1.2-2" }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</section>

<section class="w-100">
  <div class="">
    <div class="wrapper4 wholesale-fill">
      <div class="mb-3 mt-3">
        <h4 class="mb-3 filter-heading color-333">
          Search By: <small>Size, Brand, PartNo and Tire</small>
        </h4>
        <form class="product-filter-section">
          <div class="row mb-2 filters-sm">
            <div class="col-lg-6 col-md-6 col-sm-12 pr-0">
              <mat-form-field>
                <mat-label>Search by PartNo and Name</mat-label>
                <input
                  matInput
                  [formControl]="searchControl"
                  (keyup.enter)="getVariantListingSearch()"
                />
              </mat-form-field>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-12 pr-0">
              <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Brand </mat-label>
                <input
                  type="text"
                  placeholder="Brand"
                  aria-label="Number"
                  matInput
                  [formControl]="brandControl"
                  (keyup.enter)="getVariantListingSearch()"
                  [matAutocomplete]="brandAuto"
                />
                <mat-autocomplete
                  autoActiveFirstOption
                  #brandAuto="matAutocomplete"
                  (optionSelected)="selectedBrand($event.option.value)"
                >
                  <mat-option
                    *ngFor="let brand of brandOption | async"
                    [value]="brand.name"
                  >
                    {{ brand.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
          <div class="row filters-sm">
            <div class="col-sm-12 col-lg-3 col-md-3 pr-0">
              <mat-form-field class="example-full-width" appearance="fill">
                <mat-label
                  >Tire Width
                  <span
                    class="none"
                    [ngClass]="{
                      'd-inline-block':
                        widthControl.invalid && widthControl.touched
                    }"
                  >
                  </span>
                </mat-label>
                <input
                  type="text"
                  placeholder="Tyre Width"
                  aria-label="Number"
                  matInput
                  [formControl]="widthControl"
                  (keyup.enter)="getVariantListingSearch()"
                  [matAutocomplete]="widthAuto"
                />
                <mat-autocomplete
                  autoActiveFirstOption
                  #widthAuto="matAutocomplete"
                  (optionSelected)="selectedWidth($event.option.value)"
                >
                  <mat-option
                    *ngFor="let width of widthOption | async"
                    [value]="width.name"
                  >
                    {{ width.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="col-sm-12 col-lg-3 col-md-3 pr-0">
              <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Aspect Ratio </mat-label>
                <input
                  type="text"
                  placeholder="Aspect Ratio"
                  aria-label="Number"
                  matInput
                  [formControl]="ratioControl"
                  (keyup.enter)="getVariantListingSearch()"
                  [matAutocomplete]="ratioAuto"
                />

                <mat-autocomplete
                  autoActiveFirstOption
                  #ratioAuto="matAutocomplete"
                  (optionSelected)="selectedRatio($event.option.value)"
                >
                  <mat-option
                    *ngFor="let ratio of ratioOption | async"
                    [value]="ratio.name"
                  >
                    {{ ratio.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="col-sm-12 col-lg-3 col-md-3 pr-0">
              <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Diameter </mat-label>
                <input
                  type="text"
                  placeholder="Diameter"
                  aria-label="Number"
                  matInput
                  [formControl]="diameterControl"
                  (keyup.enter)="getVariantListingSearch()"
                  [matAutocomplete]="diameterAuto"
                />

                <mat-autocomplete
                  autoActiveFirstOption
                  #diameterAuto="matAutocomplete"
                  (optionSelected)="selectedDiameter($event.option.value)"
                >
                  <mat-option
                    *ngFor="let diameter of diameterOption | async"
                    [value]="diameter.name"
                  >
                    {{ diameter.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
        </form>
      </div>
      <div class="d-flex flex-wrap mt-3">
        <span
          class="badge badge-pill badge-light px-2 py-1 mr-2 mb-1"
          style="background-color: #e4e6ef"
          *ngIf="width != null || width != undefined"
          >Width : {{ width }}
        </span>
        <span
          class="badge badge-pill badge-light px-2 py-1 mr-2 mb-1"
          style="background-color: #e4e6ef"
          *ngIf="ratio != null || ratio != undefined"
          >Ratio : {{ ratio }}
        </span>
        <span
          class="badge badge-pill badge-light px-2 py-1 mr-2 mb-1"
          style="background-color: #e4e6ef"
          *ngIf="diameter != null || diameter != undefined"
          >Diameter: {{ diameter }}
        </span>
        <span
          class="badge badge-pill badge-light px-2 py-1 mr-2 mb-1"
          style="background-color: #e4e6ef"
          *ngIf="tyre != null || tyre != undefined"
          >Brand: {{ tyreBrand }}
        </span>
        <span
          class="badge badge-pill badge-light px-2 py-1 mr-2 mb-1"
          style="background-color: #e4e6ef"
          *ngIf="partTrue == true"
          >Part No:{{ partNo }}
        </span>
      </div>
      <div class="row mb-3">
        <div class="col-9 pr-0" style="text-align: end">
          <button
            type="button"
            class="btn btn-circle btn-lg p-12 primary-bg-color"
            (click)="getVariantListingSearch()"
          >
            <span class="text-btn mr-2">Search</span>
            <i class="fas fa-search" style="color: #fff"></i>
          </button>
          <a
            class="b-primary-color fw-500 cursor-affect ml-2"
            style="font-size: 16px; font-weight: 700"
            (click)="clearform()"
          >
            Clear Filters
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="w-100 mt-20px padding-bottom d-none d-md-block d-lg-block">
  <div class="wrapper4 wholesale-listing">
    <div class="table-responsive">
      <table class="table table-hover">
        <thead class="text-center">
          <tr>
            <th scope="col" class="align-middle">Part# | Tire Description</th>
            <th
              scope="col"
              class="align-middle d-flex justify-content-between"
              (click)="
                sortColumn = 'salePrice';
                sortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
                sort()
              "
            >
              <span>Price </span>
              <span class="d-flex flex-column">
                <i
                  class="fas fa-angle-up f-18"
                  [ngClass]="sortDirection === 'desc' ? 'text-success' : ''"
                ></i>
                <i
                  class="fas fa-angle-down f-18"
                  [ngClass]="sortDirection === 'asc' ? 'text-danger' : ''"
                ></i>
              </span>
            </th>
            <th scope="col" class="align-middle">FET</th>
            <th scope="col" class="align-middle">Avail Qty</th>
            <th scope="col" class="align-middle">Buy Qty</th>
            <th scope="col" class="align-middle"></th>
            <th scope="col" class="align-middle"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of variantList; let i = index">
            <td>
              <div class="item-img d-inline-block">
                <img
                  *ngIf="item?.imageUrl == null || item?.imageUrl == ''"
                  src="assets/images/tyreryder_images/img_not_available.jpg"
                  class="img-fluid"
                  alt="{{ item?.additionalDescription }}"
                />

                <img
                  *ngIf="item?.imageUrl != null && item?.imageUrl != ''"
                  src="{{ item?.imageUrl.replace('lg-', 'sm-') }}"
                  class="img-fluid"
                  alt="{{ item?.additionalDescription }}"
                />
              </div>
              <div class="d-inline-block ml-3">
                <b>{{ item.partNo }}</b> <br />
                <h6 class="base-color" *ngIf="item.manufacturePartNo != null">
                  MPN:{{ item.manufacturePartNo }}
                </h6>
                <h6
                  class="base-color"
                  *ngIf="item.alternativeManufacturePartNo != null"
                >
                  A-MPN:{{ item.alternativeManufacturePartNo }}
                </h6>
                {{ item.additionalDescription }}
              </div>
            </td>
            <td class="align-middle primary-color font-weight-bold text-center">
              ${{ item.salePrice | number : "1.2-2" }}
            </td>
            <td class="align-middle font-weight-bold text-center">
              ${{ item.tax | number : "1.2-2" }}
            </td>
            <td class="align-middle font-weight-bold text-center">
              {{ item.availableStock }}
            </td>
            <td class="align-middle text-center">
              <div class="input-width d-inline-block">
                <input
                  class="form-control input-sm"
                  minlength="1"
                  ng-minlength="1"
                  [formControl]="item.cartItemQuantity"
                  type="number"
                  min="1"
                  (keyup.enter)="AddtoCart(item)"
                />
              </div>
            </td>
            <td class="align-middle text-center">
              <div
                class="d-inline-block"
                style="cursor: pointer; vertical-align: middle !important"
              >
                <a
                  class="cursor-affect"
                  *ngIf="item.availableStock > 0"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Add to cart"
                  (click)="AddtoCart(item)"
                >
                  <img
                    src="assets/images/tyreryder_images/shopping-cart-icon.png"
                    class="img-fluid"
                    alt="Your Image"
                    style="width: 28px; height: 28px"
                  />
                  <!-- <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-bag primary-color"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z"
                      />
                    </svg> -->
                </a>
              </div>
            </td>
            <td class="align-middle text-center">
              <i
                class="fas fa-regular fa-check"
                *ngIf="isInCart(item.variantId)"
                style="color: #14a44d"
              ></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</section>

<!-- code for mobile only -->
<section class="w-100 padding-top padding-bottom d-sm-block d-md-none">
  <div class="wrapper4 wholesale-card-sm">
    <div
      class="card box-shadow mb-2"
      *ngFor="let item of variantList; let i = index"
    >
      <div class="card-body">
        <div class="d-flex">
          <div class="item-img-sm">
            <img
              src="assets/images/tierryder-listingimage.png"
              alt="image not shown"
            />
          </div>
          <div>
            <span class="item-name-heading"> {{ item.partNo }} </span>
            <p class="mb-2 item-description">
              {{ item.additionalDescription }}
            </p>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-6">
            <span class="mr-2 label-heading">Price:</span>
            <span class="pricing-heading primary-color"
              >${{ item.salePrice | number : "1.2-2" }}</span
            >
          </div>
          <div class="col-6">
            <span class="mr-2 label-heading">Avail:</span>
            <span class="pricing-heading">{{ item.availableStock }}</span>
          </div>
        </div>
        <div class="row">
          <div class="Fet-price col-6">
            <span class="mr-2 label-heading">FET:</span>
            <span class="pricing-heading primary-color"
              >${{ item.tax | number : "1.2-2" }}</span
            >
          </div>
          <div class="col-6">
            <div class="d-flex">
              <span class="mr-2 label-heading">Buy:</span>
              <span class="input-width-sm">
                <input
                  class="form-control input-sm"
                  minlength="1"
                  ng-minlength="1"
                  [formControl]="item.cartItemQuantity"
                  type="number"
                  min="1"
                />
              </span>
            </div>
          </div>
        </div>
        <div class="row cart-img mt-1">
          <div class="col-6"></div>
          <div class="col-6" style="text-align: left">
            <a
              (click)="AddtoCart(item)"
              *ngIf="item.availableStock > 0"
              class="add-cart-heading color-B31B1B mr-3"
              >Add to Cart</a
            >
            <i
              class="fas fa-regular fa-check"
              *ngIf="isInCart(item.variantId)"
              style="color: #14a44d"
            ></i>
            <!-- <img src="../assets/images/lock-ico (click)="AddtoCart(item)"n.png" alt="lock-icon" /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="w-100 bg-F5F6F9 float-left">
  <div class="wrapper4">
    <div
      class="d-flex align-items-baseline pagination-block pb-3 justify-content-between flex-wrap"
    >
      <div>
        Showing Page <span class="base-color h6">{{ page }}</span> of
        <span class="base-color h6">{{ totalPages }}</span>
      </div>
      <div class="pagination p1">
        <ul>
          <button
            class="button_border_none cursors"
            (click)="onPreviousPageSet()"
            [disabled]="currentPageIndex === 0"
          >
            <li class="arrows-color">Previous</li>
          </button>

          <ng-container *ngFor="let pageNumber of pagesToShow()">
            <a
              [class.is-active]="page === pageNumber"
              (click)="onPageChange(pageNumber)"
              class="cursors"
            >
              <li>{{ pageNumber }}</li>
            </a>
          </ng-container>

          <button
            class="button_border_none cursors"
            (click)="onNextPageSet()"
            [disabled]="currentPageIndex === totalPages"
          >
            <li class="arrows-color">Next</li>
          </button>
        </ul>
      </div>
      <div>
        Total Data<span class="base-color h6"> ({{ total }})</span>
      </div>
    </div>
  </div>
</section>
