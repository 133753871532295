import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { DecimalPipe, NgFor, NgIf, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { B2bService } from '../../../services/b2b/b2b.service';
import { SharedService } from '../../../services/shared/shared.service';
import { ShopService } from '../../../services/shop/shop.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    RouterLink,
    DecimalPipe
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent {
  loggedIn: boolean = false;
  user: any;
  cartList: any[] = [];
  totalAmount: number = 0;
  firstname: any;
  totalQuantity: any = 0;
  window: any
  isBrowser: any
  isServer: any
  wholeSale: boolean = false;
  netAmount: number = 0
  discountAmount: number = 0
  taxAmount: number = 0

  constructor(private router: Router,
    public sharedService: SharedService,
    private shopService: ShopService,
    private b2bService: B2bService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.isServer = isPlatformServer(platformId);
  }

  // this.router.routeReuseStrategy.shouldReuseRoute = () => false;



  ngOnInit(): void {

    if (this.isBrowser) {
      this.user = localStorage.getItem('user');
      if (this.user) {
        this.user = JSON.parse(this.user);
        if (this.user.customerType == 2) {
          this.wholeSale = true;
          this.loggedIn = true;
          this.getB2bCart();
        }
      }
    }
  }


  getB2bCart() {
    if (this.isBrowser) {
      this.totalAmount = 0
      this.netAmount = 0
      this.discountAmount = 0
      this.taxAmount=0
      this.b2bService.GetShoppingCart(this.user.userId).subscribe({
        next: (res: any) => {
          this.cartList = res.body.response
          this.cartList.forEach(element => {
            element.catalogue.totalAmount = element.quantity * (element.catalogue?.pricing.discountPrice > 0
              ? element.catalogue?.pricing.discountPrice
              : element.catalogue?.pricing.salePrice);
            this.taxAmount = element.quantity * (element.catalogue?.pricing.tax > 0
                ? element.catalogue?.pricing.tax
                : element.catalogue?.pricing.tax);
            this.netAmount = this.netAmount + (element.catalogue?.pricing.salePrice * element.quantity);
            if (element.catalogue?.pricing.discountPrice > 0) {
              this.discountAmount = this.discountAmount + (element.catalogue?.pricing.salePrice - element.catalogue?.pricing.discountPrice) * element.quantity
            }
          });
          this.totalAmount = this.netAmount - this.discountAmount + this.taxAmount;

          this.calculateTotalQuantity();
          // console.log(this.cartList);
          this.shopService.setb2bCartProductsList(this.cartList);
          this.getCart();
          
        },
        error: (error: any) => { }
      });
    }

  }

  onLogout() {
    if (this.isBrowser) {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      this.loggedIn = false;
      this.router.navigateByUrl('/login', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/']); // navigate to same route
      });
    }
  }

  getCart() {
    if (this.isBrowser) {
      
      this.shopService.b2bproductsList$.subscribe((value) => {
        this.cartList = value;
        this.taxAmount=0
        this.netAmount=0
        this.discountAmount=0
        this.cartList.forEach(element => {
          element.catalogue.totalAmount = element.quantity * (element.catalogue?.pricing.discountPrice > 0 ? element.catalogue?.pricing.discountPrice : element.catalogue?.pricing.salePrice)
         if(element.catalogue?.pricing?.tax)
          this.taxAmount = this.taxAmount+ element.quantity * (element.catalogue?.pricing?.tax > 0
            ? element.catalogue?.pricing?.tax
            : element.catalogue?.pricing?.tax);
          this.netAmount = this.netAmount + (element.catalogue?.pricing.salePrice * element.quantity);
        if (element.catalogue?.pricing.discountPrice > 0) {
          this.discountAmount = this.discountAmount + (element.catalogue?.pricing.salePrice - element.catalogue?.pricing.discountPrice) * element.quantity
        }
        });
        this.calculateTotalQuantity()
      });

      this.shopService.b2btotalAmount$.subscribe((value) => {

        this.totalAmount = value- this.discountAmount + this.taxAmount;
      });
    }

  }

  calculateTotalQuantity() {
    this.totalQuantity = 0;
    for (var i = 0; i < this.cartList.length; i++) {
      this.totalQuantity = this.totalQuantity + this.cartList[i].quantity;
    }
  }

}

