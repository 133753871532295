<!-- Message Modal -->
<div class="modal" tabindex="-1" id="message_modalBox" role="dialog" [ngStyle]="{'display': 'block'}"
    aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-md">
        <div class="modal-body">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="myModalLabel">{{header}}</h4>
                    <a class="btn close" [mat-dialog-close]="true" data-dismiss="modal" aria-hidden="true">&times;</a>
                </div>
                <div class="modal-body">
                    <p id="modal-message">{{message}}</p>
                </div>
            </div>
        </div>
    </div>
</div>