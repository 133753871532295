import { NgStyle } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogClose } from '@angular/material/dialog';


@Component({
  selector: 'app-message-box-component',
  standalone: true,
  imports: [NgStyle,MatDialogClose],
  templateUrl: './message-box-component.component.html',
  styleUrl: './message-box-component.component.css'
})
export class MessageBoxComponentComponent {
  message: '';
  header:''

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.message = data?.msg?? "This is the msg";
    this.header = data?.head?? "Message";
   }

}
