import { CommonModule, DecimalPipe, NgClass, NgFor, NgIf, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { B2bService } from '../../../services/b2b/b2b.service';
import { SessionRequest, SharedService } from '../../../services/shared/shared.service';
import { ShopService } from '../../../services/shop/shop.service';
import { environment } from '../../core/enviroment/environment';

@Component({
  selector: 'app-wholesale-checkout',
  standalone: true,
  imports: [NgFor, DecimalPipe, MatButtonModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    CommonModule,
    MatExpansionModule,
    NgIf,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    NgClass],
  templateUrl: './wholesale-checkout.component.html',
  styleUrl: './wholesale-checkout.component.css'
})
export class WholesaleCheckoutComponent {
  paymentMethod = 'Credit Account'
  isBrowser: any;
  isServer: any;
  userDetail: any;
  cartItems: any;
  addressList: any;
  selectedAddress: any;
  showAddressForm: boolean = true;
  totalAmount: any;
  subTotalAmount: any;
  totalTax: any;
  commissionAmount: any;
  paymentMethodList: any;
  NewShippingAddressForm: FormGroup | any;
  userAddress: FormGroup | any;
  addBillingAddressInfoForm: FormGroup | any;
  lookup: any;
  edit: boolean = true;
  showCard: boolean = false;
  showBillingForm: boolean = false;
  showOrderButton: boolean = true;
  showLoading: boolean = false;
  disbled: boolean = false;
  elements: any;
  sessionToken: any;
  paymentformData: any
  achcheckoutButton: any = false;
  sessionStripe: SessionRequest = new SessionRequest();
  selectedPaymentId: any;
  stripe: any = null;
  userid: any;
  isLoading: boolean = true;
  errorMessage: any = undefined;
  showCardInfo: boolean = false;
  form_Payment = this.formBuilder.group({
    secondCtrl: ['', Validators.required],
  });

  constructor(private router: Router,
    private shopService: ShopService, public sharedService: SharedService, private formBuilder: FormBuilder,
    private b2bService: B2bService, @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.isServer = isPlatformServer(platformId);

    this.totalAmount = 0.00;
    this.subTotalAmount = 0.00;
    this.commissionAmount = 0.00;

  }

  ngOnInit() {
    if (this.isBrowser) {
      this.userDetail = localStorage.getItem('user')
      this.userDetail = JSON.parse(this.userDetail);
      this.sessionStripe.PM_CustomerId = this.userDetail.userId?.toString();
      if (this.userDetail) {
        this.showAddressForm = false;
        this.userid = this.userDetail.userId;
        this.showCardInfo=this.userDetail?.isCreditAccount;
        this.sessionStripe.PM_CustomerId = this.userDetail.userId?.toString();
        this.getAllAddress()
      }
    }
    this.getCart();
    this.initform();
    this.getlookUp();
    this.invokeStripe();
    this.companyPaymentMethods();
  }

  showNewShippingAddressForm() {
    // if (this.selectedAddress) {
    //   this.addressList[this.selectedAddress.index].checked = false;
    // }

    this.NewShippingAddressForm?.reset();
    this.showAddressForm = true;
    this.selectedAddress = undefined;
    this.userAddress = this.selectedAddress;
  }

  onAddressChange(e: any, index: any) {
    this.addressList[index].checked = true;
    this.addressList[index].index = index;
    this.selectedAddress = this.addressList[index];

    this.showAddressForm = false;
    this.userAddress = this.selectedAddress;
    this.NewShippingAddressForm?.reset();


  }

  getAllAddress() {
    if (this.userid) {
      // this.sharedService.setShowLoading(true);
      this.shopService.getAllAddress(this.userid).subscribe({
        next: (res: any) => {
          if (res.status === 200) {
            this.addressList = res.body.response;
            if (this.addressList.length == 0) {
              this.showAddressForm = true;
            }
            this.selectedAddress = this.addressList[0];
            // this.sharedService.setShowLoading(false);
          } else {
            // this.sharedService.setShowLoading(false);
          }
        },
        error: (error: any) => {
          // this.sharedService.setShowLoading(false);
        },
      });
    }
  }

  getlookUp() {
    if (this.isBrowser) {
      this.shopService.getLookup(this.userDetail.userid).subscribe({
        next: (res: any) => {
          if (res.status === 200) {
            this.lookup = res.body.response
          }
        }
      });
    }
  }

  GETNEW_SESSIONSTRIPE() {
    this.shopService.GETNEW_SESSIONSTRIPE(this.sessionStripe).subscribe({
      next: (res: any) => {
        if (res.status === 200) {
          this.sessionToken = res.body.response;
          this.initialize();

        } else {
          // this.sharedService.setShowLoading(false);
        }
      },
      error: (error: any) => {
        // this.sharedService.setShowLoading(false);
      },
    })
  }

  showbilling(show: boolean) {
    this.showBillingForm = show;
  }

  allFormValidation(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control: any) => {
      control.markAsTouched();
    });
  }

  createOrderPayment() {
    this.showLoading = true
    if (
      (this.showAddressForm == true && this.NewShippingAddressForm.valid)
      || (this.showAddressForm == false && this.selectedAddress != undefined)
    ) {
      this.elements.submit()
        .then((result: any) => {
          if (result?.error == null) {
            this.showLoading = true
            this.b2bService.orderCheckout(this.mapOrderData()).subscribe({
              next: (res: any) => {
                if (res.status == 200) {
                  this.paymentformData = res.body.response;
                  this.makePayment();
                  this.clearCart();

                  setTimeout(() => {
                    this.router.navigate(['/thank-you', this.paymentformData.id])      // handleSuccess();
                  }, 300);

                }
              },
              error: (error: any) => {
              },
            });
          }
        });
    }
    else {
      this.allFormValidation(this.NewShippingAddressForm);
      if (this.showBillingForm == true) {
        this.allFormValidation(this.addBillingAddressInfoForm);
      }
    }
    this.showLoading = false;
  }

  createOrder() {
    if ((this.showAddressForm == true && this.NewShippingAddressForm.valid)
    || (this.showAddressForm == false && this.selectedAddress != undefined)
  ) {
      this.disbled = true;
      this.showLoading = true;
      this.b2bService.orderCheckout(this.mapOrderData()).subscribe({
        next: (res: any) => {
          this.disbled = true;
          this.showLoading = true
          if (res.status == 200) {
            this.paymentformData = res.body.response;
            this.clearCart();
            setTimeout(() => {
              this.router.navigate(['/thank-you', this.paymentformData.id])
              this.showLoading = false;
              this.disbled = false;
              // handleSuccess();
            }, 300);
          }
        },
        error: (error: any) => {
          this.showLoading = false;
          this.disbled = false;
          this.sharedService.showGeneralMsgModal(error.error.Message, 'Something when wrong!');
          this.errorMessage = error.error.Message
        },
      });
    }
    else {
      this.allFormValidation(this.NewShippingAddressForm);
      if (this.showBillingForm == true) {
        this.allFormValidation(this.addBillingAddressInfoForm);
        this.showLoading = false;
        this.disbled = false;
      }
    }
  }

  makePayment() {
    const { error, paymentIntent } = this.stripe.confirmPayment({
      elements: this.elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: "http://localhost:4200/cart/checkout",
        // receipt_email: this.addGuestInfoForm?.controls['email'].value,
      },

      redirect: "if_required",

    }).then((result: any) => {

      if (result.error) {
        // Inform the customer that there was an error.
      }
      else {
        this.clearCart();

        setTimeout(() => {
          this.showLoading = false
          this.router.navigate(['/thank-you', this.paymentformData.id])
        }, 1000);
      }
    })
  }

  clearCart() {
    this.sharedService.clearCart();
    var user: any = localStorage.getItem('user');
    user = JSON.parse(user);
    this.b2bService.EmptyShoppingCart(user?.userId).subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
            this.cartItems = [];
            this.shopService.setb2bCartProductsList(this.cartItems)
          } else {
          }
        },
        error: (error: any) => {

        }
      });
  }

  BankingType(isBankWire: boolean) {
    if (this.isBrowser) {
      if (isBankWire == true) {
        this.achcheckoutButton = true
        this.showCard = false
        this.paymentMethod = 'Credit Account'
        this.CalculateAmount();
        this.selectPaymentMethod()
      }
      else {
        isBankWire == false
        this.achcheckoutButton = false
        this.showCard = true
        this.paymentMethod = 'Card'
        this.CalculateAmount();
        this.selectPaymentMethod()
        this.GETNEW_SESSIONSTRIPE()
      }
    }
  }

  selectPaymentMethod() {
    if (this.isBrowser) {
      this.selectedPaymentId = this.paymentMethodList.filter((x: any) => x.name == this.paymentMethod)[0].id;
    }
  }

  companyPaymentMethods() {
    if (this.isBrowser) {
      this.shopService.getSiteAvaliablePaymentMethod().subscribe({
        next: (res: any) => {
          if (res.status === 200) {
            this.paymentMethodList = res.body.response;
            this.selectPaymentMethod();
          } else {
            // this.sharedService.setShowLoading(false);
          }
        },
        error: (error: any) => {
          // this.sharedService.setShowLoading(false);
        },
      });
    }
  }

  invokeStripe() {
    try {
      // if (!window.document.getElementById('stripe-script')) {
      const script = window.document.createElement('script');
      script.id = 'stripe-script';
      script.type = 'text/javascript';
      script.src = 'https://js.stripe.com/v3/';
      script.onload = () => {
        this.stripe = (<any>window).Stripe(environment.stripeKey);
      };
      window.document.body.appendChild(script);
      // }
    }
    catch (error: any) {

    }
  }

  initialize() {
    try {
      // const clientSecret = 'pi_3OGobrC56ELTN1Po0ZFa0O9J_secret_axQH7FjW3FeZ9JzhqFf6ywlPt';
      const clientSecret = this.sessionToken.pM_ClientSecret;
      const appearance = {
        theme: 'stripe',
      };
      const options = {
        layout: {
          type: 'accordion',
          defaultCollapsed: false,
          radios: true,
          spacedAccordionItems: false
        }
      };
      this.elements = this.stripe.elements({ appearance, clientSecret });
      const linkAuthenticationElement = this.elements.create('linkAuthentication', options);
      linkAuthenticationElement.mount('#link-authentication-element');
      linkAuthenticationElement.on('change', (event: any) => {
        // emailAddress = event.value.email;
      });

      const paymentElementOptions = {
        layout: 'tabs',
      };

      const paymentElement = this.elements.create(
        'payment',
        paymentElementOptions
      );

      paymentElement.mount('#payment-element');
      // setTimeout(() => {
      this.showOrderButton = true
      // }, 3000);

    }
    catch (error: any) {

    }

  }

  editAddress(value: boolean) {
    this.edit = value
  }

  initform() {
    // if (this.isBrowser || this.isServer) {
    this.NewShippingAddressForm = this.formBuilder.group({
      addressLine1: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      addressLine2: ['', [Validators.maxLength(50)]],
      city: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
        ],
      ],
      state: [
        '',
        [
          Validators.required,
          Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9]+$'),
        ],
      ],
      zipCode: ['', [Validators.required, Validators.maxLength(5), Validators.pattern('^[0-9]*$')]],
      country: [
        'US',
        [
          Validators.required,
          Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9]+$'),
        ],
      ],
      // landMark: [''],
    });
    this.addBillingAddressInfoForm = this.formBuilder.group({
      addressLine1: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      addressLine2: ['', [Validators.maxLength(50)]],
      city: [
        '',
        [
          Validators.required,
          Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9]+$'),
        ],
      ],
      state: [
        '',
        [
          Validators.required,
          Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9]+$'),
        ],
      ],
      zipCode: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      country: [
        'US',
        [
          Validators.required,
          Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9]+$'),
        ],
      ],
      // landMark: [''],
    });
    // }
  }

  getCart() {
    if (this.isBrowser) {
      this.isLoading = true;
      this.b2bService.GetShoppingCart(this.userDetail.userId).subscribe({
        next: (res: any) => {
          if (res.status == 200) {
            this.isLoading = false;
            this.cartItems = res.body.response
            this.CalculateAmount();
            this.sessionStripe.AmountInUSD = this.totalAmount;
          }
        },
        error: (error: any) => {
        }
      })
    }
  }

  mapOrderData() {
    if (this.isBrowser) {
      let orderDetail = {
        addressId: null,
        customerId: this.userDetail.userId,
        shippingAddress: this.NewShippingAddressForm?.value == null ? null : this.NewShippingAddressForm?.value,
        //billingAddress: this.addBillingAddressInfoForm?.value == null ? null : this.addBillingAddressInfoForm?.value,
        billingAddress: null,
        SaveAddressForNext: false,
        paymentMethodId: this.selectedPaymentId,
        OrderItems: this.mapProducts(),
        pM_SessionId: this.showCard == true ? this.sessionToken.pM_SessionId : null

      };

      if (this.selectedAddress) {
        orderDetail.addressId = this.selectedAddress.id;
        orderDetail.shippingAddress = null;
      }
      else {
        orderDetail.shippingAddress.addressLine1 = this.NewShippingAddressForm?.controls['addressLine1'].value;
        orderDetail.shippingAddress.addressLine2 = this.NewShippingAddressForm?.controls['addressLine2'].value;
        orderDetail.shippingAddress.state = this.NewShippingAddressForm?.controls['state'].value;
        orderDetail.shippingAddress.city = this.NewShippingAddressForm?.controls['city'].value;
        orderDetail.shippingAddress.country = this.NewShippingAddressForm?.controls['country'].value;
        orderDetail.shippingAddress.zipCode = this.NewShippingAddressForm?.controls['zipCode'].value;
      }
      return orderDetail;
    }
    return null
  }

  mapProducts() {
    if (this.isBrowser) {
      let products: any[] = [];
      this.cartItems.forEach((el: any) => {
        let productObj = {
          catalogueId: '',
          variantId: '',
          quantity: 0,
        };
        productObj.catalogueId = el.catalogue.productId;
        productObj.variantId = el.catalogue.variantId
        productObj.quantity = el.quantity;
        products.push(productObj);
      });

      return products;
    }
    return null
  }

  CalculateAmount() {
    this.subTotalAmount = 0.00;
    this.totalAmount = 0.00;
    this.commissionAmount = 0.00;
    this.totalTax = 0.00;

    this.cartItems.forEach((element: any) => {
      this.subTotalAmount = this.subTotalAmount + (element.catalogue.pricing.salePrice * element.quantity);
      let taxAmount = 0
      if (element.catalogue.pricing.tax) {
        taxAmount = element.quantity * (element.catalogue.pricing.tax);
      }
      this.totalTax = (this.totalTax || 0) + taxAmount;
    });

    this.totalAmount = this.subTotalAmount + this.totalTax;

    // if (this.paymentMethod == "BankWire") {
    //   this.totalAmount = this.subTotalAmount;
    // } else {
    //   //-- calculate comission 3% + $0.3 on subTotal
    //   this.commissionAmount = (this.subTotalAmount * (3 / 100)) + 0.3;
    //   this.totalAmount = this.commissionAmount + this.subTotalAmount;
    // }

  }

}
